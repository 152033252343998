import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";
import OrderContext from "./../OrderContext";

import InvoicingButton from "./InvoicingButton";

const InvoicingIndex = props => (
  <OrderContext.Consumer>
    {({ isLineEditing }) =>
      isLineEditing || !props.order.uiState.editable || !props.uiActive ? (
        <a className={"button button--dropdown button--disabled"}>Invoicing</a>
      ) : (
        <InvoicingButton {...props} />
      )
    }
  </OrderContext.Consumer>
);

InvoicingIndex.propTypes = {
  order: OrderObject,
  uiActive: PropTypes.bool.isRequired
};

export default InvoicingIndex;
