import React from "react";
import PropTypes from "prop-types";
import contractFragments from "../../Queries/graphql/contractFragments";

class NewOrderLink extends React.Component {
  render() {
    const { contract, queryParams } = this.props;
    let href = `/contracts/${contract.id}/new_order`;

    if (queryParams) {
      href += `?${queryParams}`;
    }

    return (
      <a
        href="#"
        onClick={e => this.closeModal(e, href)}
        className={this.props.cssClass}
      >
        {this.props.linkText || contract.contractCode}
      </a>
    );
  }

  closeModal = (e, href) => {
    e.preventDefault();
    this.props.closeModal();
    window.location.href = href;
  };
}

NewOrderLink.propTypes = {
  contract: contractFragments.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
  linkText: PropTypes.string,
  queryParams: PropTypes.string
};

export default NewOrderLink;
