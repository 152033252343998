import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";
import RequestApprovalUpdater from "./RequestApprovalUpdater";

class RequestApprovalBtn extends React.Component {
  state = { isOpen: false, error: false };

  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <button className="button primary" onClick={this.open}>
          Submit to Contract Admin
        </button>
        {this.state.isOpen && (
          <Modal
            header="Submit to Contract Admin"
            show={this.state.isOpen}
            onClose={this.toggleModal}
            renderFooter={() => (
              <RequestApprovalUpdater
                order={order}
                onError={this.setError}
                render={({ requestApproval }) => (
                  <button className="button primary" onClick={requestApproval}>
                    Request Approval
                  </button>
                )}
              />
            )}
          >
            {this.state.error && (
              <div className="message message--error">
                There was an error requesting approval, please reload the page and try again
              </div>
            )}
            <div className="group_section">
              Please confirm you would like to submit order to Aidacare. Ensure
              your quote/invoice is attached in the documents. Order will be
              approved, and codes added, then sent back to you for completion.
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  setError = (error) => {
    this.setState({ error: error })
  }

  open = () =>
    this.setState({
      isOpen: true
    });
}

RequestApprovalBtn.propTypes = {
  order: OrderObject
};

export default RequestApprovalBtn;
