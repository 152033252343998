import React from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";

class InventoryTable extends React.Component {
  render() {
    if (this.props.loading || !this.props.order.stockCheck) {
      return <div>Loading...</div>;
    }
    if (this.props.order.stockCheck.outsystemsError) {
      return (
        <div>
          Error loading inventory, please wait and try again or contact
          eCommerce team
        </div>
      );
    }
    return (
      <div className="ag-theme-balham ag-grid-wrapper">
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.order.stockCheck.inventory}
          columnDefs={this.columnDefs}
          defaultColDef={this.defaultColDef}
          suppressDragLeaveHidesColumns={true}
          suppressCsvExport={true}
          suppressExcelExport={true}
          enableRangeSelection={true}
          enableRangeHandle={true}
          headerHeight={40}
          floatingFiltersHeight={50}
        />
      </div>
    );
  }

  onGridReady = params => {
    const partner = this.props.order.partner;
    if (partner && partner.gpSupplierCode) {
      params.api.setFilterModel({
        site: {
          type: "equals",
          filter: partner.gpSupplierCode
        }
      });
    }
  };

  defaultColDef = {
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: true,
    filter: true
  };

  columnDefs = [
    {
      headerName: "SKU",
      field: "sku",
      width: 150,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains", "startsWith"]
      }
    },
    {
      headerName: "State",
      field: "state",
      width: 100,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    },
    {
      headerName: "Site",
      field: "site",
      width: 150,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains", "equals", "startsWith"]
      }
    },
    {
      headerName: "National Available",
      field: "nationalAvailable",
      width: 150,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "On Hand",
      field: "quantityOnHand",
      width: 100,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "On Order",
      field: "quantityOnOrder",
      width: 100,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "Allocated",
      field: "quantityAllocated",
      width: 100,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "Available",
      field: "quantityAvailable",
      width: 100,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    }
  ];
}

InventoryTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired
};

export default InventoryTable;
