import React from "react";
import PropTypes from "prop-types";

class AssignOrderBtn extends React.Component {
  render() {
    const { order } = this.props;
    const enabled = order.uiState.enableAssign;

    if (!order.uiState.showAssignBtn) {
      return null;
    }
    return (
      <div className="add-item-action assign-order-btn">
        <button
          className="button primary"
          disabled={!enabled}
          onClick={this.assign}
        >
          Assign order
        </button>
      </div>
    );
  }

  assign = e => {
    this.props.updateOrder({
      id: this.props.order.id,
      status: "validated"
    });
  };
}

AssignOrderBtn.propTypes = {
  order: PropTypes.object,
  updateOrder: PropTypes.func.isRequired
};

export default AssignOrderBtn;
