import React, { useState } from "react";
import PropTypes from "prop-types";
import { allowEditLine } from "../../BusRules/orderLines";

const ActionCell = ({ params, editingRow }) => {
  if (params.data.isTotals) {
    return null;
  }
  const [rowIndex, setRowIndex] = useState(params.rowIndex);

  // Index changes when invoice lines are expanded but doesn't trigger a render
  // for existing rows so we need to handle the update.
  const indexChangedCallback = () => {
    setRowIndex(params.node.rowIndex);
  };
  params.node.addEventListener("rowIndexChanged", indexChangedCallback);
  params.api.addRenderedRowListener(
    "virtualRowRemoved",
    params.rowIndex,
    () => {
      params.node.removeEventListener("rowIndexChanged", indexChangedCallback);
    }
  );

  const editClick = () => {
    params.api.startEditingCell({
      rowIndex: rowIndex,
      colKey: "action"
    });
  };

  const saveClick = () => {
    params.api.stopEditing(false);
  };

  const cancelClick = () => {
    params.api.stopEditing(true);
  };

  if (allowEditLine(params.data)) {
    if (editingRow !== null && editingRow === rowIndex) {
      return (
        <React.Fragment>
          <button
            onClick={saveClick}
            className="button button--tiny button--green"
          >
            Save
          </button>
          <button
            onClick={cancelClick}
            className="button button--tiny button--cancel"
          >
            Cancel
          </button>
        </React.Fragment>
      );
    }
    return (
      <button
        onClick={editClick}
        className="button button--tiny button--white"
        disabled={editingRow !== null && rowIndex !== editingRow}
      >
        Edit
      </button>
    );
  } else {
    return null;
  }
};

ActionCell.propTypes = {
  params: PropTypes.object.isRequired,
  editingRow: PropTypes.number
};

export default ActionCell;
