import React from "react";
import gql from "graphql-tag";

import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../../lib/AgGrid/CmpAgGridLink";
import PropTypes from "prop-types";

class TableView extends React.Component {
  render() {
    if (this.props.forItAdmin) {
      return (
        <CmpAgGrid
          defaultColumnDefs={this.contractColumnDefs()}
          gqlQuery={this.contractsQuery}
          responseDataKey="contracts"
          localStorageKey="userContracts"
        />
      );
    } else {
      return (
        <CmpAgGrid
          defaultColumnDefs={this.defaultColumnDefs()}
          gqlQuery={this.contractUsersQuery}
          responseDataKey="contractUsers"
        />
      );
    }
  }

  defaultColumnDefs = () => [
    {
      headerName: "Contract",
      field: "contract.contractCode",
      colId: "contract",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      },
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/user_accounts/${this.getUserIdFromPath()}/contract_users/${
            params.data.id
          }?tab=contract`
        };
      }
    },
    {
      headerName: "Name",
      field: "contract.longName",
      colId: "longName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contract Type",
      field: "contract.contractType",
      colId: "contractType",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Aged Care", "Community", "Hospital"]
      }
    },
    {
      headerName: "User's Role",
      field: "userRole",
      colId: "role",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Head Office", "Network Partner"]
      }
    },
    {
      headerName: "Notifications",
      field: "notifications",
      filter: "agSetColumnFilter",
      width: 100,
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    },
    {
      headerName: "Network Partners",
      field: "networkPartners",
      sortable: false,
      filter: false,
      width: 500,
      valueGetter: params =>
        params.data.networkPartners.map(np => np.name).join(", ")
    }
  ];

  contractColumnDefs = () => [
    {
      headerName: "Contract",
      field: "contractCode",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/contracts/${params.data.id}?tab=details`
        };
      }
    },
    {
      headerName: "Name",
      field: "longName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contract Type",
      field: "contractType",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Aged Care", "Community", "Hospital"]
      }
    },
    {
      headerName: "User's Role",
      field: "userRole",
      sortable: false,
      filter: false,
      valueGetter: params => "IT Admin"
    },
    {
      headerName: "Notifications",
      field: "notifications",
      sortable: false,
      filter: false,
      valueGetter: params => false
    }
  ];

  contractUsersQuery = () => {
    const userId = this.getUserIdFromPath();
    return gql`
      query ContractUsers($agGridParams: RequestParamInput) {
        contractUsers(agGridParams: $agGridParams, userId:${userId}) {
          id
          userRole
          notifications
          contract {
            id
            contractCode
            longName
            contractType
          }
          networkPartners {
            id
            name
          }
        }
      }
    `;
  };

  contractsQuery = () => {
    return gql`
      query Contracts($agGridParams: RequestParamInput) {
        contracts(agGridParams: $agGridParams) {
          id
          contractCode
          longName
          contractType
        }
      }
    `;
  };

  getUserIdFromPath = () => {
    const matchPath = window.location.pathname.match(/^\/user_accounts\/\d+/);
    let userId = null;
    if (matchPath) {
      userId = matchPath[0].match(/\d+/)[0];
    }
    return userId;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  forItAdmin: PropTypes.bool.isRequired
};

export default TableView;
