import React from "react";
import { OrderObject } from "../Queries/OrderQuery";

import TableView from "./TableView";

class PaymentsIndex extends React.Component {
  render() {
    const { order } = this.props;
    return <TableView invoices={order.paymentInvoices} />;
  }
}
PaymentsIndex.propTypes = {
  order: OrderObject
};

export default PaymentsIndex;
