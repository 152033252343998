import React from "react";
import PropTypes from "prop-types";

import { EventObject } from "../Queries/OrderQuery";
import dateFuncs from "../../lib/Form/dateFuncs";

class TableView extends React.Component {
  render() {
    const { invoices } = this.props;

    return (
      <table className="table aidacare">
        <thead>
          <tr className="table__row table__row--head">
            <th className="table__cell">Invoice #</th>
            <th className="table__cell">Partner Reference</th>
            <th className="table__cell">Description</th>
            <th className="table__cell">Amount (inc GST)</th>
            <th className="table__cell">Date (DD/MM/YYYY)</th>
            <th className="table__cell">Processed By</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map(invoice => (
            <tr className="table__row" key={invoice.id}>
              <td className="table__cell">{invoice.invoiceNumber}</td>
              <td className="table__cell">
                {invoice.payment.partnerReference}
              </td>
              <td className="table__cell">{invoice.payment.description}</td>
              <td className="table__cell">{invoice.payment.amount}</td>
              <td className="table__cell">
                {dateFuncs.displayDateYYYY(invoice.payment.date)}
              </td>
              <td className="table__cell">{invoice.payment.userName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

TableView.propTypes = {
  invoices: PropTypes.arrayOf(EventObject)
};

export default TableView;
