// disable for now
// const allowSwitchManualAuto = order => isHireOrder(order);
const allowSwitchManualAuto = order => false;

const showProcessInvoices = order => order.invoiceableLinesCount > 0;
const showCancelInvoices = order =>
  order.uiState.allowReverseInvoice &&
  order.reversibleLinesCount > 0 &&
  isHireOrder(order);

const showInvoicePeriodCol = order => isHireOrder(order);

const showInvoiceIssue = order => {
  if (!order.uiState.showFulfillment) {
    return false;
  }
  return order.orderLines.find(ol =>
    ol.invoiceLines.find(il => il.invoiceIssue)
  );
};

export {
  allowSwitchManualAuto,
  showInvoiceIssue,
  showInvoicePeriodCol,
  showProcessInvoices,
  showCancelInvoices
};

const isHireOrder = order => order.orderType === "HIRE";
