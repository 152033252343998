import React from "react";
import gql from "graphql-tag";

import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import { contractIdFromPath } from "../../lib/Util/url";
import PropTypes from "prop-types";
import CmpAgGridLink from "../../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.contractHoUsersQuery}
        responseDataKey="contractHoUsers"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "First Name",
      field: "firstName",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/user_accounts/${params.data.id}?tab=details`
        };
      }
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Role on contract",
      field: "roleOnContract",
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "IT Admin",
          "Head Office Standard",
          "Head Office Supervisor",
          "Head Office Contract Manager"
        ]
      }
    },
    {
      headerName: "Contracts",
      colId: "contract",
      field: "contracts",
      // Doesn't make sense to sort when there are multiple
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Active", "Inactive"]
      }
    }
  ];

  contractHoUsersQuery = () => {
    const id = contractIdFromPath();
    return gql`
      query ContractHoUsers($agGridParams: RequestParamInput!) {
        contractHoUsers(agGridParams: $agGridParams, contractId:${id}) {
          id
          firstName
          lastName
          email
          contracts
          roleOnContract
          status
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
