import React from "react";
import RoField from "Form/RoField";

import { OrderObject } from "./../Queries/OrderQuery";
class AdditionalInfo extends React.Component {
  render() {
    const additionalInfo = this.props.order.additionalInfo;
    const breakPoint = Math.round(additionalInfo.length / 2);
    const column1 = additionalInfo.slice(0, breakPoint);
    const column2 = additionalInfo.slice(breakPoint);

    return (
      <article className="order-additional-info-area">
        <header className="display_group__header">
          <h2 className="display_group__header__heading">Additional Info</h2>
        </header>
        <div className={"display-flex"}>
          <fieldset className={"fieldset fieldset--compact"}>
            {column1.map(infoField => this.printInfoField(infoField, 1))}
          </fieldset>
          <fieldset className={"fieldset fieldset--compact"}>
            {column2.map(infoField => this.printInfoField(infoField, 2))}
          </fieldset>
        </div>
      </article>
    );
  }

  printInfoField = (infoField, column) => {
    if (infoField.subInfo) {
      return (
        <React.Fragment key={"additionalInfo-" + column}>
          <RoField
            key={infoField.labelText}
            value=" "
            labelText={infoField.labelText + ":"}
          />
          {infoField.subInfo.map(subInfoField => (
            <RoField
              key={infoField.labelText + "-" + subInfoField.labelText}
              value={subInfoField.value}
              labelText={" - " + subInfoField.labelText}
            />
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <RoField
          key={infoField.labelText}
          value={infoField.value}
          labelText={infoField.labelText}
        />
      );
    }
  };
}

AdditionalInfo.propTypes = {
  order: OrderObject
};

export default AdditionalInfo;
