import React from "react";

const TickCompleted = () => (
  <svg className="icon icon--check margin-right-base" viewBox="0 0 100 100">
    <g>
      <path d="M87.8978532,14.004077 C85.3478411,14.0783947 82.9268403,15.1421678 81.1473944,16.9701877 C65.6095892,32.5415734 51.4234958,47.8100639 36.5023144,63.0471177 L18.9613494,48.9837437 C16.2208086,46.7908815 12.5185208,46.2267681 9.2492574,47.5039233 C5.97999401,48.7810784 3.64049991,51.7054461 3.11213806,55.1753225 C2.58377622,58.6451989 3.94682804,62.1333572 6.68778789,64.3256956 L31.2349109,83.963394 C35.1551708,87.0861193 40.7980128,86.7554488 44.3267098,83.1962145 C61.9843228,65.5006845 77.8036016,48.1203395 95.0574307,30.8290024 C97.941163,27.9994029 98.7975518,23.6923661 97.2156166,19.9748365 C95.6336814,16.2573069 91.9363038,13.8880525 87.8978532,14.004077 Z" />
    </g>
  </svg>
);

const TickCross = () => (
  <svg className="icon icon--cross margin-right-base" viewBox="0 0 100 100">
    <g>
      <path
        d="M39.3736839,39.3736839 L39.3736839,6.22591975 C39.3736839,0.703072256 43.8508364,-3.77408025 49.3736839,-3.77408025 L50.3943895,-3.77408025 C55.917237,-3.77408025 60.3943895,0.703072256 60.3943895,6.22591975 L60.3943895,39.3736839 L93.5421536,39.3736839 C99.0650011,39.3736839 103.542154,43.8508364 103.542154,49.3736839 L103.542154,50.3943895 C103.542154,55.917237 99.0650011,60.3943895 93.5421536,60.3943895 L60.3943895,60.3943895 L60.3943895,93.5421536 C60.3943895,99.0650011 55.917237,103.542154 50.3943895,103.542154 L49.3736839,103.542154 C43.8508364,103.542154 39.3736839,99.0650011 39.3736839,93.5421536 L39.3736839,60.3943895 L6.22591975,60.3943895 C0.703072256,60.3943895 -3.77408025,55.917237 -3.77408025,50.3943895 L-3.77408025,49.3736839 C-3.77408025,43.8508364 0.703072256,39.3736839 6.22591975,39.3736839 L39.3736839,39.3736839 Z"
        transform="translate(49.884037, 49.884037) rotate(-315.000000) translate(-49.884037, -49.884037) "
      />
    </g>
  </svg>
);

export { TickCompleted, TickCross };
