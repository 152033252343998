import React from "react";
import Modal from "Util/Modal";
import WardTransferQuery from "./WardTransferQuery";
import TransferOrderUpdater from "./TransferOrderUpdater";
import ErrorDisplay from "../../lib/Form/ErrorDisplay";
import EditDropdown from "../../lib/Form/EditDropdown";
import EditTextArea from "../../lib/Form/EditTextArea";
import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";
import EditDateField from "../../lib/Form/EditDateField";
import dateFuncs from "../../lib/Form/dateFuncs";
import EditTextField from "../../lib/Form/EditTextField";
import EditSalesRep from "../OrderDetails/EditSalesRep";

class WardModal extends React.Component {
  state = {
    isOpen: false,
    submitDisabled: false,
    transfer: {
      // Local time in ISO format
      transferDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      facilityId: null,
      salesRep: this.props.order.salesRep,
      comment: "",
      cpo: this.props.order.webOrderNo + "/" + this.props.order.costCentre
    }
  };

  render() {
    const { order, selectedLineIds } = this.props;
    const { transfer } = this.state;
    return (
      <React.Fragment>
        <button
          onClick={e => this.open(e)}
          className="button button--white"
          disabled={selectedLineIds.length === 0}
        >
          Transfer to Ward
        </button>
        {this.state.isOpen && (
          <WardTransferQuery
            contractId={order.contract.id}
            render={({ facilities, salesReps }) => (
              <TransferOrderUpdater
                order={order}
                onError={this.enableSubmit}
                render={({ transferOrder, errors }) => (
                  <Modal
                    header="Transfer Order"
                    show={this.state.isOpen}
                    onClose={() => this.close(true)}
                    className="ward-transfer-modal"
                    renderFooter={() => (
                      <div>
                        <button
                          className="button button--back"
                          onClick={() => this.close(false)}
                        >
                          Back
                        </button>
                        <button
                          className="button primary"
                          onClick={() => {
                            this.setState({ submitDisabled: true });
                            transferOrder({
                              transferType: "ward",
                              lineIds: selectedLineIds,
                              comment: transfer.comment,
                              facilityId: parseInt(transfer.facilityId),
                              salesRepId: transfer.salesRep.id,
                              transferDate: transfer.transferDate,
                              cpo: transfer.cpo
                            });
                          }}
                          disabled={
                            this.state.submitDisabled ||
                            !transfer.facilityId ||
                            !(transfer.salesRep && transfer.salesRep.id) ||
                            !transfer.transferDate
                          }
                        >
                          Transfer Order
                        </button>
                      </div>
                    )}
                  >
                    <ErrorDisplay errors={errors} modelDesc="order" />
                    <div className="group_section">
                      <EditDateField
                        name="transferDate"
                        onChange={this.handleChange}
                        value={transfer.transferDate}
                        labelText="Transfer Date"
                        className="fullwidth"
                        required={true}
                        limits={{
                          startDate: dateFuncs.displayDateYYYY(
                            order.dateReceived
                          )
                        }}
                        errors={errors}
                      />
                      <form>
                        <fieldset className="fieldset">
                          <EditDropdown
                            labelText="Please select the ward you want to transfer the hire agreement to"
                            onChange={(field, value) =>
                              this.handleFacilityChange(
                                field,
                                value,
                                facilities
                              )
                            }
                            name="facilityId"
                            value={transfer.facilityId}
                            options={facilities.map(f => ({
                              value: f.id,
                              text:
                                f.name +
                                " - " +
                                (f.costCentre ? f.costCentre : "N/A")
                            }))}
                            required={true}
                            selectCssClass="fullwidth"
                            placeholder="Please select"
                          />
                          <EditTextField
                            onChange={this.handleChange}
                            name="cpo"
                            value={transfer.cpo}
                            labelText="CPO"
                          />
                          <EditSalesRep
                            options={salesReps}
                            labelText="Sales Rep"
                            onChange={this.handleChange}
                            value={
                              (transfer.salesRep && transfer.salesRep.name) ||
                              null
                            }
                            required={true}
                          />
                          <EditTextArea
                            name="comment"
                            onChange={this.handleChange}
                            value={transfer.comment}
                            labelText="Comment"
                          />
                        </fieldset>
                      </form>
                    </div>
                  </Modal>
                )}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }

  handleFacilityChange = (field, value, facilities) => {
    this.handleChange(field, value);
    const costCentre =
      (facilities.find(f => f.id.toString() === value) || {}).costCentre ||
      "N/A";
    this.handleChange("cpo", this.props.order.webOrderNo + "/" + costCentre);
  };

  handleChange = (field, value) => {
    this.setState(prevState => ({
      transfer: { ...prevState.transfer, [field]: value }
    }));
  };

  open = e => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };

  close = closeParent => {
    this.setState({ isOpen: false });
    if (closeParent) {
      this.props.closeParentModal();
    }
  };

  enableSubmit = () => {
    this.setState({ submitDisabled: false });
  };
}

WardModal.defaultProps = {
  selectedLineIds: []
};

WardModal.propTypes = {
  closeParentModal: PropTypes.func.isRequired,
  order: OrderObject.isRequired,
  selectedLineIds: PropTypes.array
};

export default WardModal;
