import React from "react";
import PropTypes from "prop-types";

const FieldWrapper = ({ name, errors, stackedLabel, children }) => (
  <div
    className={`field ${
      isFieldError(name, errors) ? "form__field_error" : ""
    } ${stackedLabel ? "field--stacked" : ""}`}
  >
    {children}
  </div>
);

const isFieldError = (name, errors) =>
  errors &&
  errors.find(e => (e.attribute || "").toUpperCase() === name.toUpperCase());

FieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.array,
  stackedLabel: PropTypes.bool
};

export default FieldWrapper;
