import React from "react";
import PropTypes from "prop-types";

import dateFuncs from "./dateFuncs";

const RoDateTimeField = ({ value, labelText }) => (
  <div className="field">
    <label htmlFor={labelText}>{labelText}</label>
    <div>{value ? dateFuncs.displayDateTime(value) : "-"}</div>
  </div>
);

RoDateTimeField.propTypes = {
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

export default RoDateTimeField;
