import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";

const DischargeCostCentresApp = props => {
  return (
    <GraphqlWrapper>
      <section className="discharge_cost_centres_section">
        <Table />
      </section>
    </GraphqlWrapper>
  );
};

export default DischargeCostCentresApp;
