import React from "react";

import { CurrentUserObject, OrderObject } from "../Queries/OrderQuery";
import OrderUpdater from "../OrderUpdater";
import PropTypes from "prop-types";

class AwaitingCallBtn extends React.Component {
  state = { disabled: false };

  render() {
    const { order, currentUser } = this.props;
    let btnText = "Awaiting Call";
    if (order.awaitingCall) {
      btnText = `Remove ${btnText}`;
    }
    return (
      <React.Fragment>
        <OrderUpdater
          currentUser={currentUser}
          order={order}
          onComplete={this.enableBtn}
          render={({ updateOrder }) => (
            <button
              className="button primary"
              onClick={() => this.handleClick(updateOrder)}
              disabled={this.state.disabled}
            >
              {btnText}
            </button>
          )}
        />
      </React.Fragment>
    );
  }

  handleClick = updateOrder => {
    this.setState({ disabled: true });
    const awaitingCall = !this.props.order.awaitingCall;
    this.props.onClick(awaitingCall);
    const order = {
      id: this.props.order.id,
      awaitingCall: awaitingCall
    };
    updateOrder(order);
  };

  enableBtn = () => {
    this.setState({ disabled: false });
  };
}

AwaitingCallBtn.propTypes = {
  order: OrderObject.isRequired,
  onClick: PropTypes.func.isRequired,
  currentUser: CurrentUserObject.isRequired
};

export default AwaitingCallBtn;
