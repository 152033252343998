import React from "react";
import PropTypes from "prop-types";

import FieldWrapper from "./FieldWrapper";

const EditTextArea = ({
  name,
  onChange,
  value,
  labelText,
  errors,
  type,
  maxLength,
  stackedLabel,
  preText
}) => {
  const textArea = (
    <textarea
      id={name}
      type={type || "text"}
      value={value || ""}
      maxLength={maxLength}
      onChange={e => onChange(name, e.target.value)}
    />
  );
  return (
    <FieldWrapper name={name} errors={errors} stackedLabel={!!stackedLabel}>
      <React.Fragment>
        {labelText && <label htmlFor={name}>{labelText}</label>}
        {preText && (
          <div className={"input-container"}>
            <span>{preText}</span>
            {textArea}
          </div>
        )}
        {!preText && textArea}
      </React.Fragment>
    </FieldWrapper>
  );
}

EditTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string,
  maxLength: PropTypes.number,
  stackedLabel: PropTypes.bool,
  preText: PropTypes.string,
  errors: PropTypes.array
};

export default EditTextArea;
