import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../../../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../../../lib/AgGrid/CmpAgGridLink";
import ContractUserPartnerCheckbox from "./ContractUserPartnerCheckbox";
import { contractUserIdFromPath } from "../../../lib/Util/url";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.networkPartnersQuery}
        frameworkComponents={this.frameworkComponents}
        responseDataKey="networkPartners"
        localStorageKey="contractUserParters"
        preserveSelection={false}
      />
    );
  }

  frameworkComponents = {
    assignedToPartnerCellRenderer: row => {
      return <ContractUserPartnerCheckbox row={row} />;
    }
  };

  defaultColumnDefs = () => [
    {
      headerName: "Network Partner Name",
      field: "name",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/network_partners/${params.data.id}?tab=details`,
          newTab: true
        };
      }
    },
    {
      headerName: "Assigned to Partner",
      field: "userIsAssigned",
      pinned: "left",
      sortable: false,
      filter: false,
      cellRenderer: "assignedToPartnerCellRenderer"
    },
    {
      headerName: "Type",
      field: "partnerType",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Network", "Internal"]
      }
    },
    {
      headerName: "Services Offered",
      field: "serviceDesc",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Hire", "Home Mod", "Repair", "Supply"]
      }
    },
    {
      headerName: "Contracts",
      colId: "contract",
      field: "contracts",
      // Doesn't make sense to sort when there are multiple
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Address",
      field: "streetAddress",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Postcode",
      field: "postcode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Active", "Draft", "Inactive"]
      }
    },
    {
      headerName: "Using CMP",
      field: "usingCmp",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    }
  ];

  networkPartnersQuery = () => {
    const contractUserId = contractUserIdFromPath();
    return gql`
      query NetworkPartners($agGridParams: RequestParamInput!) {
        networkPartners(agGridParams: $agGridParams, contractUserId:${contractUserId}) {
          id
          name
          streetAddress
          status
          partnerType
          city
          state
          postcode
          serviceDesc
          contracts
          usingCmp
          userIsAssigned
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
