import React from "react";
import PropTypes from "prop-types";

import UniqueScheduleCodeQuery from "./UniqueScheduleCodeQuery";
import { OrderObject } from "./../../Queries/OrderQuery";

import { isAllowedMixedOrderLines } from "./../../BusRules/orders";

import ProductAdder from "./ProductAdder";
import AddNonContractedItemForm from "./AddNonContractedItemForm";
import NonContractedItemPicker from "./NonContractedItemPicker";
import { isNEDOrder, isOnDvaContract } from "../../BusRules/access";

const requiredFields = props => {
  let fields = [
    "sku",
    "description",
    "saleType",
    "quantity",
    "sellUnitPriceExc",
    "buyUnitPriceExc",
    "gstApplicable"
  ];
  if (isOnDvaContract(props.order)) {
    fields.push("rapScheduleCode");
  }
  if (isNEDOrder(props.order)) {
    fields.push("webLineId");
  }
  return fields;
};

const emptyItem = props => {
  let saleType = props.order.saleType;
  if (props.isAddFreight) {
    saleType = "FREIGHT";
  } else if (isAllowedMixedOrderLines(props.order)) {
    saleType = "";
  }
  return {
    sku: "",
    size: "",
    description: "",
    rapScheduleCode: "",
    webLineId: null,
    saleType: saleType,
    unit: "",
    quantity: "",
    buyUnitPriceExc: "",
    sellUnitPriceExc: "",
    gstApplicable: ""
  };
};
class AddNonContractedItem extends React.Component {
  state = {
    gpProduct: null,
    item: { ...emptyItem(this.props) }
  };
  render() {
    const { order, complete, disableUIFunc, currentUser} = this.props;
    return (
      <UniqueScheduleCodeQuery
        contractId={order.contract.id}
        render={({ uniqueScheduleCodes, nonContractedItemSizes }) => (
          <ProductAdder
            disableUIFunc={disableUIFunc}
            render={({ update }) =>
              this.state.gpProduct ? (
                <AddNonContractedItemForm
                  uniqueScheduleCodes={uniqueScheduleCodes}
                  sizes={nonContractedItemSizes}
                  update={update}
                  order={order}
                  item={this.state.item}
                  complete={complete}
                  handleChange={this.handleChange}
                  requiredFields={requiredFields(this.props)}
                  saleTypeOptions={this.saleTypeOptions(order)}
                  resetGpProduct={this.resetGpProduct}
                />
              ) : (
                <NonContractedItemPicker
                  order={order}
                  complete={complete}
                  useGpProduct={this.useGpProduct}
                  currentUser={currentUser}
                />
              )
            }
          />
        )}
      />
    );
  }

  // set gpProduct & reset the form item
  useGpProduct = gpProduct => {
    this.setState({
      gpProduct,
      item: {
        ...emptyItem(this.props),
        gstApplicable: gpProduct.gstApplicable ? "YES" : "NO",
        sku: gpProduct.sku,
        unit: gpProduct.unit,
        description: gpProduct.description
      }
    });
  };

  resetGpProduct = () => {
    this.setState({
      gpProduct: null,
      item: { ...emptyItem(this.props) }
    });
  };

  // Updated rule for sale type is:
  // - if we're adding freight then only "FREIGHT" can be selected
  // - if the order is allowed mixed then an array of [BUY, HIRE]
  // - if the order is a hire order then only HIRE items can be added
  // - otherwise only BUY items can be added
  saleTypeOptions = order => {
    if (this.props.isAddFreight) {
      return ["FREIGHT"];
    }
    const lineTypes = isAllowedMixedOrderLines(order)
      ? ["BUY", "HIRE"]
      : [order.saleType === "HIRE" ? "HIRE" : "BUY"];
    return lineTypes.concat(["FREIGHT"]);
  };

  handleChange = (field, value) => {
    const item = { ...this.state.item, [field]: value };
    this.setState({ item });
  };
}

AddNonContractedItem.defaultProps = {
  isAddFreight: false
};

AddNonContractedItem.propTypes = {
  order: OrderObject.isRequired,
  complete: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  isAddFreight: PropTypes.bool,
  currentUser: PropTypes.object
};

export default AddNonContractedItem;
