import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import contractFragments from "../../Orders/Queries/graphql/contractFragments";

const GridPropsQuery = ({ render, additionalFields }) => {
  return (
    <Query
      query={gqlGridPropsQuery(
        additionalFields.map(field => field.gqlQueryString)
      )}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
        let renderParams = {
          contractCodes: data.contracts.map(c => c.contractCode),
          currentUser: data.currentUser
        };
        additionalFields.map(field => {
          renderParams[field.dataKey] = data[field.dataKey];
        });
        return render(renderParams);
      }}
    </Query>
  );
};

// Any fields which are required in the grid setup can be added to this query
const gqlGridPropsQuery = (additionalFields, currentUserFields) => {
  return gql`
    query AgGridProperties {
      contracts {
        ...ContractQuery
      }
      currentUser {
        id
        aidacareHo
        partner
        itAdmin
        name
        orderStatusDescriptions
      }
      ${additionalFields}
    }
    ${contractFragments.query}
  `;
};

GridPropsQuery.defaultProps = {
  additionalFields: []
};

GridPropsQuery.propTypes = {
  render: PropTypes.func.isRequired,
  additionalFields: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      gqlQueryString: PropTypes.string.isRequired
    })
  )
};

export default GridPropsQuery;
