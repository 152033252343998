import React from "react";
import PropTypes from "prop-types";

import { OrderObject, CurrentUserObject } from "./Queries/OrderQuery";
import { Link } from "react-router-dom";

import PrintOrderBtn from "./PrintOrderBtn";
import AddRepairHomeModsBtn from "./AddRepairHomeModsBtn";
import Invoicing from "./Invoicing";
import ReassignBtn from "./ReassignStaffMember/ReassignBtn";
import ReassignLines from "./ReassignLines";
import ConvertToOrder from "./ConvertToOrder";
import CopyOrder from "./CopyOrder";
import CancelOrderBtn from "./CancelOrder";
import ReinstateBtn from "./ReinstateBtn";
import OrderLevelProcessBtns from "./OrderLevelProcessBtns";
import FulfillmentOptions from "./FulfillmentOptions";
import BackToValidationBtn from "./BackToValidationBtn";
import AssignToMeBtn from "./ReassignStaffMember/AssignToMeBtn";
import AssignUpdater from "./ReassignStaffMember/AssignUpdater";
import TransferBtn from "./TransferOrderBtn/TransferBtn";
import CreatePayment from "./Payments/CreatePayment";
import { isOnDvaContract } from "./BusRules/access";
import SendInternalOrderToGp from "./ResendToGp/SendInternalOrderToGp";
import AwaitingCallBtn from "./AwaitingCallBtn";
import Shipments from "./Shipments";
import ResendOrderInvoice from "./ResendToGp/ResendOrderInvoice";
import SendSMSBtn from "./SendSMSBtn/SendSMSBtn";
import UpdateTrialsBtn from "./Trials/UpdateTrialsBtn";
import EnableDeliveryBtn from "./Trials/EnableDeliveryBtn";
import PreferredPartnerBtn from "./PreferredPartnerBtn";

class OrderTitleBar extends React.Component {
  state = { awaitingCallPending: false };
  render() {
    const {
      order,
      updateOrder,
      currentUser,
      uiActive,
      disableUIFunc
    } = this.props;

    var showAddRepairHomeModsBtn =
      order.uiState.showAddHomeModsLineBtn ||
      order.uiState.showAddRepairLineBtn;

    return (
      <header className={"title_bar"}>
        <h2 className="title_bar__heading">
          <div>Order #{order.cmpOrderNumber}</div>
          {order.trial && (
            <span className="label label--trial-title">Includes trials</span>
          )}
          {order.inReassign && (
            <div className="title-in-reassignment-messge">
              Locked while reassign active
            </div>
          )}
        </h2>
        {order.uiState.showProcessingInstructions && (
          <div className="title_bar__text message message--error">
            {order.contract.processingInstructions}
          </div>
        )}
        <div className="title_bar__actions">
          <FulfillmentOptions order={order} />
        </div>
        <div className="title_bar__actions">
          {isOnDvaContract(order) && order.uiState.showPaymentsBtn && (
            <React.Fragment>
              <CreatePayment order={order} />
            </React.Fragment>
          )}
          {order.uiState.showGoBackToValidationBtn && (
            <BackToValidationBtn order={order} />
          )}
          {order.uiState.showPreferredPartnerBtn && (
            <PreferredPartnerBtn order={order} currentUser={currentUser} />
          )}
          {order.uiState.showAwaitingCall && (
            <AwaitingCallBtn
              order={order}
              currentUser={currentUser}
              onClick={this.setAwaitingCallPending}
            />
          )}
          {order.uiState.showProcessTransfer && (
            <TransferBtn order={order} currentUser={currentUser} />
          )}
          {order.uiState.showUpdateTrials && (
            <UpdateTrialsBtn
              order={order}
              currentUser={currentUser}
              disableUIFunc={disableUIFunc}
            />
          )}
          {order.uiState.showEnableTrialDelivery && (
            <EnableDeliveryBtn order={order} currentUser={currentUser} />
          )}
          {order.uiState.showTrialGpSync && (
            <SendInternalOrderToGp order={order} btnText={"Send to GP"} />
          )}
          <OrderLevelProcessBtns
            order={order}
            uiActive={uiActive}
            disableUIFunc={disableUIFunc}
          />
          {order.uiState.showProcessShipments && (
            <Shipments
              order={order}
              currentUser={currentUser}
              uiActive={uiActive}
              awaitingCallPending={this.state.awaitingCallPending}
            />
          )}
          <Link to="/" className={"button button--back"}>
            Back to List
          </Link>
          {showAddRepairHomeModsBtn && <AddRepairHomeModsBtn order={order} />}
          {order.uiState.showInvoicingBtn && (
            <Invoicing
              order={order}
              uiActive={uiActive}
            />
          )}
          <PrintOrderBtn order={order} />
          {order.uiState.showSendClientSmsBtn && (
            <SendSMSBtn order={order} currentUser={currentUser} />
          )}
          {order.uiState.showReassignLinesBtn && (
            <ReassignLines
              order={order}
              updateOrder={updateOrder}
              uiActive={uiActive}
            />
          )}
          {order.uiState.showCopyOrderBtn && (
            <CopyOrder order={order} update={updateOrder} />
          )}
          {order.uiState.showConvertToOrderBtn && (
            <ConvertToOrder order={order} />
          )}
          {order.uiState.showReassignBtn && <ReassignBtn order={order} />}
          {order.uiState.showAssignToMeBtn && (
            <AssignUpdater
              order={order}
              complete={data => null}
              render={props => (
                <AssignToMeBtn currentUser={currentUser} {...props} />
              )}
            />
          )}
          {order.uiState.showCancelOrderBtn && <CancelOrderBtn order={order} />}
          {order.uiState.showReinstateBtn && <ReinstateBtn order={order} />}
          {order.uiState.showResendSyncingBtn && (
            <SendInternalOrderToGp order={order} btnText={"Resend to GP"} />
          )}
          {order.uiState.showResendInvoiceBtn && (
            <ResendOrderInvoice order={order} btnText={"Resend to GP"} />
          )}
        </div>
      </header>
    );
  }
  setAwaitingCallPending = value => {
    this.setState({ awaitingCallPending: value });
  };
}

OrderTitleBar.propTypes = {
  order: OrderObject,
  updateOrder: PropTypes.func.isRequired,
  currentUser: CurrentUserObject.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default OrderTitleBar;
