import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../../../lib/AgGrid/CmpAgGrid";
import { OrderObject } from "../../Queries/OrderQuery";
import { isOnDvaContract } from "../../BusRules/access";

class GpProductList extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.gpProductsQuery}
        responseDataKey="gpProducts"
        preserveSelection={false}
        frameworkComponents={this.frameworkComponents}
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Sku",
      field: "sku",
      width: 140,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Unit",
      field: "unit",
      width: 110,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Description",
      field: "description",
      width: 610,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Action",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "actionCellRenderer",
      valueGetter: params => null // value not part of dataset
    }
  ];

  frameworkComponents = {
    actionCellRenderer: row => {
      return (
        <button
          className="button button--tiny button--white"
          onClick={() => this.props.useGpProduct(row.data)}
          key={row.data.sku}
        >
          Use item
        </button>
      );
    }
  };

  gpProductsQuery = () => {
    const contractId = this.props.contractId;
    return gql`
      query GpProducts($agGridParams: RequestParamInput!) {
        gpProducts(agGridParams: $agGridParams, contractId: ${contractId}) {
          description
          gstApplicable
          id
          sku
          rapScheduleCode
          unit
        }
      }
    `;
  };
}

GpProductList.propTypes = {
  contractId: PropTypes.number.isRequired,
  order: OrderObject.isRequired,
  useGpProduct: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default GpProductList;
