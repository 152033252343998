import React from "react";
import { OrderObject } from "../../Queries/OrderQuery";
import PartnerReferenceField from "./PartnerReferenceField";

const getInvoicingColumns = ({ order }) => {
  let cols = [];

  if (order.uiState.showNpPricing) {
    cols.push({
      headerName: "INVOICING",
      children: [
        {
          headerName: "Invoice #",
          headerTooltip: "Invoice Number",
          field: "invoiceNumber",
          width: 100
        },
        {
          headerName: "Date",
          field: "invoiceDate",
          width: 100
        },
        {
          headerName: "Partner Ref",
          headerTooltip: "Partner Reference Number",
          field: "partnerReference",
          width: 90,
          wrapText: true,
          cellRenderer: params => {
            if (params.data.isTotals) {
              return null;
            } else if (params.data.path.length > 1) {
              return (
                <PartnerReferenceField
                  invoiceLine={params.data}
                  rowNode={params.node}
                  api={params.api}
                />
              );
            } else if (params.data.invoiceLines.length === 1) {
              return (
                <PartnerReferenceField
                  invoiceLine={params.data.invoiceLines[0]}
                  rowNode={params.node}
                  api={params.api}
                />
              );
            } else if (params.data.invoiceLines.length === 0) {
              return "N/A";
            } else {
              return "-";
            }
          }
        },
        {
          headerName: "Invoicing Status",
          field: "statusDesc",
          width: 120
        }
      ]
    });
  }
  
  return cols;
};

getInvoicingColumns.propTypes = {
  order: OrderObject.isRequired
};

export { getInvoicingColumns };
