import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment EventQuery on OrderEvent {
      id
      eventName
      eventCode
      eventAt
      ownerName
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    eventName: PropTypes.string,
    eventCode: PropTypes.string,
    eventAt: PropTypes.string,
    ownerName: PropTypes.string
  })
};

export default fragments;
