import React from "react";

import { showInvoiceIssue } from "./businessRules";
import { OrderObject } from "./../Queries/OrderQuery";

const InvoiceIssueWarningMsg = ({ order }) => {
  if (!showInvoiceIssue(order)) {
    return null;
  }
  return (
    <div className="message message--warning" role="alert">
      <p>
        One or more lines have been invoiced for periods after the item return
        date
      </p>
    </div>
  );
};

InvoiceIssueWarningMsg.propTypes = {
  order: OrderObject
};

export default InvoiceIssueWarningMsg;
