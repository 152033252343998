import { lineStatuses } from "./orderLines";
import { isOnDvaContract } from "./access";

const ORDER_STATUS_PENDING_ALLOCATION = "pending_allocation";
const ORDER_STATUS_VALIDATED = "validated";
const ORDER_STATUS_SENT_TO_PARTNER = "sent_to_partner";
const ORDER_STATUS_ALL_ASSIGNED = "all_assigned";
const AIDACARE_APPROVAL_LIST = [
  "approval_requested",
  "dva_approval_requested",
  "approval_accepted",
  "approval_confirmed"
];
const ORDER_STATUS_HIDE_ALLOCATED_LINES = [
  ORDER_STATUS_VALIDATED,
  ORDER_STATUS_SENT_TO_PARTNER
];
const ORDER_STATUS_HIDE_CANCELLED_LINES =
  ORDER_STATUS_HIDE_ALLOCATED_LINES + AIDACARE_APPROVAL_LIST;

const ORDER_STATUS_FULFILLMENT_LIST = [
  "accepted_branch_pending_gp_sync",
  "accepted",
  "approval_confirmed",
  "on_backorder",
  "partly_delivered",
  "on_hire",
  "pickup_request",
  "completed"
];

const ORDER_STATUS_NOT_PERMITTED_MIXED = [ORDER_STATUS_SENT_TO_PARTNER]
  .concat(AIDACARE_APPROVAL_LIST)
  .concat(ORDER_STATUS_FULFILLMENT_LIST);

const unassignedLines = order => {
  const showLine = (order, line) => {
    if (
      order.inReassign &&
      (line.status === lineStatuses.CANCELLED || line.allocated)
    ) {
      return false;
    } else if (
      ORDER_STATUS_HIDE_ALLOCATED_LINES.includes(order.status) &&
      line.allocated
    ) {
      return false;
    } else if (
      ORDER_STATUS_HIDE_CANCELLED_LINES.includes(order.status) &&
      line.status === lineStatuses.CANCELLED
    ) {
      return false;
    } else if (
      order.duringFulfillment &&
      line.status === lineStatuses.CANCELLED
    ) {
      return false;
    }
    return true;
  };

  return order.orderLines.filter(ol => showLine(order, ol));
};

const assignableLines = lines => {
  const showLine = line => lineStatuses.SELECTABLE_LIST.includes(line.status);

  return lines.filter(ol => showLine(ol));
};

const undeletedLines = order =>
  order.orderLines.filter(ol => !lineStatuses.DELETED_LIST.includes(ol.status));

const unassignedCount = order => assignableLines(unassignedLines(order)).length;

const lineCount = order => order.orderLines.length;

const isAllowedMixedOrderLines = order =>
  !(
    order.inReassign || ORDER_STATUS_NOT_PERMITTED_MIXED.includes(order.status)
  );

const isSent = order => order.status !== ORDER_STATUS_PENDING_ALLOCATION;

const isAllSent = order => order.status === ORDER_STATUS_ALL_ASSIGNED;

const isHireOrder = order => order.orderType === "HIRE";

const isRTOOrder = order => order.orderType === "RTO";

const isAgedCareOrder = order => order.agedCare;

const isHospitalOrder = order => order.hospitalType !== "Na";

const isHospitalDischargeOrder = order => order.hospitalType === "Discharge";

const isHospitalWardOrder = order => order.hospitalType === "Ward";

const isHireOrRTOOrder = order => isHireOrder(order) || isRTOOrder(order);

const includesHireOrRTO = order =>
  order.orderType.includes("HIRE") || order.orderType.includes("RTO");

const showAssetColumn = order => isHireOrder(order) || isRTOOrder(order);

const filterSaleType = (order, isAddFreight) => {
  if (isAddFreight) {
    return "FREIGHT";
  } else if (isAllowedMixedOrderLines(order)) {
    return "";
  } else {
    return order.orderType;
  }
};

const boolFieldYesNo = boolField => (boolField ? "Yes" : "No");

const canSendToPartner = order =>
  order.uiState.stageCanSendToPartner &&
  !salesRepRequired(order) &&
  !debtorRequired(order);

const salesRepRequired = order =>
  order.partner.isInternal &&
  !order.salesRep &&
  order.contract.contractType !== "Aged Care";

const debtorRequired = order => !isOnDvaContract(order) && !order.debtor;

export {
  canSendToPartner,
  isAllSent,
  isAllowedMixedOrderLines,
  isHireOrder,
  isRTOOrder,
  isAgedCareOrder,
  isHospitalOrder,
  isHospitalDischargeOrder,
  isHospitalWardOrder,
  filterSaleType,
  isSent,
  lineCount,
  isHireOrRTOOrder,
  includesHireOrRTO,
  showAssetColumn,
  unassignedCount,
  unassignedLines,
  undeletedLines,
  boolFieldYesNo
};
