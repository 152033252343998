import React from "react";

import OrderUpdater from "../OrderUpdater";
import { CurrentUserObject, OrderObject } from "../Queries/OrderQuery";

class EnableDeliveryBtn extends React.Component {
  state = { disabled: false };
  render() {
    const { order, currentUser } = this.props;

    return (
      <OrderUpdater
        currentUser={currentUser}
        order={order}
        render={({ updateOrder }) => (
          <button
            onClick={() => {
              this.setState({ disabled: true });
              updateOrder({
                id: order.id,
                trialDeliveryEnabled: true
              });
            }}
            className="button button--white"
            disabled={this.state.disabled}
          >
            Enable Delivery
          </button>
        )}
      />
    );
  }
}

EnableDeliveryBtn.propTypes = {
  order: OrderObject.isRequired,
  currentUser: CurrentUserObject.isRequired
};

export default EnableDeliveryBtn;
