import React from "react";
import PropTypes from "prop-types";

import fetch from "unfetch";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";

// use something like
// REFRESH_INTERVAL=0 ./bin/webpack-dev-server
// to override & remove locally
// or set ENV to change the freq in staging/prod
// otherwise it defaults to 30'sec (30000ms)
const refreshInterval = parseInt(process.env.REFRESH_INTERVAL || "30000");

const pollingStopper = (pollingPathNameMatch, stopPolling) => {
  if (
    pollingPathNameMatch &&
    !window.location.pathname.match(pollingPathNameMatch)
  ) {
    stopPolling();
  }
};

const httpLink = createHttpLink({
  uri: "/graphql",
  fetch: fetch
});

const errorLink = onError(({ networkError }) => {
  if ((networkError || {}).statusCode === 401) {
    window.location.href = "/users/sign_in";
  }
});

const apolloClient = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache()
});

const GraphqlWrapper = props => {
  return (
    <ApolloProvider client={apolloClient} {...props}>
      {props.children}
    </ApolloProvider>
  );
};

GraphqlWrapper.propTypes = {
  children: PropTypes.object.isRequired
};

export default GraphqlWrapper;
export { refreshInterval, pollingStopper, apolloClient };
