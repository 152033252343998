import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.facilitiesQuery}
        responseDataKey="facilities"
      />
    );
  }

  defaultColumnDefs = () => {
    return [
      {
        headerName: "Name",
        field: "name",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridLink,
        cellRendererParams: params => {
          return {
            link: `/facilities/${params.data.id}?tab=details`,
            newTab: true
          };
        }
      },
      {
        headerName: "Contract",
        field: "contract",
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Phone",
        field: "phone",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "address",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "city",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "state",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Postcode",
        field: "postcode",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Organisation ID",
        field: "organisationId",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Default Debtor",
        field: "debtor",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Cost Centre",
        field: "costCentre",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          values: ["Active", "Inactive"]
        }
      }
    ];
  };

  facilitiesQuery = () => {
    return gql`
      query Facilities($agGridParams: RequestParamInput!) {
        facilities(agGridParams: $agGridParams, excludeDrafts: true) {
          id
          name
          contract
          phone
          address
          postcode
          city
          state
          organisationId
          debtor
          costCentre
          status
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
