import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import GridPropsQuery from "../../../lib/AgGrid/GridPropsQuery";

import Table from "./Table";

const ContractUserPartnersApp = props => {
  const dataset = document.getElementById("contract-user-partners").dataset;
  const contractId = parseInt(dataset.contractId);
  const userId = parseInt(dataset.userId);
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        render={({ contractCodes, currentUser }) => (
          <section className="contract_user_partner_section">
            <Table
              contractId={contractId}
              userId={userId}
              contractCodes={contractCodes}
              currentUser={currentUser}
            />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default ContractUserPartnersApp;
