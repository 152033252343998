import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment ClientQuery on Client {
      id
      sourceId
      webClientId
      status
      reference
      firstName
      lastName
      gender
      dateOfBirth
      dischargeDate
      phoneNumber
      mobileNumber
      email
      altContactName
      altPhoneNumber
      cardType
      homecarePackageLevel
      residentialCareFacility
      acfiClassification
      address
      city
      postcode
      state
      deliveryAddress
      deliveryCity
      deliveryPostcode
      deliveryState
      differentDeliveryAddress
      deliveryInstructions
      formattedAddress
      formattedDeliveryAddress
      originalRecordId
      fundingOther
      fundingOtherType
      fundingOtherNotes
      homeModsInstructions
      defaultSmsPrefix
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sourceId: PropTypes.number,
    webClientId: PropTypes.number,
    status: PropTypes.string,
    reference: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    dateOfBirth: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    email: PropTypes.string,
    altContactName: PropTypes.string,
    altPhoneNumber: PropTypes.string,
    cardType: PropTypes.string,
    homecarePackageLevel: PropTypes.bool,
    residentialCareFacility: PropTypes.bool,
    acfiClassification: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    deliveryAddress: PropTypes.string,
    deliveryCity: PropTypes.string,
    deliveryPostcode: PropTypes.string,
    deliveryState: PropTypes.string,
    formattedAddress: PropTypes.string,
    formattedDeliveryAddress: PropTypes.string,
    originalRecordId: PropTypes.string,
    fundingOther: PropTypes.bool,
    fundingOtherType: PropTypes.string,
    fundingOtherNotes: PropTypes.string,
    homeModsInstructions: PropTypes.string,
    defaultSmsPrefix: PropTypes.string
  })
};

export default fragments;
