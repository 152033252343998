import React from "react";
import PropTypes from "prop-types";

import { EventObject } from "./../Queries/OrderQuery";

class TableView extends React.Component {
  render() {
    const { events } = this.props;

    return (
      <table className="table aidacare">
        <thead>
          <tr className="table__row table__row--head">
            <th className="table__cell">Event</th>
            <th className="table__cell">Date</th>
            <th className="table__cell">Author</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr className="table__row" key={event.id}>
              <td className="table__cell">{event.eventName}</td>
              <td className="table__cell">{event.eventAt}</td>
              <td className="table__cell">{event.ownerName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

TableView.propTypes = {
  events: PropTypes.arrayOf(EventObject)
};

export default TableView;
