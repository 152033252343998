import React from "react";
import PropTypes from "prop-types";

const RoYesNo = ({ value, labelText }) => {
  let textValue = "";
  if (value) {
    textValue = "Yes";
  } else if (value === false) {
    textValue = "No";
  }
  return (
    <div className="field">
      <label htmlFor={labelText}>{labelText}</label>
      <div>{textValue}</div>
    </div>
  );
};

RoYesNo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelText: PropTypes.string.isRequired
};

export default RoYesNo;
