import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";
import SelectPartner from "../Lines/Assign/SelectPartner";
import OrderUpdater from "../OrderUpdater";

class PreferredPartnerBtn extends React.Component {
  state = { isOpen: false };

  render() {
    const { order, currentUser } = this.props;
    return (
      <React.Fragment>
        <button className="button button--white" onClick={this.open}>
          Update Preferred Partner
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.close}
            header="Preferred Partner"
          >
            <OrderUpdater
              currentUser={currentUser}
              order={order}
              onComplete={this.close}
              render={({ updateOrder }) => (
                <SelectPartner
                  order={order}
                  complete={selection =>
                    this.partnerSelected(selection, updateOrder)
                  }
                  repairLinesSelected={false}
                  homeModLinesSelected={false}
                />
              )}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });

  partnerSelected = (selection, updateOrder) => {
    const order = { id: this.props.order.id, partnerId: selection.id };
    updateOrder(order);
  };
}

PreferredPartnerBtn.propTypes = {
  order: OrderObject,
  currentUser: PropTypes.object.isRequired
};

export default PreferredPartnerBtn;
