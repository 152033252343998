import React from "react";
import PropTypes from "prop-types";
import { OrderObject } from "../../Queries/OrderQuery";
import TableUpdater from "../TableUpdater";
import Modal from "../../../lib/Util/Modal";
import EditTextArea from "../../../lib/Form/EditTextArea";

class CommentCell extends React.Component {
  state = { isOpen: false, comment: this.props.value, updating: false };

  render() {
    const { comment } = this.state;
    const noComment = !comment || comment.length === 0;
    const displayComment = noComment ? "-" : comment;
    const { order, data, disableUIFunc } = this.props;

    const editable =
      order.uiState.editable &&
      order.uiState.canEditLines &&
      order.uiState.currentUserIsHeadOffice;

    if (data.isTotals) {
      return null;
    }

    return (
      <TableUpdater
        order={order}
        disableUIFunc={disableUIFunc}
        render={({ saveLines, errors }) => (
          <React.Fragment>
            <span
              onClick={this.openModal}
              className={"order-line-comment" + (noComment ? " empty" : "")}
            >
              {displayComment}
            </span>

            {this.state.isOpen && (
              <Modal
                onClose={this.closeModal}
                show={this.state.isOpen}
                header={"Edit Comment"}
                renderFooter={() => this.saveButton(editable)}
              >
                {!editable && <div>{displayComment}</div>}
                {editable && (
                  <EditTextArea
                    onChange={this.handleChange}
                    name={"comment"}
                    value={comment}
                  />
                )}
              </Modal>
            )}
          </React.Fragment>
        )}
      />
    );
  }

  handleChange = (field, value) => {
    this.setState({ comment: value });
  };

  saveButton = editable => {
    if (!editable) return null;
    return (
      <button
        className={"button primary"}
        onClick={this.updateComment}
        disabled={this.state.updating}
      >
        Save
      </button>
    );
  };

  updateComment = () => {
    this.setState({ updating: true });
    this.props.saveLine({
      id: this.props.data.id,
      comment: this.state.comment
    });
    this.closeModal();
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

CommentCell.propTypes = {
  order: OrderObject.isRequired,
  data: PropTypes.object.isRequired,
  value: PropTypes.string,
  disableUIFunc: PropTypes.func.isRequired,
  saveLine: PropTypes.func.isRequired
};

export default CommentCell;
