import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import ClinicalJustification from "./ClinicalJustification";
import SeekApproval from "./SeekApproval";
import AssignOrderBtn from "./AssignOrderBtn";
import OrderValidationMessage from "./../OrderValidationMessage";
import { isOnDvaContract } from "./../BusRules/access";

const PreValidationStepsIndex = props => {
  if (props.order == null) {
    return <div>loading...</div>;
  }
  if (props.order.uiState.processStep2Message) {
    return (
      <OrderValidationMessage>
        {props.order.uiState.processStep2Message}
      </OrderValidationMessage>
    );
  }
  if (isOnDvaContract(props.order)) {
    return (
      <section>
        <ClinicalJustification {...props} />
        <SeekApproval {...props} approval="seekPriorApproval" />
        <AssignOrderBtn {...props} />
      </section>
    );
  } else {
    return (
      <section>
        <AssignOrderBtn {...props} />
      </section>
    );
  }
};

PreValidationStepsIndex.propTypes = {
  order: OrderObject,
  updateOrder: PropTypes.func.isRequired
};

export default PreValidationStepsIndex;
