import React from "react";
import { Link } from "react-router-dom";

import { OrderObject } from "../Queries/OrderQuery";

const TransferredMsg = ({ order }) => {
  if (
    typeof order.transferredToIds === "undefined" ||
    order.transferredToIds.length === 0
  ) {
    return null;
  }
  return (
    <div className="message message--success" role="alert">
      <p>
        Some hire items have been transferred to other orders:{" "}
        {order.transferredToIds.map(orderId => (
          <React.Fragment key={orderId}>
            <Link to={`/orders/${orderId}`} key={orderId}>
              #{orderId}
            </Link>
            &nbsp;
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};

TransferredMsg.propTypes = {
  order: OrderObject
};

export default TransferredMsg;
