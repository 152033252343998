import React from "react";

import { apolloClient } from "GraphQL/GraphqlWrapper";
import processingInvoiceLines from "../Queries/graphql/processingInvoiceLines";

const ProcessingInvoicesWarningMsg = () => {
  const cachedProcessingInvoiceLines = apolloClient.readFragment({
    id: processingInvoiceLines.cacheFragmentId,
    fragment: processingInvoiceLines.query
  });
  if (
    cachedProcessingInvoiceLines &&
    cachedProcessingInvoiceLines.processingInvoiceLineIds.length
  ) {
    return (
      <div className="message message--warning" role="alert">
        <p>
          One or more lines are currently being processed and so are not listed
          here
        </p>
      </div>
    );
  }
  return null;
};

export default ProcessingInvoicesWarningMsg;
