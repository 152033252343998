import React from "react";

import Modal from "Util/Modal";
import { OrderObject } from "./../Queries/OrderQuery";
import ReassignStaffMember from "./index";

class ReassignBtn extends React.Component {
  state = { isOpen: false };

  render() {
    const { order } = this.props;

    return (
      <React.Fragment>
        <button className="button primary" onClick={this.toggleModal}>
          Reassign to different staff
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            header="Select the staff member to assign the order to"
            onClose={this.toggleModal}
          >
            <ReassignStaffMember order={order} complete={this.toggleModal} />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  toggleModal = data => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
}

ReassignBtn.propTypes = {
  order: OrderObject
};

export default ReassignBtn;
