import React from "react";
import PropTypes from "prop-types";
import { formatPhone } from "./formatPhone";

const RoPhoneField = ({ value, labelText, valueNote }) => {
  const textValue = formatPhone(value) || "-";
  return (
    <div className="field">
      <label htmlFor={labelText}>{labelText}</label>
      <div>
        {textValue}
        <em style={{ marginLeft: 5, color: "grey" }}>{valueNote}</em>
      </div>
    </div>
  );
};

RoPhoneField.propTypes = {
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  valueNote: PropTypes.string
};

export default RoPhoneField;
