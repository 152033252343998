import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class CopyOrderBtn extends React.Component {
  render() {
    return (
      <button className="button primary" onClick={this.open}>
        Copy order to add lines
      </button>
    );
  }

  open = () => {
    if (
      window.confirm(
        "Are you sure you want to copy the order?\n\n" +
          "This option is only to be used when resending a new " +
          "order to a branch with a substitute code for the " +
          "removed item/s. A new order will be created with no " +
          "codes. You must add lines, and reassign to the branch."
      )
    ) {
      this.cancelOrder();
    }
  };

  cancelOrder = () => {
    const { update, order } = this.props;
    update({
      variables: {
        id: order.id
      }
    });
  };
}

CopyOrderBtn.propTypes = {
  order: OrderObject,
  update: PropTypes.func.isRequired
};

export default CopyOrderBtn;
