import React from "react";
import PropTypes from "prop-types";

import { CurrentUserObject, OrderObject } from "./../Queries/OrderQuery";

class AssignToMeBtn extends React.Component {
  render() {
    const { currentUser, order } = this.props;
    if (String(order.assignedStaffId) === String(currentUser.id)) {
      return null;
    }
    return (
      <button className="button primary" onClick={this.assign}>
        Assign To Me
      </button>
    );
  }

  assign = e => {
    const { currentUser, order } = this.props;
    e.preventDefault();
    this.props.update({
      variables: {
        id: order.id,
        input: {
          userId: currentUser.id
        }
      }
    });
  };
}

AssignToMeBtn.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  order: OrderObject,
  update: PropTypes.func.isRequired
};

export default AssignToMeBtn;
