import React from "react";
import PropTypes from "prop-types";

const StatusCellRenderer = ({ params }) => (
  <span className={`label label--${params.data.status.toLowerCase()}`}>
    {params.data.statusDesc}
  </span>
);

StatusCellRenderer.propTypes = {
  params: PropTypes.object.isRequired
};

export default StatusCellRenderer;
