import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import AidacareStaffQuery from "./AidacareStaffQuery";
import AssignUpdater from "./AssignUpdater";
import ToStaffMember from "./ToStaffMember";

class ReassignStaffMemberIndex extends React.Component {
  render() {
    const { order, complete } = this.props;

    return (
      <AidacareStaffQuery
        orderId={order.id}
        render={({ staffMembers }) => (
          <div className="assign-staff-member-to-order">
            <ul className="assign-staff-member-to-order-list">
              {staffMembers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(staff => (
                  <li key={staff.id}>
                    <AssignUpdater
                      order={order}
                      staff={staff}
                      complete={complete}
                      render={props => <ToStaffMember {...props} />}
                    />
                  </li>
                ))}
            </ul>
          </div>
        )}
      />
    );
  }
}

ReassignStaffMemberIndex.propTypes = {
  order: OrderObject,
  complete: PropTypes.func
};

export default ReassignStaffMemberIndex;
