import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";

const MUTATION = gql`
  mutation reverseInvoiceLines($id: ID!, $input: [InvoiceLineInput!]!) {
    reverseInvoiceLines(id: $id, input: $input) {
      order {
        invoiceableLinesCount
        reversibleLinesCount
        ...OrderFields
        ...OrderLinesAndEventsQuery
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
`;

class ProcessInvoiceLinesUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation mutation={MUTATION} onError={this.onError}>
        {(update, { loading, error }) => {
          const processLines = (orderId, lines) => {
            const input = lines.map(line => this.whitelistedInput(line));
            update({
              variables: {
                id: orderId,
                input: input
              }
            });
          };
          return render({ processLines });
        }}
      </Mutation>
    );
  }

  whitelistedInput = line => {
    const input = {};
    const whitelist = ["id"];

    whitelist.map(key => {
      if (line[key] !== undefined) {
        input[key] = line[key];
      }
    });
    return input;
  };

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

ProcessInvoiceLinesUpdater.propTypes = {
  render: PropTypes.func
};

export default ProcessInvoiceLinesUpdater;
