import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";
import EditDateField from "Form/EditDateField";
import EditTextArea from "Form/EditTextArea";

import { OrderObject } from "./../Queries/OrderQuery";
import {
  minDeliveredOnDateAllowed,
  validDeliveredOn
} from "../BusRules/orderLines";
import Table from "./Table";
import ProcessLinesQuery from "./ProcessLinesQuery";
import ProcessOrderLinesUpdater from "./ProcessOrderLinesUpdater";

class ProcessOrderLines extends React.Component {
  state = { form: { deliveredOn: "" } };
  render() {
    const { order, toggleOpen, option, disableUIFunc } = this.props;
    return (
      <ProcessLinesQuery
        orderId={order.id}
        queryField={option.queryField}
        render={({ orderLines }) =>
          orderLines && (
            <Modal
              show={true}
              header={option.headerText}
              onClose={toggleOpen}
              className="invoiceable-lines"
            >
              <div>
                <h2>CMP Order #: {order.cmpOrderNumber}</h2>
                <p className="invoiceable-lines-message">
                  {option.subheaderText}
                </p>
                <Table
                  {...this.props}
                  orderLines={orderLines}
                  processSelections={selections => (
                    <ProcessOrderLinesUpdater
                      disableUIFunc={disableUIFunc}
                      render={({ processLines }) => (
                        <React.Fragment>
                          {this.optionalFields()}
                          <div className="button-group">
                            <button
                              className="button primary right"
                              onClick={() =>
                                this.processAction(selections, processLines)
                              }
                              disabled={this.isActionDisabled(selections, orderLines)}
                            >
                              {option.actionButtonText}
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                    />
                  )}
                />
              </div>
            </Modal>
          )
        }
      />
    );
  }
  optionalFields = () => {
    const { order, option } = this.props;
    const { form } = this.state;

    return (
      <form onSubmit={this.onSubmit} className={"form"}>
        <fieldset className={"fieldset"}>
          {option.fields.includes("deliveredOn") && (
            <EditDateField
              name="deliveredOn"
              onChange={this.handleChange}
              value={form.deliveredOn}
              limit="TodayBack"
              limits={{ startDate: minDeliveredOnDateAllowed(order) }}
              labelText="Delivery Date"
            />
          )}
          {option.fields.includes("pickupInstructions") && (
            <EditTextArea
              name="pickupInstructions"
              onChange={this.handleChange}
              value={form.pickupInstructions}
              labelText="Pickup Instructions"
            />
          )}
          {option.fields.includes("pickedUpOn") && (
            <EditDateField
              name="pickedUpOn"
              onChange={this.handleChange}
              value={form.pickedUpOn}
              limit="TodayBack"
              limits={{ startDate: minDeliveredOnDateAllowed(order) }}
              labelText="Returned Date"
            />
          )}
        </fieldset>
      </form>
    );
  };

  handleChange = (field, value) => {
    let form = { ...this.state.form, [field]: value };
    if (field === "deliveredOn" && !validDeliveredOn(this.props.order, value)) {
      form = { ...this.state.form, [field]: "" };
    }
    this.setState({ form });
  };

  isActionDisabled = (selections, orderLines) => {
    const { option } = this.props;
    let disableButton = false;
    if (typeof option.disableActionButton === "function") {
      disableButton = option.disableActionButton(selections, orderLines);
    }
    return (
      selections.length === 0 || this.isRequiredFieldMissing() || disableButton
    );
  };

  isRequiredFieldMissing = () => {
    if (this.props.option.requiredFields.length > 0) {
      return !this.props.option.requiredFields.find(f => !!this.state.form[f]);
    }
    return false;
  };

  processAction = (selections, processLines) => {
    const { option } = this.props;
    const { form } = this.state;
    const status = option.status;
    const lines = selections.map(id => {
      let line = { id, status };
      if (status === "on_hire") {
        line["hireEndOn"] = "";
      }
      option.fields.forEach(field => (line[field] = form[field]));
      return line;
    });
    processLines(this.props.order.id, lines, {
      comment: form.pickupInstructions
    });
    this.props.disableUIFunc(true);
    this.props.toggleOpen();
  };
}

ProcessOrderLines.propTypes = {
  order: OrderObject,
  option: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default ProcessOrderLines;
