import React from "react";
import PropTypes from "prop-types";

import GpProductList from "./GpProductList";
import { OrderObject } from "./../../Queries/OrderQuery";

class NonContractedItemPicker extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GpProductList
          contractId={this.props.order.contract.id}
          order={this.props.order}
          useGpProduct={this.props.useGpProduct}
          currentUser={this.props.currentUser}
        />
        <footer className="modal__footer">
          <button
            className="button button--back"
            onClick={this.props.complete}
          >
            Back to order
          </button>
        </footer>
      </React.Fragment>
    );
  }
}

NonContractedItemPicker.propTypes = {
  order: OrderObject.isRequired,
  complete: PropTypes.func.isRequired,
  useGpProduct: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default NonContractedItemPicker;
