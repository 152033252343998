import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";
import AcceptOrderUpdater from "./AcceptOrderUpdater";

class AcceptBtn extends React.Component {
  state = { isOpen: false, acceptDisabled: false };

  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <button className="button primary" onClick={this.open}>
          Accept
        </button>
        {this.state.isOpen && (
          <Modal
            header="Accept Order"
            show={this.state.isOpen}
            onClose={this.toggleModal}
            renderFooter={() => (
              <AcceptOrderUpdater
                order={order}
                render={({ acceptOrder }) => (
                  <button
                    className="button primary"
                    onClick={() => this.acceptOrder(acceptOrder)}
                    disabled={this.state.acceptDisabled}
                  >
                    ACCEPT
                  </button>
                )}
              />
            )}
          >
            <div className="group_section">
              By accepting this order, you acknowledge receipt of the order. The
              order may be amended (after acceptance) by contacting us. Please
              confirm you would like to accept the order.
            </div>
            <div className="group_section">
              <em>
                <b>RCTI Acknowlegement:</b> This order will be invoiced on an
                RCTI (Recipient Created Tax Invoice) basis.  Under an RCTI, You
                (the Supplier) will not issue tax invoices in respect of these
                supplies. Instead, Aidacare (the Recipient) will issue all tax
                invoices and make payment accordingly. Any GST on the
                transaction remains your responsibility to remit. Please advise
                Aidacare immediately if your GST status changes. Acceptance of
                this order constitutes acceptance of the RCTI invoicing basis on
                which it is offered.
              </em>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }

  acceptOrder = update => {
    this.setState({ acceptDisabled: true });
    update();
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  open = () =>
    this.setState({
      isOpen: true
    });
}

AcceptBtn.propTypes = {
  order: OrderObject
};

export default AcceptBtn;
