import React from "react";
import PropTypes from "prop-types";
import { OrderObject } from "./../Queries/OrderQuery";

import Details from "./Details";
import Client from "./../Client/index";
import HealthcareProvider from "./../HealthcareProvider/index";
import { isOnDvaContract } from "../BusRules/access";
import AdditionalInfo from "./AdditionalInfo";
import Facility from "../Facility";

const OrderDetails = ({
  order,
  currentUser,
  salesReps,
  disableUIFunc,
  updateOrder
}) => {
  return (
    <article className={"display_group display_group--outlined"}>
      <Details
        order={order}
        updateOrder={updateOrder}
        currentUser={currentUser}
        salesReps={salesReps}
        editable={order.uiState.editable}
        editableAfterGP={order.uiState.allowEditOrderAfterGpSync}
        disableUIFunc={disableUIFunc}
      />
      <section className="display_group__item width-two-fifths">
        {order.uiState.showClientAndHcp && (
          <Client
            order={order}
            client={order.client}
            editable={order.uiState.editable}
            currentUser={currentUser}
            disableUIFunc={disableUIFunc}
          />
        )}
        {order.uiState.showFacility && (
          <Facility
            facility={order.facility}
            order={order}
            editable={order.uiState.editable}
            currentUser={currentUser}
            disableUIFunc={disableUIFunc}
          />
        )}
      </section>
      <section className="display_group__item width-two-fifths">
        {order.uiState.showClientAndHcp && (
          <HealthcareProvider
            hcp={order.healthcareProvider}
            order={order}
            currentUser={currentUser}
            editable={order.uiState.editable}
            editableAfterGP={order.uiState.allowEditHcpAfterGpSync}
            disableUIFunc={disableUIFunc}
          />
        )}
        {!isOnDvaContract(order) && <AdditionalInfo order={order} />}
      </section>
    </article>
  );
};

OrderDetails.propTypes = {
  order: OrderObject,
  currentUser: PropTypes.object.isRequired,
  salesReps: PropTypes.array.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired
};

export default OrderDetails;
