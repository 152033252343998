import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import AddRowToOrderBtn from "../OrderDetails/AddRowToOrderBtn";
import { OrderObject } from "../Queries/OrderQuery";
import { isOnDvaContract } from "../BusRules/access";

class HcpsTable extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.hcpsQuery}
        responseDataKey="healthcareProviders"
        localStorageKey="orderHealthcareProviders"
        frameworkComponents={this.frameworkComponents}
      />
    );
  }

  frameworkComponents = {
    actionCellRenderer: row => {
      return <AddRowToOrderBtn row={row.data} update={this.props.update} />;
    }
  };

  defaultColumnDefs = () => {
    return [
      {
        headerName: "Action",
        field: "action",
        pinned: "left",
        width: 80,
        sortable: false,
        filter: false,
        cellRenderer: "actionCellRenderer",
        valueGetter: params => null // value not part of dataset
      },
      {
        headerName: "Surname",
        field: "lastName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Given Name",
        field: "firstName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Healthcare Provider Group",
        field: "groupName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "AHPRA",
        field: "ahpraNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Medicare Provider Number",
        field: "medicareProviderNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Provider Type",
        field: "providerType",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "address",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "city",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Postcode",
        field: "postcode",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "state",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Phone #",
        field: "phoneNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Mobile #",
        field: "mobileNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter"
      }
    ];
  };

  hcpsQuery = () => {
    const contractId = this.props.order.contract.id;
    const epiOnly =
      isOnDvaContract(this.props.order) &&
      this.props.order.sourceSystem === "EPI";
    return gql`
      query HealthcareProviders($agGridParams: RequestParamInput!) {
        healthcareProviders(contractId: ${contractId}, activeOnly: true, excludeDrafts: true, agGridParams: $agGridParams, epiOnly: ${epiOnly}) {
          id
          firstName
          lastName
          name
          groupName
          medicareProviderNumber
          providerType
          phoneNumber
          mobileNumber
          email
          address
          city
          postcode
          state
          ahpraNumber
        }
      }
    `;
  };
}

HcpsTable.propTypes = {
  order: OrderObject.isRequired,
  update: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default HcpsTable;
