import React from "react";
import PropTypes from "prop-types";
import { OrderObject } from "./../Queries/OrderQuery";

import RoField from "Form/RoField";
import RoDateField from "Form/RoDateField";
import RoDateTimeField from "Form/RoDateTimeField";
import RoFieldWrapper from "Form/RoFieldWrapper";
import RoMemo from "Form/RoMemo";
import { OrderLink } from "./../OrderNav";
import {
  isHireOrder,
  isAgedCareOrder,
  isHospitalOrder,
  isHospitalDischargeOrder,
  boolFieldYesNo
} from "./../BusRules/orders";
import {
  allowPrivilegedFields,
  isOnDvaContract
} from "./../BusRules/access";
import RoYesNo from "../../lib/Form/RoYesNo";

const OrderDetailsView = ({ order, currentUser }) => (
  <fieldset className="fieldset fieldset--compact">
    <RoField
      value={order.statusDesc}
      dataLabelClass={order.status}
      labelText="Status"
    />
    <RoField value={order.cmpOrderNumber} labelText="CMP Order No" />
    <RoField value={order.orderType} labelText="Order Type" />
    {isOnDvaContract(order) && order.includesHomeMods && (
      <RoYesNo value={order.complexHomeMods} labelText="Complex Home Mod" />
    )}
    <RoField value={order.webOrderNo} labelText="Web Order No" />
    <RoField value={order.sourceSystem} labelText="Originated" />
    <RoField value={order.contract.contractCode} labelText="Contract" />
    {allowPrivilegedFields(order) && isAgedCareOrder(order) && (
      <RoField value={order.contract.contractType} labelText="Contract Type" />
    )}
    {allowPrivilegedFields(order) &&
      order.contract.contractType === "Hospital" && (
        <RoField value={order.hospitalType} labelText="Hospital Type" />
      )}
    {isHospitalDischargeOrder(order) && (
      <RoYesNo
        value={order.vic30DayDischarge}
        labelText="Vic 30 Day Discharge"
      />
    )}
    <RoField value={order.orderDate} labelText="Order Date" />
    {allowPrivilegedFields(order) && isOnDvaContract(order) && (
      <RoDateField value={order.dateReceived} labelText="Order Received Date" />
    )}
    {allowPrivilegedFields(order) && (
      <RoDateField value={order.followUpOn} labelText="Follow Up Date" />
    )}
    {!allowPrivilegedFields(order) && (
      <RoDateField value={order.npFollowUpOn} labelText="Follow Up Date" />
    )}
    {allowPrivilegedFields(order) && (
      <RoField value={order.priorApprovalNumber} labelText="Prior Approval #" />
    )}
    <RoField
      value={order.invoiceNo}
      labelText={
        allowPrivilegedFields(order) ||
        (order.partner && order.partner.isInternal)
          ? "GP Number"
          : "Invoice No"
      }
      clickToCopy={!["Check Lines", null].includes(order.invoiceNo)}
    />
    {allowPrivilegedFields(order) && isHospitalOrder(order) && (
      <RoField value={order.costCentre} labelText="Cost Centre" />
    )}
    {allowPrivilegedFields(order) && !isOnDvaContract(order) && (
      <RoField value={order.cpo} labelText="CPO" />
    )}
    <RoField
      value={boolFieldYesNo(order.urgent)}
      dataLabelClass={order.urgent ? "urgent" : ""}
      labelText="Urgent"
    />
    {order.uiState.showAwaitingCall && (
      <RoField
        value={boolFieldYesNo(order.awaitingCall)}
        dataLabelClass={order.awaitingCall ? "urgent" : ""}
        labelText="Awaiting Call"
      />
    )}
    <RoField value={order.assignedStaffName} labelText="Aidacare Staff" />
    {order.uiState.hasSalesRep && order.salesRep && (
      <RoField value={order.salesRep.name} labelText="Sales Rep" />
    )}
    {order.uiState.showDebtor && (
      <RoField
        value={order.debtor ? order.debtor.code : "-"}
        labelText="Debtor"
      />
    )}
    {isHireOrder(order) && (
      <React.Fragment>
        <RoDateField value={order.hireStartOn} labelText="Hire Start Date" />
        <RoDateField value={order.hireEndOn} labelText="Hire End Date" />
      </React.Fragment>
    )}

    <RoDateTimeField value={order.updatedAt} labelText="Last changed at" />
    {order.parentOrder && (
      <RoFieldWrapper labelText="Related Order">
        <OrderLink order={order.parentOrder}>
          View #{order.parentOrder.cmpOrderNumber}
        </OrderLink>
      </RoFieldWrapper>
    )}
    {order.orderAllocations && order.orderAllocations.length > 0 && (
      <RoField value="Yes" labelText="Related Orders" />
    )}
    {allowPrivilegedFields(order) && !isOnDvaContract(order) && (
      <RoYesNo value={order.fromQuote} labelText="From Quote" />
    )}
    {allowPrivilegedFields(order) && isHospitalOrder(order) && (
      <RoYesNo value={order.consignment} labelText="Consignment" />
    )}
    {allowPrivilegedFields(order) && (
      <RoMemo value={order.adminNotes} labelText="Admin Notes" small={true} />
    )}
  </fieldset>
);

OrderDetailsView.propTypes = {
  order: OrderObject,
  currentUser: PropTypes.object.isRequired
};

export default OrderDetailsView;
