import React from "react";
import PropTypes from "prop-types";

// NOTE: changes to this may need to be reflected in CmpAgGrid getContextMenuItems
class CmpAgGridLink extends React.Component {
  render() {
    const { value, link, newTab } = this.props;

    return (
      <React.Fragment>
        <a href={link} target={newTab ? "_blank" : "_self"}>
          {value}
        </a>
      </React.Fragment>
    );
  }
}

CmpAgGridLink.propTypes = {
  value: PropTypes.string,
  link: PropTypes.string.isRequired,
  newTab: PropTypes.bool
};

export default CmpAgGridLink;
