import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

const OrderTabButton = ({
  className,
  order,
  currOrder,
  children,
  setCurrOrder
}) => (
  <button
    onClick={() => setCurrOrder(order)}
    className={`tab ${currOrder === order ? "is-active" : ""} ${className}`}
  >
    {children}
  </button>
);

OrderTabButton.propTypes = {
  order: OrderObject.isRequired,
  currOrder: OrderObject.isRequired,
  className: PropTypes.string,
  setCurrOrder: PropTypes.func.isRequired
};

export default OrderTabButton;
