import { lineStatuses } from "./orderLines";

// true for now, likely to change in the future
const canViewVisibleTo = user => true;
const showManualOrder = user => user.aidacareHo;
const showComplexHMTabs = (user, order) =>
  order.uiState.currentUserIsHeadOffice &&
  isOnDvaContract(order) &&
  order.complexHomeMods;
const showEditOrderDetails = user => true;
const showAddProducts = (user, order) =>
  order.uiState.currentUserIsHeadOffice && order.uiState.canEditLines;
const showRemoveProducts = (user, order) =>
  order.uiState.currentUserIsHeadOffice && order.uiState.canEditLines;

const allowEditQuantity = (user, order, orderLine) =>
  order.uiState.currentUserIsHeadOffice &&
  order.uiState.canEditLines &&
  !["HIRE", "RECYCLED"].includes(orderLine.saleType) &&
  lineStatuses.EDIT_QUANTITY.includes(orderLine.status);

const allowPrivilegedFields = order => order.uiState.currentUserIsHeadOffice;
const isOnDvaContract = model => {
  let contractCode = "";
  if (model.hasOwnProperty("contractCode")) {
    contractCode = model.contractCode;
  } else if (model.hasOwnProperty("contract")) {
    contractCode = model.contract;
    if (model.contract.hasOwnProperty("contractCode")) {
      contractCode = model.contract.contractCode;
    }
  }
  return contractCode === "DVA";
};
const isErapOrder = order => ["ERAP", "CMP"].includes(order.sourceSystem);
const isNonErapHomeMods = order =>
  !isErapOrder(order) && order.includesHomeMods;
const isNonErapDvaOrder = order =>
  !isErapOrder(order) && isOnDvaContract(order);
const isNEDOrder = order => order.sourceSystem === "NED";

export {
  canViewVisibleTo,
  showManualOrder,
  showComplexHMTabs,
  showEditOrderDetails,
  showAddProducts,
  showRemoveProducts,
  allowEditQuantity,
  allowPrivilegedFields,
  isOnDvaContract,
  isErapOrder,
  isNonErapHomeMods,
  isNonErapDvaOrder,
  isNEDOrder
};
