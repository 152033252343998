import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const StatusEditor = memo(
  ({ value, onValueChange, node, setEditedLineStatus }) => {
    const refContainer = useRef(null);

    useEffect(() => {
      refContainer.current.focus();
    }, []);

    const onChange = event => {
      const newValue = event.target.value;

      onValueChange(newValue);
      setEditedLineStatus(newValue);
    };

    return (
      <select
        ref={refContainer}
        value={value}
        className={"ag-edit-custom"}
        onChange={e => onChange(e)}
      >
        {node.data.fulfillmentStatusOptions.map(o => (
          <option key={o}>{o}</option>
        ))}
      </select>
    );
  }
);

StatusEditor.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  node: PropTypes.object,
  setEditedLineStatus: PropTypes.func
};

export default StatusEditor;
