import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";

const NetworkPartnerUsersApp = props => {
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        render={({ contractCodes, currentUser }) => (
          <section className="network_partner_section">
            <Table contractCodes={contractCodes} currentUser={currentUser} />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default NetworkPartnerUsersApp;
