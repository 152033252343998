import React from "react";
import PropTypes from "prop-types";

import { OrderLineObject } from "./../../Queries/OrderQuery";
import { hideIsClientEligible } from "./../../BusRules/orderLines";

class ClientIsEligible extends React.Component {
  render() {
    const { line } = this.props;

    if (hideIsClientEligible(line)) {
      return null;
    }

    return (
      <form className="order-lines-client-is-eligible">
        <label>
          <input
            name="clientIsEligible"
            type="radio"
            value="true"
            onChange={this.handleClientIsEligibleChange}
            checked={line.clientIsEligible === true}
          />{" "}
          Yes
        </label>
        <label>
          <input
            name="clientIsEligible"
            type="radio"
            value="false"
            onChange={this.handleClientIsEligibleChange}
            checked={line.clientIsEligible === false}
          />{" "}
          No
        </label>
      </form>
    );
  }

  handleClientIsEligibleChange = e => {
    this.props.saveLine({
      id: this.props.line.id,
      clientIsEligible: e.target.value === "true"
    });
  };
}

ClientIsEligible.propTypes = {
  line: OrderLineObject.isRequired,
  saveLine: PropTypes.func.isRequired
};

export default ClientIsEligible;
