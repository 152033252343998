import React from "react";
import PropTypes from "prop-types";

import { ClientObject } from "./../Queries/OrderQuery";

import EditTextField from "Form/EditTextField";
import EditTextArea from "Form/EditTextArea";
import EditDropdown from "Form/EditDropdown";
import EditDateField from "Form/EditDateField";
import EditYesNo from "Form/EditYesNo";
import EditAddressFields from "Form/EditAddressFields";
import EditDeliveryAddressFields from "Form/EditDeliveryAddressFields";
import ErrorDisplay from "Form/ErrorDisplay";
import {
  isErapOrder,
  isNonErapDvaOrder,
  isOnDvaContract
} from "../BusRules/access";
import ReferenceField from "./ReferenceField";

class ClientForm extends React.Component {
  state = { client: null };

  static getDerivedStateFromProps(props, state) {
    // set local copy of client for editing
    if (state.client == null) {
      return {
        client: props.client
      };
    }
    return null;
  }

  render() {
    const { client } = this.state;
    const { errors, order } = this.props;

    return (
      <form onSubmit={this.onSubmit} className={"form"}>
        <ErrorDisplay errors={errors} modelDesc="client" />
        <div className={"display-flex"}>
          <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
            <EditTextField
              name="lastName"
              onChange={this.handleChange}
              value={client.lastName}
              labelText="Surname"
              errors={errors}
            />
            <EditTextField
              name="firstName"
              onChange={this.handleChange}
              value={client.firstName}
              labelText="Given names"
              errors={errors}
            />
            {isErapOrder(order) && (
              <EditDropdown
                name="gender"
                onChange={this.handleChange}
                value={client.gender}
                labelText="Gender"
                errors={errors}
                options={client.genderOptions}
              />
            )}
            <ReferenceField
              client={client}
              contractId={order.contract.id}
              handleChange={this.handleChange}
              errors={errors}
            />
            <EditDateField
              name="dateOfBirth"
              onChange={this.handleChange}
              value={client.dateOfBirth}
              labelText="Date of birth"
              limit="TodayBack"
              limits={{ startDate: "1900-01-01" }}
              errors={errors}
            />
            {isOnDvaContract(order) && (
              <EditDropdown
                name="cardType"
                onChange={this.handleChange}
                value={client.cardType}
                labelText="Card type"
                errors={errors}
                options={client.cardTypeOptions}
              />
            )}
            {isOnDvaContract(order) && isErapOrder(order) && (
              <EditYesNo
                name="homecarePackageLevel"
                onChange={this.handleChange}
                value={client.homecarePackageLevel}
                labelText="Home Care Packages lvl 4"
                errors={errors}
              />
            )}
            {isOnDvaContract(order) && (
              <EditYesNo
                name="residentialCareFacility"
                onChange={this.handleChange}
                value={client.residentialCareFacility}
                labelText="Residential care facility"
                errors={errors}
              />
            )}
            {isOnDvaContract(order) && (
              <EditTextField
                name="acfiClassification"
                onChange={this.handleChange}
                value={client.acfiClassification}
                labelText="ACFI classification"
                errors={errors}
              />
            )}
            <EditDropdown
              name="status"
              onChange={this.handleChange}
              value={client.status}
              labelText="Client Status"
              errors={errors}
              options={client.statusOptions}
            />
            <EditTextField
              name="phoneNumber"
              onChange={this.handleChange}
              type="tel"
              value={client.phoneNumber}
              labelText="Phone No"
              errors={errors}
            />
            <EditTextField
              name="mobileNumber"
              onChange={this.handleChange}
              type="tel"
              value={client.mobileNumber}
              labelText="Mobile No"
              errors={errors}
            />
            <EditTextField
              name="email"
              onChange={this.handleChange}
              type="email"
              value={client.email}
              labelText="Email"
              errors={errors}
            />
            <EditTextField
              name="altContactName"
              onChange={this.handleChange}
              value={client.altContactName}
              labelText="Alternative Contact Name"
              errors={errors}
            />
            <EditTextField
              name="altPhoneNumber"
              onChange={this.handleChange}
              type="tel"
              value={client.altPhoneNumber}
              labelText="Alternative Contact Phone No"
              errors={errors}
            />
            {isNonErapDvaOrder(order) && (
              <EditYesNo
                name="fundingOther"
                onChange={this.handleChange}
                value={client.fundingOther}
                labelText="Other Funding"
                errors={errors}
              />
            )}
            {isNonErapDvaOrder(order) && client.fundingOther && (
              <React.Fragment>
                <EditDropdown
                  name="fundingOtherType"
                  onChange={this.handleChange}
                  value={client.fundingOtherType}
                  labelText="Other Funding Type"
                  errors={errors}
                  options={client.fundingOptions}
                  required={client.fundingOther}
                />
                <EditTextArea
                  name="fundingOtherNotes"
                  onChange={this.handleChange}
                  value={client.fundingOtherNotes}
                  labelText="Other Funding Notes"
                  errors={errors}
                />
              </React.Fragment>
            )}
          </fieldset>
          <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
            <EditAddressFields
              model={client}
              onChange={this.handleChange}
              errors={errors}
            />
            {isOnDvaContract(order) && (
              <EditDateField
                name="dischargeDate"
                onChange={this.handleChange}
                value={client.dischargeDate}
                labelText="Date of Discharge"
                errors={errors}
              />
            )}
            <EditYesNo
              name="differentDeliveryAddress"
              onChange={this.handleChange}
              value={client.differentDeliveryAddress}
              labelText="Delivery address different to Residential"
              errors={errors}
            />
            {client.differentDeliveryAddress && (
              <EditDeliveryAddressFields
                model={client}
                onChange={this.handleChange}
                errors={errors}
              />
            )}
            {!order.includesRepair && (
              <EditTextArea
                name="deliveryInstructions"
                onChange={this.handleChange}
                value={client.deliveryInstructions}
                labelText="Delivery Instructions"
                errors={errors}
              />
            )}
            {isNonErapDvaOrder(order) && order.includesHomeMods && (
              <EditTextArea
                name="homeModsInstructions"
                onChange={this.handleChange}
                value={client.homeModsInstructions}
                labelText="Home Mods Instructions"
                errors={errors}
              />
            )}
          </fieldset>
        </div>
        <div className="form__actions">
          <input
            onClick={this.save}
            type="submit"
            name="commit"
            value="Update Client"
            className="button primary"
          />
        </div>
      </form>
    );
  }

  onSubmit = e => {
    const { client } = this.state;
    e.preventDefault();

    this.props.update({
      variables: {
        id: client.id,
        input: {
          reference: client.reference,
          firstName: client.firstName,
          lastName: client.lastName,
          gender: client.gender,
          dateOfBirth: client.dateOfBirth,
          status: client.status,
          dischargeDate: client.dischargeDate,
          phoneNumber: client.phoneNumber,
          mobileNumber: client.mobileNumber,
          email: client.email,
          altContactName: client.altContactName,
          altPhoneNumber: client.altPhoneNumber,
          cardType: client.cardType,
          homecarePackageLevel: client.homecarePackageLevel,
          residentialCareFacility: client.residentialCareFacility,
          acfiClassification: client.acfiClassification,
          fundingOther: client.fundingOther,
          fundingOtherType: client.fundingOtherType,
          fundingOtherNotes: client.fundingOtherNotes,
          address: client.address,
          city: client.city,
          postcode: client.postcode,
          state: client.state,
          deliveryAddress: client.deliveryAddress,
          deliveryCity: client.deliveryCity,
          deliveryPostcode: client.deliveryPostcode,
          deliveryState: client.deliveryState,
          deliveryInstructions: client.deliveryInstructions,
          differentDeliveryAddress: client.differentDeliveryAddress,
          homeModsInstructions: client.homeModsInstructions
        }
      }
    });
  };

  handleChange = (field, value) => {
    // Remove non-digits to allow cut & paste
    if (["phoneNumber", "mobileNumber", "altPhoneNumber"].includes(field)) {
      value = value.replace(/[^\d]/g, "");
    }
    let client = { ...this.state.client, [field]: value };
    if (field === "fundingOther" && !value) {
      client = { ...this.state.client, [field]: value, fundingOtherType: null };
    }
    this.setState({ client });
  };
}

ClientForm.propTypes = {
  client: ClientObject,
  update: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  errors: PropTypes.array
};

export default ClientForm;
