import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";

const SplitUpdater = ({ render, order }) => {
  return (
    <GraphqlWrapper>
      <Mutation mutation={SPLIT_LINES}>
        {(update, { loading, error }) => {
          const splitSelections = (specialOrder, selections) => {
            update({
              variables: {
                id: order.id,
                input: {
                  specialOrder,
                  ids: selections
                }
              }
            });
          };
          return render({ order, splitSelections });
        }}
      </Mutation>
    </GraphqlWrapper>
  );
};

SplitUpdater.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default SplitUpdater;

const SPLIT_LINES = gql`
  mutation splitLinesToNewOrder($id: ID!, $input: SplitLinesInput!) {
    splitLinesToNewOrder(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
`;
