import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment CommentQuery on Comment {
      id
      ownerName
      visibleTo
      isRead
      canEditIsRead
      body
      canEditBody
      bodyEditedAt
      addedAt
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ownerName: PropTypes.string,
    visibleTo: PropTypes.string,
    body: PropTypes.string,
    addedAt: PropTypes.string
  })
};

export default fragments;
