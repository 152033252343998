import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import AutoInvoicingUpdater from "./AutoInvoicingUpdater";
import {
  allowSwitchManualAuto,
  showProcessInvoices,
  showCancelInvoices
} from "./businessRules";

class MenuItems extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <ul className="button__menu">
        <li>
          {showProcessInvoices(order) ? (
            <span
              onClick={this.props.toggleProcessPopup}
              className="button__menu__link link-text"
            >
              Process Invoices
            </span>
          ) : (
            <span className="button__menu__link button--dropdown--non-clickable">
              No invoices ready to process
            </span>
          )}
        </li>

        {showCancelInvoices(order) && (
          <li>
            <span
              onClick={this.props.toggleReversePopup}
              className="button__menu__link link-text"
            >
              Reverse Invoices
            </span>
          </li>
        )}
        {allowSwitchManualAuto(order) && (
          <li>
            <AutoInvoicingUpdater
              order={order}
              render={({ updateAutoInvoicing }) => (
                <span
                  className="button__menu__link link-text"
                  onClick={() =>
                    updateAutoInvoicing(order, !order.useAutoInvoicing)
                  }
                >
                  {order.useAutoInvoicing
                    ? "Switch to Manual"
                    : "Use automated invoicing"}
                </span>
              )}
            />
          </li>
        )}
      </ul>
    );
  }
}

MenuItems.propTypes = {
  order: OrderObject,
  toggleProcessPopup: PropTypes.func.isRequired,
  toggleReversePopup: PropTypes.func.isRequired
};

export default MenuItems;
