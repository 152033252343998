import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../../Queries/OrderQuery";
import PartnerSender from "./PartnerSender";

import RelatedOrderContext from "./../../RelatedOrderTabs/RelatedOrderContext";
import { canSendToPartner } from "../../BusRules/orders";

class SendToPartner extends React.Component {
  static contextType = RelatedOrderContext.Consumer;
  render() {
    const { order, uiActive } = this.props;

    return (
      <PartnerSender
        order={order}
        render={({ sendToPartner }) => (
          <button
            className="button primary"
            disabled={!uiActive || !canSendToPartner(order)}
            onClick={() => {
              if (window.confirm("Send to partner?")) {
                sendToPartner();
                this.context.gotoNextAssignedOrder();
              }
            }}
          >
            Send to Partner ({order.partner.name})
          </button>
        )}
      />
    );
  }
}

SendToPartner.propTypes = {
  order: OrderObject.isRequired,
  uiActive: PropTypes.bool.isRequired
};

export default SendToPartner;
