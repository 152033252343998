import React from "react";
import PropTypes from "prop-types";

import EditDropdown from "Form/EditDropdown";
import EditYesNo from "Form/EditYesNo";

import { isErapOrder } from "../../BusRules/access";
import { CurrentUserObject, OrderObject } from "./../../Queries/OrderQuery";

class AddItemsForm extends React.Component {
  state = {
    document: {
      categoryName: this.props.order.documentCategoryOptions[0],
      syncWithEpi: false
    },
    showSyncField: true,
    reader: null,
    image: null,
    saving: false
  };
  render() {
    const { currentUser, order } = this.props;
    const { document, showSyncField } = this.state;
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <fieldset className="fieldset">
            <EditDropdown
              name="categoryName"
              onChange={this.handleCategoryChange}
              value={document.categoryName}
              labelText="Category"
              stackedLabel={true}
              options={order.documentCategoryOptions}
            />
            {showSyncField &&
              !isErapOrder(order) &&
              order.uiState.currentUserIsHeadOffice && (
                <EditYesNo
                  name="syncWithEpi"
                  onChange={this.handleChange}
                  value={document.syncWithEpi}
                  labelText="Show to therapist on portal & email"
                  stackedLabel={true}
                />
              )}
            <div className="field field--stacked">
              <label htmlFor="document_file">Upload New File</label>
              <input
                onChange={this.setImage}
                type="file"
                accept={this.props.order.documentAllowedMimeTypes}
              />
            </div>
          </fieldset>

          <div className="fieldset__actions">
            <input
              onClick={this.save}
              type="submit"
              name="commit"
              value="Upload Document"
              className="button primary"
              disabled={!this.state.image || this.state.saving}
            />
          </div>
          {this.state.saving && (
            <div className="order-document-saving">
              <div className="spinner-container">
                <span>Uploading, please wait</span>
                <div className="loading-spinner" />
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }

  onSubmit = e => {
    const { document } = this.state;
    e.preventDefault();
    this.setState({ saving: true });

    this.props.addDocumentToOrder({
      categoryName: document.categoryName,
      fileBase64: this.state.reader.result,
      fileName: this.state.image.name,
      fileType: this.state.image.type,
      syncWithEpi: document.syncWithEpi
    });
  };

  setImage = e => {
    const val = e.target.files[0];
    if (val == null) {
      this.setState({ image: null, reader: null });
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onloadend = () => this.setState({ image: val, reader: reader });
    }
  };

  handleCategoryChange = (field, value) => {
    let document = { ...this.state.document, [field]: value };
    let showSyncField;
    if (this.props.order.documentCategoriesSyncedWithEpi.includes(value)) {
      showSyncField = true;
    } else {
      document.syncWithEpi = false;
      showSyncField = false;
    }
    this.setState({ document, showSyncField: showSyncField });
  };

  handleChange = (field, value) => {
    const document = { ...this.state.document, [field]: value };
    this.setState({ document });
  };
}

AddItemsForm.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  order: OrderObject.isRequired,
  addDocumentToOrder: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired
};

export default AddItemsForm;
