import dateFuncs from "../../../lib/Form/dateFuncs";
import { isNEDOrder } from "../../BusRules/access";
import {
  isHireOrRTOOrder,
  isRTOOrder,
  showAssetColumn
} from "../../BusRules/orders";
import { getHireEndOnColumn } from "./getHireEndOnColumn";
import {
  lineStatuses,
  minDeliveredOnDateAllowed
} from "../../BusRules/orderLines";
import StatusEditor from "./StatusEditor";
import { OrderObject } from "../../Queries/OrderQuery";
import PropTypes from "prop-types";
import StatusDependentFieldEditor from "./StatusDependentFieldEditor";

const getFulfillmentColumns = ({
  order,
  isEditing,
  editedLineStatus,
  setEditedLineStatus
}) => {
  let cols = [];
  let fulfillmentCols = [
    {
      headerName: "Status",
      field: "status",
      width: isEditing ? 115 : 85,
      editable: params =>
        order.uiState.editable &&
        !order.complexHomeMods &&
        lineStatuses.EDIT_STATUS_ON.includes(params.data.status),
      cellEditor: StatusEditor,
      cellEditorParams: {
        setEditedLineStatus: setEditedLineStatus
      }
    },
    {
      headerName: "ETA",
      field: "etaOn",
      width: isEditing ? 115 : 85,
      valueFormatter: params =>
        params.value ? dateFuncs.displayDateYYYY(params.value) : "",
      editable: true,
      cellEditor: StatusDependentFieldEditor,
      cellEditorParams: {
        order: order,
        type: "date",
        editableStatuses: lineStatuses.EDIT_ETA_ON,
        editedLineStatus: editedLineStatus,
        limit: "TodayForward"
      }
    },
    {
      headerName: "Prescriber Notified?",
      headerTooltip: "Prescriber Notified?",
      field: "providerNotified",
      width: isEditing ? 60 : 50,
      editable: true,
      cellEditor: StatusDependentFieldEditor,
      cellEditorParams: {
        order: order,
        type: "select",
        options: ["", "No", "Yes"],
        editableStatuses: ["On backorder"],
        editedLineStatus: editedLineStatus
      }
    },
    {
      headerName: "Delivered",
      field: "deliveredOn",
      width: isEditing ? 115 : 85,
      valueFormatter: params =>
        params.value ? dateFuncs.displayDateYYYY(params.value) : "",
      editable: true,
      cellEditor: StatusDependentFieldEditor,
      cellEditorParams: params => {
        const editableStatuses = lineStatuses.EDIT_DELIVERED_ON.includes(
          params.data.status
        )
          ? []
          : lineStatuses.EDIT_DELIVERED_ON;
        return {
          order: order,
          type: "date",
          editableStatuses: editableStatuses,
          editedLineStatus: editedLineStatus,
          limits: { startDate: minDeliveredOnDateAllowed(order) },
          limit: "TodayBack"
        };
      }
    }
  ];
  if (isNEDOrder(order)) {
    fulfillmentCols.push({
      headerName: "Ship Invoice No.",
      field: "shipmentInvoiceNumber",
      width: 110
    });
  }
  if (isRTOOrder(order)) {
    fulfillmentCols.push(
      {
        headerName: "Weeks to own",
        headerTooltip: "Weeks to own",
        field: "weeksToOwn",
        width: 55
      },
      {
        headerName: "Owned By",
        field: "ownedByOn",
        valueFormatter: params =>
          params.value ? dateFuncs.displayDateYYYY(params.value) : "",
        width: 85
      }
    );
  }

  if (isHireOrRTOOrder(order)) {
    fulfillmentCols.push({
      headerName: "Picked up",
      field: "pickedUpOn",
      valueFormatter: params =>
        params.value ? dateFuncs.displayDateYYYY(params.value) : "",
      width: isEditing ? 115 : 85,
      editable: true,
      cellEditor: StatusDependentFieldEditor,
      cellEditorParams: params => {
        return {
          order: order,
          type: "date",
          editableStatuses: lineStatuses.EDIT_PICKED_UP_ON,
          editedLineStatus: editedLineStatus,
          limits: { startDate: dateFuncs.toYYYYMMDD(params.data.deliveredOn) },
          limit: "TodayBack"
        };
      }
    });
    fulfillmentCols.push(
      ...getHireEndOnColumn({ order, includeHeader: false, editedLineStatus })
    );
  }
  if (showAssetColumn(order)) {
    fulfillmentCols.push({
      headerName: "Asset ID/Serial #",
      field: "assetDetails",
      width: 85,
      cellEditor: "agTextCellEditor",
      editable: params =>
        order.uiState.editable &&
        lineStatuses.EDIT_ASSET_DETAILS_ON.includes(params.data.status)
    });
  }

  cols.push({
    headerName: "FULFILLMENT",
    children: fulfillmentCols
  });

  return cols;
};

getFulfillmentColumns.propTypes = {
  order: OrderObject.isRequired,
  editingRow: PropTypes.bool,
  editedLineStatus: PropTypes.string,
  setEditedLineStatus: PropTypes.func.isRequired
};

export { getFulfillmentColumns };
