import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";

import { OrderObject } from "./../../Queries/OrderQuery";
import SplitUpdater from "./SplitUpdater";

const SPECIAL_ORDER_HOME_MOD = "home_mod";
const SPECIAL_ORDER_REPAIR = "repair";
const SPECIAL_ORDER_STANDARD = "no";

class SplitLinesToNewOrder extends React.Component {
  state = { isOpen: false };

  render() {
    const {
      selections,
      repairLinesSelected,
      homeModLinesSelected,
      selectedRows,
      order,
      clearSelections
    } = this.props;

    const enabled =
      selections.length > 0 && !(repairLinesSelected && homeModLinesSelected);

    return (
      <React.Fragment>
        <SplitUpdater
          order={order}
          render={({ splitSelections }) => {
            const assign = () => {
              let specialOrder = SPECIAL_ORDER_STANDARD;
              if (repairLinesSelected) {
                specialOrder = SPECIAL_ORDER_REPAIR;
              } else if (homeModLinesSelected) {
                specialOrder = SPECIAL_ORDER_HOME_MOD;
              }
              splitSelections(specialOrder, selections);
              clearSelections();
            };
            return (
              <React.Fragment>
                <button
                  className="button primary"
                  onClick={this.toggleModal}
                  disabled={!enabled}
                >
                  Split to New Order
                </button>

                {this.state.isOpen && (
                  <Modal
                    show={this.state.isOpen}
                    onClose={this.toggleModal}
                    header={"Split Order"}
                    renderFooter={() => this.renderFooter(assign)}
                  >
                    <div className="margin-bottom-base assign-alternative-partner">
                      <ul className="list list--compact list--bullet">
                        {selectedRows.map(s => (
                          <li className="list__item" key={s.id}>
                            {s.sku} - {s.description}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <p className="margin-none red">
                      The split option is to be used carefully and only for
                      those scenarios where you need to validate part of the
                      order separate to other parts. Otherwise, do not use the
                      split function and use the assignment to split and send
                      orders to different NPs/Branches. Examples where you
                      should use the split functionality: 2nd supply of recliner
                      needs approval - other items need to be delivered; Buy
                      items and hose reel going to same partner and need to be
                      on separate orders
                    </p>
                  </Modal>
                )}
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }
  isSpecialOrder = () =>
    this.props.repairLinesSelected || this.props.homeModLinesSelected;

  renderFooter = split =>
    this.isSpecialOrder() ? (
      <div className="assign-alternative-partner-footer">
        {(this.props.repairLinesSelected ||
          this.props.homeModLinesSelected) && (
          <div className="message message--error" role="alert">
            <p>
              You are about to create a
              {this.props.repairLinesSelected ? " Repair " : " Home Mods "}
              Order. Are you sure?
            </p>
          </div>
        )}
        {this.splitButton(split)}
      </div>
    ) : (
      this.splitButton(split)
    );

  splitButton = split => {
    return (
      <button
        className="button primary"
        onClick={() => {
          split();
          this.toggleModal();
        }}
      >
        Split to New Order
      </button>
    );
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
}

SplitLinesToNewOrder.propTypes = {
  order: OrderObject.isRequired,
  selections: PropTypes.array.isRequired,
  repairLinesSelected: PropTypes.bool.isRequired,
  homeModLinesSelected: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  clearSelections: PropTypes.func.isRequired
};

export default SplitLinesToNewOrder;
