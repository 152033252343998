import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";

import InvoiceableLinesQuery from "./InvoiceableLinesQuery";
import ProcessInvoicePopup from "./ProcessInvoicePopup";
import ReverseInvoicePopup from "./ReverseInvoicePopup";
import MenuItems from "./MenuItems";

class InvoicingButton extends React.Component {
  state = { isOpen: false, processPopupOpen: false, reversePopupOpen: false };

  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <a
          onClick={this.toggleOpen}
          className={
            "button button--dropdown" + (this.state.isOpen ? " is-open" : "")
          }
        >
          Invoicing
          {this.state.isOpen && (
            <MenuItems
              order={order}
              toggleProcessPopup={this.toggleProcessPopup}
              toggleReversePopup={this.toggleReversePopup}
            />
          )}
        </a>

        {this.state.processPopupOpen && (
          <InvoiceableLinesQuery
            reverseable={false}
            orderId={order.id}
            render={({ invoiceLines }) => (
              <ProcessInvoicePopup
                invoiceLines={invoiceLines}
                order={order}
                close={this.toggleProcessPopup}
              />
            )}
          />
        )}
        {this.state.reversePopupOpen && (
          <InvoiceableLinesQuery
            reverseable={true}
            orderId={order.id}
            render={({ invoiceLines }) => (
              <ReverseInvoicePopup
                invoiceLines={invoiceLines}
                order={order}
                close={this.toggleReversePopup}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleProcessPopup = () =>
    this.setState({ processPopupOpen: !this.state.processPopupOpen });
  toggleReversePopup = () =>
    this.setState({ reversePopupOpen: !this.state.reversePopupOpen });
}

InvoicingButton.propTypes = {
  order: OrderObject
};

export default InvoicingButton;
