import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";

const ContractsApp = props => {
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        render={({ contractCodes }) => (
          <section className="contracts_section">
            <Table contractCodes={contractCodes} />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default ContractsApp;
