import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "../Queries/OrderQuery";

class SendToGpBtn extends React.Component {
  state = { syncTriggered: false };

  render() {
    return (
      <React.Fragment>
        <button
          onClick={this.resendToGp}
          className="button button--white"
          disabled={this.state.syncTriggered}
        >
          {this.props.btnText}
        </button>
      </React.Fragment>
    );
  }

  resendToGp = () => {
    const { update, order } = this.props;
    this.setState({ syncTriggered: true });
    update({
      variables: {
        id: order.id
      }
    });
  };
}

SendToGpBtn.propTypes = {
  order: OrderObject.isRequired,
  btnText: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired
};

export default SendToGpBtn;
