import React from "react";
import PropTypes from "prop-types";

import { HealthcareProviderObject, OrderObject } from "../Queries/OrderQuery";

import EditTextField from "Form/EditTextField";
import EditTextArea from "Form/EditTextArea";
import EditDropdown from "Form/EditDropdown";
import EditAddressFields from "Form/EditAddressFields";
import ErrorDisplay from "Form/ErrorDisplay";
import {
  allowPrivilegedFields,
  isNonErapDvaOrder,
  isOnDvaContract
} from "../BusRules/access";
import EmailField from "./EmailField";

const prescriberNotesMaxLength = 1929;

class HcpForm extends React.Component {
  state = { hcp: null };

  static getDerivedStateFromProps(props, state) {
    // set local copy of hcp for editing
    if (state.hcp == null) {
      return {
        hcp: props.hcp
      };
    }
    return null;
  }

  render() {
    const { hcp } = this.state;
    const { order, contractId, currentUser, errors } = this.props;
    return (
      <form onSubmit={this.onSubmit} className={"form"}>
        <ErrorDisplay errors={errors} modelDesc="client" />
        <div className={"display-flex"}>
          <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
            <EditTextField
              name="firstName"
              onChange={this.handleChange}
              value={hcp.firstName}
              labelText="First Name"
              errors={errors}
            />
            <EditTextField
              name="lastName"
              onChange={this.handleChange}
              value={hcp.lastName}
              labelText="Last Name"
              errors={errors}
            />
            <EditTextField
              name="ahpraNumber"
              onChange={this.handleChange}
              value={hcp.ahpraNumber}
              labelText="AHPRA Number"
              errors={errors}
            />
            <EditTextField
              name="groupName"
              onChange={this.handleChange}
              value={hcp.groupName}
              labelText="Group Name"
              errors={errors}
            />
            <EditTextField
              name="medicareProviderNumber"
              onChange={this.handleChange}
              value={hcp.medicareProviderNumber}
              labelText="medicareProviderNumber"
              errors={errors}
            />
            {isOnDvaContract(hcp) && (
              <EditDropdown
                name="providerType"
                onChange={this.handleChange}
                value={hcp.providerType}
                labelText="Provider Type"
                options={hcp.providerTypeOptions}
                errors={errors}
              />
            )}
            {!isOnDvaContract(hcp) && (
              <EditTextField
                name="providerType"
                onChange={this.handleChange}
                value={hcp.providerType}
                labelText="Provider Type"
                errors={errors}
              />
            )}
          </fieldset>
          <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
            <EditAddressFields
              model={hcp}
              onChange={this.handleChange}
              errors={errors}
            />
            <EditTextField
              name="phoneNumber"
              type="tel"
              onChange={this.handleChange}
              value={hcp.phoneNumber}
              labelText="Phone Number"
              errors={errors}
            />
            <EditTextField
              name="mobileNumber"
              type="tel"
              onChange={this.handleChange}
              value={hcp.mobileNumber}
              labelText="Mobile Number"
              errors={errors}
            />
            <EditTextField
              name="faxNumber"
              type="tel"
              onChange={this.handleChange}
              value={hcp.faxNumber}
              labelText="Fax Number"
              errors={errors}
            />
            <EmailField
              hcp={hcp}
              contractId={contractId}
              handleChange={this.handleChange}
              errors={errors}
            />
            <EditTextField
              name="preferredContactMethod"
              onChange={this.handleChange}
              value={hcp.preferredContactMethod}
              labelText="Preferred Contact Method"
              errors={errors}
            />
            <EditTextArea
              name="prescriberNotes"
              onChange={this.handleChange}
              value={hcp.prescriberNotes}
              labelText="Healthcare Provider Note"
              maxLength={prescriberNotesMaxLength}
              errors={errors}
            />
            {allowPrivilegedFields(order) && isNonErapDvaOrder(order) && (
              <EditTextArea
                name="cjInstructions"
                onChange={this.handleChange}
                value={hcp.cjInstructions}
                labelText="Clinical Justification Instructions"
                errors={errors}
              />
            )}
          </fieldset>
        </div>
        <div className={"form__actions"}>
          <input
            onClick={this.save}
            type="submit"
            name="commit"
            value="Update Healthcare provider"
            className="button primary"
          />
        </div>
      </form>
    );
  }

  onSubmit = e => {
    const { hcp } = this.state;
    e.preventDefault();
    this.props.update({
      variables: {
        id: hcp.id,
        input: {
          firstName: hcp.firstName,
          lastName: hcp.lastName,
          groupName: hcp.groupName,
          medicareProviderNumber: hcp.medicareProviderNumber,
          providerType: hcp.providerType,
          phoneNumber: hcp.phoneNumber,
          mobileNumber: hcp.mobileNumber,
          faxNumber: hcp.faxNumber,
          preferredContactMethod: hcp.preferredContactMethod,
          email: hcp.email,
          address: hcp.address,
          city: hcp.city,
          postcode: hcp.postcode,
          state: hcp.state,
          ahpraNumber: hcp.ahpraNumber,
          prescriberNotes: hcp.prescriberNotes,
          cjInstructions: hcp.cjInstructions
        }
      }
    });
  };

  handleChange = (field, value) => {
    // Remove non-digits to allow cut & paste
    if (["phoneNumber", "mobileNumber", "faxNumber"].includes(field)) {
      value = value.replace(/[^\d]/g, "");
    }
    const hcp = { ...this.state.hcp, [field]: value };
    this.setState({ hcp });
  };
}

HcpForm.propTypes = {
  hcp: HealthcareProviderObject,
  order: OrderObject.isRequired,
  currentUser: PropTypes.object.isRequired,
  contractId: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default HcpForm;
