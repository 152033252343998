const setSearchParam = (searchParam, value) => {
  const searchParams = new URLSearchParams(window.location.search);
  const basePath = window.location.pathname;
  searchParams.set(searchParam, value);
  const newPath = basePath + "?" + searchParams.toString();

  window.history.replaceState(null, "", newPath);
};

const searchParams = new URLSearchParams(window.location.search);

const getSearchParam = param => searchParams.get(param);

const contractIdFromPath = () => {
  const matchPath = window.location.pathname.match(/^\/contracts\/\d+/);
  if (matchPath) {
    return matchPath[0].match(/\d+/)[0];
  } else {
    return null;
  }
};

const contractUserIdFromPath = () => {
  const matchPath = window.location.pathname.match(
    /^\/user_accounts\/\d+\/contract_users\/\d+/
  );
  let contractUserId = null;
  if (matchPath) {
    contractUserId = matchPath[0].match(/\d+$/)[0];
  }
  return contractUserId;
};

export {
  setSearchParam,
  searchParams,
  getSearchParam,
  contractIdFromPath,
  contractUserIdFromPath
};
