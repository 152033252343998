import { isNonErapHomeMods } from "../../BusRules/access";
import { OrderObject } from "../../Queries/OrderQuery";

const getPartnerColumns = ({ order }) => {
  let cols = [];

  if (
    order.uiState &&
    order.uiState.stageAssignment &&
    !isNonErapHomeMods(order)
  ) {
    cols.push(
      {
        headerName: "Preferred Network Partner",
        field: "partner.name",
        width: 200,
        valueGetter: params => {
          if (params.data.isTotals) {
            return "";
          }
          return (order.partner || { name: "none" }).name;
        }
      },
      {
        headerName: "Partner Services",
        field: "partner.serviceDesc",
        width: 200,
        valueGetter: params => {
          if (params.data.isTotals || !order.partner) {
            return "";
          }
          return order.partner.serviceDesc;
        }
      }
    );
  }

  return cols;
};

getPartnerColumns.propTypes = {
  order: OrderObject.isRequired
};

export { getPartnerColumns };
