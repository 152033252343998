const formatPhone = val => {
  if (val) {
    if (val.match(/^04[0-9]{8}$/)) {
      return val.slice(0, 4) + " " + val.slice(4, 7) + " " + val.slice(7, 10);
    } else if (val.match(/^[0-9]{10}$/)) {
      return val.slice(0, 2) + " " + val.slice(2, 6) + " " + val.slice(6, 10);
    }
  }
  return val;
};

const isValidMobile = val => {
  return val && val.match(/^04[0-9]{8}$/);
};

export { formatPhone, isValidMobile };
