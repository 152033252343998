import dateFuncs from "../../lib/Form/dateFuncs";
// "Select" - covers the case where it's changed back to Select

const lineStatuses = {
  CANCELLED: "Cancelled",
  CANCELLED_LIST: ["Cancelled"],
  DELETED_LIST: ["Cancelled", "Removed"],
  SELECTABLE_LIST: ["New", "Available", "On backorder"],
  EDIT_ASSET_DETAILS_ON: [
    "Select",
    "New",
    "Available",
    "On backorder",
    "On Hire",
    "Pickup Request",
    "Returned"
  ],
  EDIT_STATUS_ON: [
    "Select",
    "New",
    "Available",
    "On backorder",
    "Delivered",
    "On Hire",
    "Pickup Request",
    "Returned"
  ],
  EDIT_ETA_ON: ["On backorder"],
  EDIT_DELIVERED_ON: ["Delivered", "On Hire"],
  EDIT_HIRE_END_ON: ["Delivered", "On Hire", "Pickup Request", "Returned"],
  EDIT_PICKED_UP_ON: ["Returned"],
  EDIT_QUANTITY: ["Select", "New", "Available", "On backorder"],
  START_EDIT_BUY_LIST: ["New", "Available", "On backorder", "Delivered"],
  START_EDIT_HIRE_LIST: [
    "New",
    "Available",
    "On backorder",
    "On Hire",
    "Pickup Request",
    "Returned"
  ]
};

const allowExpanded = line => {
  return (
    (["HIRE", "RTO"].includes(line.saleType) && line.invoiceLines.length > 0) ||
    line.invoiceLines.length > 1
  );
};

const allowEditLine = line => {
  if (line.isHomeMod || line.isRepair) {
    return false;
  } else if (isBuyOrFreight(line)) {
    return (
      lineStatuses.START_EDIT_BUY_LIST.includes(line.status) &&
      line.fulfillmentStatusOptions.length > 1
    );
  } else {
    return (
      lineStatuses.START_EDIT_HIRE_LIST.includes(line.status) &&
      line.fulfillmentStatusOptions.length > 1
    );
  }
};

const allowEditLineInOrder = (order, line) =>
  order.uiState.editable && order.uiState.canEditLines && allowEditLine(line);

const anyLinesExpandable = order =>
  order.orderLines.some(line => allowExpanded(line));

const hideIsClientEligible = line =>
  lineStatuses.DELETED_LIST.includes(line.status);

const validDeliveredOn = (order, deliveredOn) => {
  const today = dateFuncs.toYYYYMMDD(new Date());
  return (
    deliveredOn >= minDeliveredOnDateAllowed(order) && deliveredOn <= today
  );
};

const minDeliveredOnDateAllowed = order => {
  const sortedDeliveredOnDates = order.orderLines
    .map(line => line.deliveredOn)
    .filter(Boolean)
    .sort();
  let minDate = order.dateReceived;
  if (sortedDeliveredOnDates[0]) {
    minDate = sortedDeliveredOnDates[0];
  }
  return dateFuncs.toYYYYMMDD(minDate);
};

export {
  allowEditLine,
  allowEditLineInOrder,
  lineStatuses,
  allowExpanded,
  anyLinesExpandable,
  hideIsClientEligible,
  validDeliveredOn,
  minDeliveredOnDateAllowed
};

const isBuy = line => {
  return line.saleType === "BUY";
}

const isBuyOrFreight = line => {
  return line.saleType === "BUY" || line.saleType === "FREIGHT";
}
