import React from "react";

import RelatedOrderContext from "./../RelatedOrderTabs/RelatedOrderContext";

class StartSendingOrdersButton extends React.Component {
  static contextType = RelatedOrderContext.Consumer;
  render() {
    return (
      <div className="detail_section detail_section__padded">
        <span className="cj-provided-text margin-right-large">
          All lines have been assigned
        </span>
        <button
          onClick={() => this.context.gotoNextAssignedOrder()}
          className="button button--green"
        >
          Start sending orders
        </button>
      </div>
    );
  }
}

StartSendingOrdersButton.propTypes = {};
export default StartSendingOrdersButton;
