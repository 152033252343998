import React from "react";
import PropTypes from "prop-types";

const RoFieldWrapper = ({ labelText, children }) => (
  <div className="field">
    <label htmlFor={labelText}>{labelText}</label>
    <div>{children}</div>
  </div>
);

RoFieldWrapper.propTypes = {
  labelText: PropTypes.string.isRequired
};

export default RoFieldWrapper;
