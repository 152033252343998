import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../../lib/AgGrid/GridPropsQuery";

const ContractHOUsersApp = () => (
  <GraphqlWrapper>
    <GridPropsQuery
      render={({ contractCodes, currentUser }) => (
        <section className="contract_ho_users_section">
          <Table contractCodes={contractCodes} currentUser={currentUser} />
        </section>
      )}
    />
  </GraphqlWrapper>
);

export default ContractHOUsersApp;
