import React from "react";

import { FacilityObject, OrderObject } from "./../Queries/OrderQuery";
import RoMemo from "Form/RoField";
import { isAgedCareOrder, isHospitalWardOrder } from "../BusRules/orders";
import FacilityViewFields from "./FacilityViewFields";
import RoField from "../../lib/Form/RoField";

const FacilityView = ({ facility, order }) => {
  return (
    <div className="display-flex">
      <fieldset className={"fieldset fieldset--compact"}>
        <FacilityViewFields facility={facility} />
        {!order.includesRepair && (
          <RoMemo
            value={order.deliveryInstructions}
            labelText="Delivery Instructions"
          />
        )}
        {isAgedCareOrder(order) && order.manualOrder && (
          <React.Fragment>
            <RoField
              labelText="Requester First Name"
              value={order.requesterFirstName}
            />
            <RoField
              labelText="Requester Last Name"
              value={order.requesterLastName}
            />
          </React.Fragment>
        )}
        {isHospitalWardOrder(order) && (
          <React.Fragment>
            <RoField labelText="Client Name" value={order.wardClientName} />
            <RoField
              labelText="Client Reference"
              value={order.wardClientReference}
            />
            <RoField labelText="Client Room" value={order.wardClientRoom} />
          </React.Fragment>
        )}
      </fieldset>
    </div>
  );
};

FacilityView.propTypes = {
  facility: FacilityObject.isRequired,
  order: OrderObject.isRequired
};

export default FacilityView;
