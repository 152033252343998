import React from "react";
import PropTypes from "prop-types";

class Tab extends React.Component {
  render() {
    const {
      tabId,
      tabgroup,
      label,
      styleClass,
      tabBodyClass,
      children,
      disabled,
      count
    } = this.props;

    if (!tabgroup) {
      return null;
    }
    const internalId = `${tabgroup.name}-${tabId}`;
    const displayCount = count && count > 0 ? count : null;

    return (
      <React.Fragment key={tabId}>
        <input
          className={"tab tab--hidden"}
          type="radio"
          name={`tab-${tabgroup.name}`}
          id={internalId}
          value={tabId}
          checked={tabId === tabgroup.selectedTabId}
          disabled={disabled}
          onChange={e => tabgroup.selectTab(e.target.value)}
        />
        <label
          className={`tab ${styleClass ? " tab--small" : ""} ${
            disabled ? " tab--disabled" : ""
          }`}
          htmlFor={internalId}
        >
          {label}
          {displayCount && <div className="tabs-counter">{displayCount}</div>}
        </label>
        <div className={`panel__body ${tabBodyClass || ""}`}>{children}</div>
      </React.Fragment>
    );
  }
}

Tab.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabgroup: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
  tabBodyClass: PropTypes.string,
  count: PropTypes.number
};

export default Tab;
