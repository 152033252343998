import React from "react";
import PropTypes from "prop-types";

import SeekApprovalEnterNumber from "./SeekApprovalEnterNumber";

import { TickCompleted } from "./TickHelpers";

const priorApprovalDownload = "/documents/AIDACARE_DVA PRIOR APPROVAL FORM.pdf";

class SeekApproval extends React.Component {
  state = { enterApproval: false };
  render() {
    const { order, approval } = this.props;
    const { enterApproval } = this.state;

    const spa = order[approval];
    if (!order.uiState.showSeekApproval) {
      return null;
    }
    return (
      <section className="detail_section detail_section__padded ">
        <div className="add-item-action seek-prior-approval">
          {spa == null && (
            <React.Fragment>
              <p>Seek prior approval?</p>
              <div className="button-group">
                <button
                  onClick={this.handleYes}
                  type="button"
                  className="button primary"
                >
                  Yes
                </button>
                <button
                  onClick={this.handleNo}
                  type="button"
                  className="button red"
                >
                  No
                </button>
              </div>
            </React.Fragment>
          )}
          {spa === true && enterApproval && (
            <SeekApprovalEnterNumber
              order={order}
              updateOrder={this.props.updateOrder}
              toggleUpdateApproval={this.toggleUpdateApproval}
            />
          )}
          {spa === true && !enterApproval && (
            <div className="seek-prior-approval__true">
              {!order.priorApprovalNumber ? (
                <React.Fragment>
                  <div>
                    <p className="display-inline-block margin-right-base">
                      Please submit the order to DVA for Prior Approval{" "}
                    </p>
                    <a
                      className="margin-right-large"
                      href={priorApprovalDownload}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Download)
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <TickCompleted />
                  Prior Approval Number: {order.priorApprovalNumber} entered.
                </div>
              )}
              <a
                className="button button--white"
                onClick={this.toggleUpdateApproval}
              >
                update prior approved
              </a>
              <a className="button button--cancel" onClick={this.resetVal}>
                cancel
              </a>
            </div>
          )}
          {spa === false && (
            <div>
              <TickCompleted />
              <p className="display-inline-block margin-right-base">
                {this.completeMsg()}
              </p>
              {this.change("change")}
            </div>
          )}
        </div>
      </section>
    );
  }

  change = text => (
    <button
      className="button button--white margin-left-base"
      onClick={this.resetVal}
    >
      {text}
    </button>
  );

  toggleUpdateApproval = () =>
    this.setState(prev => {
      return { ...prev, enterApproval: !prev.enterApproval };
    });

  saveOrder = val => {
    this.props.updateOrder({
      id: this.props.order.id,
      [this.props.approval]: val
    });
  };

  handleYes = e => this.saveOrder(true);

  handleNo = e => this.saveOrder(false);

  resetVal = () => this.saveOrder(null);

  completeMsg = () =>
    this.props.approval === "seekPriorApproval"
      ? "Please proceed to assignment"
      : "Please confirm the order for the partner";
}

SeekApproval.propTypes = {
  order: PropTypes.object,
  updateOrder: PropTypes.func.isRequired,
  approval: PropTypes.oneOf(["seekPriorApproval", "partnerRequestedApproval"])
};

export default SeekApproval;
