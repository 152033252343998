import dateFuncs from "../../../lib/Form/dateFuncs";
import { OrderObject } from "../../Queries/OrderQuery";
import PropTypes from "prop-types";
import StatusDependentFieldEditor from "./StatusDependentFieldEditor";
import { lineStatuses } from "../../BusRules/orderLines";

const getHireEndOnColumn = ({ order, includeHeader, editedLineStatus }) => {
  const cols = [
    {
      headerName: "Hire end",
      field: "hireEndOn",
      valueFormatter: params =>
        params.value ? dateFuncs.displayDateYYYY(params.value) : "",
      width: 95,
      editable: params =>
        order.uiState.canEditHireEnd && params.data.saleType !== "FREIGHT",
      cellEditor: StatusDependentFieldEditor,
      cellEditorParams: params => {
        return {
          order: order,
          type: "date",
          editableStatuses: lineStatuses.EDIT_HIRE_END_ON,
          editedLineStatus: editedLineStatus,
          limits: { startDate: dateFuncs.toYYYYMMDD(params.data.deliveredOn) },
        };
      }
    }
  ];

  if (includeHeader) {
    return [
      {
        headerName: "FULFILLMENT",
        children: cols
      }
    ];
  }
  return cols;
};

getHireEndOnColumn.propTypes = {
  order: OrderObject.isRequired,
  includeHeader: PropTypes.bool
};

export { getHireEndOnColumn };
