import React from "react";
import PropTypes from "prop-types";

import { HealthcareProviderObject, OrderObject } from "../Queries/OrderQuery";
import RoField from "Form/RoField";
import RoMemo from "Form/RoMemo";

import EditTextField from "Form/EditTextField";
import ErrorDisplay from "Form/ErrorDisplay";
import EmailField from "./EmailField";
import { allowPrivilegedFields, isNonErapDvaOrder } from "../BusRules/access";

class HcpLimitedForm extends React.Component {
  state = { hcp: null };

  static getDerivedStateFromProps(props, state) {
    // set local copy of hcp for editing
    if (state.hcp == null) {
      return {
        hcp: props.hcp
      };
    }
    return null;
  }

  render() {
    const { hcp } = this.state;
    const { order, errors, contractId, currentUser } = this.props;
    return (
      <form onSubmit={this.onSubmit} className={"form"}>
        <ErrorDisplay errors={errors} modelDesc="client" />
        <div className={"display-flex"}>
          <fieldset className={"fieldset fieldset--compact"}>
            <RoField value={hcp.firstName} labelText="First Name" />
            <RoField value={hcp.lastName} labelText="Last Name" />
            <RoField value={hcp.ahpraNumber} labelText="AHPRA Number" />
            <RoField value={hcp.groupName} labelText="Group Name" />
            <RoField
              value={hcp.medicareProviderNumber}
              labelText="Medicare Provider Number"
            />
            <RoField value={hcp.providerType} labelText="Provider Type" />
          </fieldset>
          <fieldset className={"fieldset fieldset--compact"}>
            <RoField value={hcp.address} labelText="Address" />
            <RoField value={hcp.city} labelText="" />
            <RoField value={hcp.postcode} labelText="" />
            <RoField value={hcp.state} labelText="" />
            <EditTextField
              name="phoneNumber"
              type="tel"
              onChange={this.handleChange}
              value={hcp.phoneNumber}
              labelText="Phone"
              errors={errors}
            />
            <EditTextField
              name="mobileNumber"
              type="tel"
              onChange={this.handleChange}
              value={hcp.mobileNumber}
              labelText="Mobile"
              errors={errors}
            />
            <RoField value={hcp.faxNumber} labelText="Fax" />
            <EmailField
              hcp={hcp}
              handleChange={this.handleChange}
              contractId={contractId}
              errors={errors}
            />
            <RoField
              value={hcp.preferredContactMethod}
              labelText="Preferred Contact Method"
            />
            <RoMemo
              value={hcp.prescriberNotes}
              labelText="Healthcare Provider Note"
            />
            {allowPrivilegedFields(order) && isNonErapDvaOrder(order) && (
              <RoField
                value={hcp.cjInstructions}
                labelText="Clinical Justification Instructions"
              />
            )}
          </fieldset>
        </div>
        <div className={"form__actions"}>
          <input
            onClick={this.save}
            type="submit"
            name="commit"
            value="Update Healthcare provider"
            className="button primary"
          />
        </div>
      </form>
    );
  }

  onSubmit = e => {
    const { hcp } = this.state;
    e.preventDefault();
    this.props.update({
      variables: {
        id: hcp.id,
        input: {
          phoneNumber: hcp.phoneNumber,
          mobileNumber: hcp.mobileNumber,
          email: hcp.email
        }
      }
    });
  };

  handleChange = (field, value) => {
    // Remove non-digits to allow cut & paste
    if (["phoneNumber", "mobileNumber"].includes(field)) {
      value = value.replace(/[^\d]/g, "");
    }
    const hcp = { ...this.state.hcp, [field]: value };
    this.setState({ hcp });
  };
}

HcpLimitedForm.propTypes = {
  hcp: HealthcareProviderObject.isRequired,
  order: OrderObject.isRequired,
  currentUser: PropTypes.object.isRequired,
  contractId: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default HcpLimitedForm;
