const dateFuncs = {
  displayDateYYYY: isoString => {
    const d = new Date(isoString);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear().toString();
    return `${zeroPad(day)}/${zeroPad(month)}/${year}`;
  },
  displayDateYY: isoString => {
    const d = new Date(isoString);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d
      .getFullYear()
      .toString()
      .substr(-2);
    return `${zeroPad(day)}/${zeroPad(month)}/${year}`;
  },
  displayDateMMDDYYYY: isoString => {
    const d = new Date(isoString);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${zeroPad(month)}/${zeroPad(day)}/${year} `;
  },
  displayDateTime: isoString => {
    const d = new Date(isoString);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const hour = d.getHours();
    const min = d.getMinutes();
    return (
      `${zeroPad(day)}/${zeroPad(month)}/${year} ` +
      `${zeroPad(hour)}:${zeroPad(min)}`
    );
  },
  toYYYYMMDD: dateString => {
    let d;
    if (dateString === "today") {
      d = new Date();
    } else {
      d = new Date(dateString);
    }
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${zeroPad(month)}-${zeroPad(day)}`;
  },
  toYYYYMMDDFromString: string => {
    var dateparts = string.split("/");
    const day = dateparts[0];
    const month = dateparts[1];
    const year = dateparts[2];
    return `${year}-${zeroPad(month)}-${zeroPad(day)}`;
  }
};

const zeroPad = num => `${num < 10 ? "0" : ""}${num}`;

export default dateFuncs;
