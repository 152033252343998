import React from "react";
import Modal from "Util/Modal";
import ClientsTable from "./Table";
import ClientAdder from "./ClientAdder";
import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";

class ClientModal extends React.Component {
  state = { isOpen: false, addNewDisabled: false };

  render() {
    const { order, buttonText, currentUser } = this.props;

    const disabledButton = (
      <button
        onClick={e => this.toggleModal(e)}
        className="display_group__header__button button--disabled"
        disabled={true}
      >
        {buttonText}
      </button>
    );

    return (
      <React.Fragment>
        <section>
          {order.status === "cancelled" && disabledButton}
          {order.status !== "cancelled" && order.healthcareProvider === null && (
            <React.Fragment>
              <em className={"display_group__header__heading__small"}>
                Please select a Healthcare Provider first
              </em>
              {disabledButton}
            </React.Fragment>
          )}
          {order.status !== "cancelled" && order.healthcareProvider !== null && (
            <button
              onClick={e => this.toggleModal(e)}
              className="display_group__header__button button--back"
            >
              {buttonText}
            </button>
          )}
        </section>
        {this.state.isOpen && (
          <ClientAdder
            order={order}
            onComplete={this.closeModal}
            render={({ update }) => (
              <Modal
                show={this.state.isOpen}
                onClose={() => this.closeModal()}
                header="Add Client"
                className="clients-modal"
                renderFooter={() => (
                  <React.Fragment>
                    {order.sourceSystem !== "EPI" && (
                      <button
                        onClick={() => this.addNew(update)}
                        className="button button--white"
                        disabled={this.state.addNewDisabled}
                      >
                        Add New Client
                      </button>
                    )}
                    <a
                      onClick={() => this.closeModal()}
                      className="button button--back"
                    >
                      Back
                    </a>
                  </React.Fragment>
                )}
              >
                <ClientsTable
                  contractId={order.contract.id}
                  order={order}
                  update={update}
                  currentUser={currentUser}
                />
              </Modal>
            )}
          />
        )}
      </React.Fragment>
    );
  }

  addNew = update => {
    this.setState({ addNewDisabled: true });
    update(null);
  };

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

ClientModal.defaultProps = {
  buttonText: "Add Client"
};

ClientModal.propTypes = {
  order: OrderObject.isRequired,
  buttonText: PropTypes.string,
  currentUser: PropTypes.object
};

export default ClientModal;
