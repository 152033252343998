import React from "react";
import PropTypes from "prop-types";

import OrderUpdater from "./../../OrderUpdater";
import EditSalesRep from "./../../OrderDetails/EditSalesRep";
import SelectSalesRepsQuery from "./SelectSalesRepsQuery";

class SelectSalesRep extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <SelectSalesRepsQuery
        render={({ salesReps }) => (
          <OrderUpdater
            currentUser={this.props.currentUser}
            order={order}
            render={({ updateOrder }) => (
              <div
                className={`select-salesrep-in-allocation ${!order.salesRep &&
                  "select-salesrep-in-allocation__required"}`}
              >
                <EditSalesRep
                  onChange={(field, value) =>
                    this.handleChange(value, updateOrder)
                  }
                  value={(order.salesRep && order.salesRep.name) || null}
                  labelText="Sales Rep"
                  options={salesReps}
                />
              </div>
            )}
          />
        )}
      />
    );
  }
  // <form onSubmit={this.onSubmit} className={"form"}>

  handleChange = (saleRep, updateOrder) => {
    const order = { id: this.props.order.id, saleRep, salesRepId: saleRep.id };
    updateOrder(order);
  };
}

SelectSalesRep.propTypes = {
  order: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default SelectSalesRep;
