import { getColumnWidth } from "../../../lib/Util/getColumnWidth";
import { allowEditQuantity, isOnDvaContract } from "../../BusRules/access";
import { CurrentUserObject, OrderObject } from "../../Queries/OrderQuery";
import CommentCell from "./CommentCell";
import PropTypes from "prop-types";

const getBaseColumns = ({ order, currentUser, disableUIFunc, saveLine }) => {
  let cols = [
    {
      headerName: "Cat Num",
      headerTooltip: "Category Number",
      field: "sku",
      width: getColumnWidth(order.orderLines, "sku")
    },
    {
      headerName: "S",
      headerTooltip: "Size",
      field: "size",
      width: getColumnWidth(order.orderLines, "size", null, 12)
    },
    {
      headerName: "Description",
      field: "description",
      tooltipField: "description",
      flex: 1,
      minWidth: 100
    },
    {
      headerName: "Note",
      field: "comment",
      tooltipValueGetter: params => params.value || "-",
      width: 50,
      cellRenderer: CommentCell,
      cellRendererParams: params => ({
        order: order,
        disableUIFunc: disableUIFunc,
        saveLine: saveLine
      })
    }
  ];
  if (order.uiState.showTrials) {
    cols.push({
      headerName: "Trial Status",
      field: "trialStatus",
      width: 80
    });
  }
  if (isOnDvaContract(order)) {
    cols.push({
      headerName: "RAP#",
      field: "rapScheduleCode",
      width: getColumnWidth(order.orderLines, "rapScheduleCode", null, 12)
    });
  }
  cols.push(
    {
      headerName: "Sale Type",
      field: "saleType",
      wrapHeaderText: true,
      width: getColumnWidth(order.orderLines, "saleType", null),
      minWidth: 52
    },
    {
      headerName: "UOM",
      field: "unit",
      width: getColumnWidth(order.orderLines, "unit", null, 12)
    },
    {
      headerName: "Qty",
      field: "quantity",
      width: 40,
      cellStyle: { textAlign: "right" },
      editable: params => allowEditQuantity(currentUser, order, params.data),
      cellEditor: "agNumberCellEditor",
      cellEditorParams: {
        min: 1,
        step: 1
      }
    }
  );

  return cols;
};

getBaseColumns.propTypes = {
  order: OrderObject.isRequired,
  currentUser: CurrentUserObject.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  saveLine: PropTypes.func.isRequired
};

export { getBaseColumns };
