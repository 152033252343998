import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import { contractIdFromPath } from "../../lib/Util/url";
import GridPropsQuery from "../../lib/AgGrid/GridPropsQuery";

const contractId = contractIdFromPath();
const gridPropsFields = [
  {
    dataKey: "networkPartners",
    gqlQueryString: `
      networkPartners(agGridParams: {}, contractId:${contractId}) {
        id
        name
      }`
  }
];

const ContractNPUsersApp = () => (
  <GraphqlWrapper>
    <GridPropsQuery
      additionalFields={gridPropsFields}
      render={({ contractCodes, currentUser, networkPartners }) => (
        <section className="contract_np_users_section">
          <Table
            contractCodes={contractCodes}
            currentUser={currentUser}
            networkPartners={networkPartners}
          />
        </section>
      )}
    />
  </GraphqlWrapper>
);

export default ContractNPUsersApp;
