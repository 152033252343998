import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const WardTransferQuery = ({ contractId, render }) => (
  <Query
    query={gqlWardTransferQuery(contractId)}
    fetchPolicy="cache-and-network"
  >
    {({ loading, error, data }) => {
      if (!data.facilities || !data.salesReps) return <p>Loading...</p>;
      const sortedFacilities = data.facilities.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return render({
        facilities: sortedFacilities,
        salesReps: data.salesReps
      });
    }}
  </Query>
);

const gqlWardTransferQuery = contractId => {
  return gql`
    query WardTransfer {
      facilities(contractId: ${contractId}, activeOnly: true, excludeDrafts: true) {
        id
        name
        costCentre
        sourceId
        debtor
      }
      salesReps {
        id
        gpCode
        status
        firstName
        lastName
        name
        territory
        searchText
      }
    }
  `;
};

WardTransferQuery.propTypes = {
  contractId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default WardTransferQuery;
