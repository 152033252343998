import React from "react";
import PropTypes from "prop-types";
import { DocumentObject } from "../Queries/OrderQuery";

class DownloadAll extends React.Component {
  render() {
    if (this.props.documents.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <a
          className="button button--back"
          href={window.location.pathname + "/download_attachments"}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download All
        </a>
      </React.Fragment>
    );
  }
}

DownloadAll.propTypes = {
  documents: PropTypes.arrayOf(DocumentObject)
};

export default DownloadAll;
