import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { apolloClient } from "GraphQL/GraphqlWrapper";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";
import processingInvoiceLines from "./../Queries/graphql/processingInvoiceLines";

const MUTATION = gql`
  mutation processInvoiceLines($id: ID!, $input: [InvoiceLineInput!]!) {
    processInvoiceLines(id: $id, input: $input) {
      order {
        invoiceableLinesCount
        reversibleLinesCount
        ...OrderFields
        ...OrderLinesAndEventsQuery
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
`;

class ProcessInvoiceLinesUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={MUTATION}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const processLines = (orderId, lines) => {
            this.cacheProcessingLineIds(lines);
            const input = lines.map(line => this.whitelistedInput(line));
            update({
              variables: {
                id: orderId,
                input: input
              }
            });
          };
          return render({ processLines });
        }}
      </Mutation>
    );
  }

  cacheProcessingLineIds = lines => {
    let lineIds = lines.map(line => line.id);
    const cachedLineIds = apolloClient.readFragment({
      id: processingInvoiceLines.cacheFragmentId,
      fragment: processingInvoiceLines.query
    });
    if (cachedLineIds) {
      lineIds = lineIds.concat(cachedLineIds.processingInvoiceLineIds);
    }
    apolloClient.writeFragment({
      id: processingInvoiceLines.cacheFragmentId,
      fragment: processingInvoiceLines.query,
      data: {
        processingInvoiceLineIds: lineIds,
        __typename: "Order"
      }
    });
  };

  clearCachedProcessingLines = () => {
    apolloClient.writeFragment({
      id: processingInvoiceLines.cacheFragmentId,
      fragment: processingInvoiceLines.query,
      data: {
        processingInvoiceLineIds: [],
        __typename: "Order"
      }
    });
  };

  whitelistedInput = line => {
    const input = {};
    const whitelist = ["id", "partnerReference", "status"];

    whitelist.map(key => {
      if (line[key] !== undefined) {
        input[key] = line[key];
      }
    });
    return input;
  };

  onError = error => {
    this.clearCachedProcessingLines();
    console.log("onError occurred");
    console.log(error);
  };

  onCompleted = () => {
    this.clearCachedProcessingLines();
  };
}

ProcessInvoiceLinesUpdater.propTypes = {
  render: PropTypes.func
};

export default ProcessInvoiceLinesUpdater;
