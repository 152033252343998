import React from "react";
import PropTypes from "prop-types";
import Modal from "Util/Modal";

class RoMemo extends React.Component {
  state = { isOpen: false };

  render() {
    const { value, labelText, small } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        <div className="field">
          <label htmlFor={labelText}>{labelText}</label>
          <div>
            <div
              className={`memoViewer ${expanded &&
                "memoViewer__expanded"} ${small && "memoViewer__small"}`}
            >
              {value ? value : "-"}
            </div>
            {value && (
              <a className="memoViewer__view_more" onClick={this.toggleModal}>
                (view more)
              </a>
            )}
          </div>
        </div>

        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.toggleModal}
            header={labelText}
            renderFooter={() => (
              <div>
                <a onClick={this.toggleModal} className="button button--back">
                  Back to order
                </a>
              </div>
            )}
          >
            {value}
          </Modal>
        )}
      </div>
    );
  }

  toggleModal = () => this.setState({ isOpen: !this.state.isOpen });
}

RoMemo.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  labelText: PropTypes.string.isRequired,
  small: PropTypes.bool
};

export default RoMemo;
