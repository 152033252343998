import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const OrderLink = ({ order, children }) => (
  <Link to={`/orders/${order.id}`}>{children}</Link>
);

OrderLink.propTypes = {
  order: PropTypes.object
};

export { OrderLink };
