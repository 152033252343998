import React from "react";
import { OrderObject } from "../Queries/OrderQuery";

import TableView from "./TableView";

class ChecklistIndex extends React.Component {
  render() {
    const { order } = this.props;
    return <TableView checklist={order.checklist} />;
  }
}
ChecklistIndex.propTypes = {
  order: OrderObject
};

export default ChecklistIndex;
