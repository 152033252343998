import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";
import InventoryTable from "./InventoryTable";
import InventoryQuery from "./InventoryQuery";

class InventoryModal extends React.Component {
  state = { lastUpdate: "" };

  render() {
    const { orderId, close } = this.props;
    return (
      <Modal
        show={true}
        header="Stock Levels"
        headerSmall={`Last updated: ${this.state.lastUpdate}`}
        onClose={close}
        className="stock-levels-modal"
      >
        <InventoryQuery
          orderId={orderId}
          onComplete={this.setLastUpdate}
          render={({ loading, order }) => (
            <InventoryTable loading={loading} order={order} />
          )}
        />
      </Modal>
    );
  }

  setLastUpdate = data => {
    if (!data.order.stockCheck) {
      return;
    }
    const newVal = data.order.stockCheck.lastUpdate;
    this.setState({ lastUpdate: newVal });
  };
}

InventoryModal.propTypes = {
  orderId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired
};

export default InventoryModal;
