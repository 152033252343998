import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import orderFragments from "./../Queries/graphql/orderFragments";
import { FacilityObject, OrderObject } from "../Queries/OrderQuery";
import FacilityForm from "./FacilityForm";
import facilityFragments from "../Queries/graphql/facilityFragments";

const UPDATE_FACILITY = gql`
  mutation updateFacility($id: ID!, $input: FacilityInput!) {
    updateFacility(id: $id, input: $input) {
      facility {
        ...FacilityQuery
      }
      order {
        ...OrderFields
      }
      errors {
        path
        message
      }
    }
  }
  ${facilityFragments.query}
  ${orderFragments.query}
`;

class FacilityUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { facility, order } = this.props;
    return (
      <Mutation
        mutation={UPDATE_FACILITY}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(updateFacility, { loading, error }) => (
          <FacilityForm
            facility={facility}
            order={order}
            update={updateFacility}
            errors={this.state.errors}
          />
        )}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
  };

  onCompleted = data => {
    let newFacility = null;
    if (
      data.hasOwnProperty("updateFacility") &&
      data.updateFacility.hasOwnProperty("facility")
    ) {
      newFacility = data.updateFacility.facility;
    }
    this.props.toggleEdit(newFacility);
  };
}

FacilityUpdater.propTypes = {
  facility: FacilityObject.isRequired,
  order: OrderObject.isRequired,
  toggleEdit: PropTypes.func.isRequired
};

export default FacilityUpdater;
