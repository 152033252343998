import React from "react";
import PropTypes from "prop-types";
import CmpAgGridLink from "./CmpAgGridLink";

class CmpAgGridOrderLink extends React.Component {
  render() {
    const { data, value, link } = this.props;

    return (
      <React.Fragment>
        {data.urgent && <div className="is-urgent" />}
        <CmpAgGridLink value={value} link={link} />
      </React.Fragment>
    );
  }
}

CmpAgGridOrderLink.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default CmpAgGridOrderLink;
