import React from "react";
import PropTypes from "prop-types";

class ConfirmOrderBtn extends React.Component {
  render() {
    const buttonText = this.props.order.includesHomeMods
      ? "Send Work Order"
      : "Confirm order";

    return (
      <div className="add-item-action assign-order-btn">
        <button className="button primary" onClick={this.confirm}>
          {buttonText}
        </button>
      </div>
    );
  }

  confirm = e => {
    this.props.updateOrder({
      id: this.props.order.id,
      status: "approval_confirmed"
    });
  };
}

ConfirmOrderBtn.propTypes = {
  order: PropTypes.object,
  updateOrder: PropTypes.func.isRequired
};

export default ConfirmOrderBtn;
