import React from "react";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import userFragments from "./../UserAccounts/Queries/userFragments";
import PropTypes from "prop-types";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import RemoveFromPartnerBtn from "./RemoveFromPartnerBtn";

const networkPartnerIdFromPath = () => {
  const matchPath = window.location.pathname.match(/network_partners\/\d+/);
  if (matchPath) {
    return matchPath[0].match(/\d+/)[0];
  } else {
    return null;
  }
};

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.npUsersQuery}
        frameworkComponents={this.frameworkComponents}
        responseDataKey="networkPartnerUsers"
      />
    );
  }

  frameworkComponents = {
    removeFromPartnerRenderer: params => {
      return (
        <RemoveFromPartnerBtn
          partnerId={networkPartnerIdFromPath()}
          params={params}
        />
      );
    }
  };

  defaultColumnDefs = () => {
    let cols = [
      {
        headerName: "First Name",
        field: "firstName",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridLink,
        cellRendererParams: params => {
          return {
            link: `/user_accounts/${params.data.id}?tab=details`,
            newTab: true
          };
        }
      },
      {
        headerName: "Last Name",
        field: "lastName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Employee Id",
        field: "employeeId",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Roles",
        field: "namedRoles",
        // Doesn't make sense to sort when there are multiple
        sortable: false,
        filter: false
      },
      {
        headerName: "Contracts",
        colId: "contract",
        field: "contracts",
        // Doesn't make sense to sort when there are multiple
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Phone #",
        field: "phoneNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Mobile #",
        field: "mobileNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Active", "Inactive"]
        }
      },
      {
        headerName: "Last Sign In",
        field: "formattedLastSignIn",
        // current_sign_in_at shows the most recent sign in
        colId: "currentSignInAt",
        filter: "agDateColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "lessThan", "greaterThan"]
        }
      }
    ];
    if (this.props.currentUser.itAdmin) {
      cols.push({
        headerName: "Remove",
        field: "removeFromPartner",
        sortable: false,
        filter: false,
        cellRenderer: "removeFromPartnerRenderer"
      });
    }
    return cols;
  };

  npUsersQuery = () => {
    const id = networkPartnerIdFromPath();
    return gql`
      query NetworkPartnerUsers($agGridParams: RequestParamInput!) {
        networkPartnerUsers(agGridParams: $agGridParams, id:${id}) {
          ...UserQuery
        }
      }
      ${userFragments.query}
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default TableView;
