import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";
import TransferLinesTable from "./TransferLinesTable";
import PropTypes from "prop-types";

class TransferBtn extends React.Component {
  state = { isOpen: false };

  render() {
    const { order, currentUser } = this.props;
    return (
      <React.Fragment>
        <button className="button button--white" onClick={this.open}>
          Transfer Order
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.close}
            header="Transfer Order"
            renderFooter={() => (
              <a onClick={this.close} className="button button--back">
                Cancel
              </a>
            )}
          >
            <TransferLinesTable
              order={order}
              orderLines={this.transferableLines()}
              closeParentModal={this.close}
              currentUser={currentUser}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });

  transferableLines = () => {
    const { order } = this.props;

    let lines = order.orderLines.filter(
      line => !["Cancelled", "Removed", "Returned"].includes(line.status)
    );
    if (order.status === "pending_validation") {
      return lines;
    }
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return lines.filter(line => {
      if (["HIRE", "RECYCLED"].includes(line.saleType) && line.deliveredOn) {
        let lineDeliveredOn = new Date(line.deliveredOn);
        lineDeliveredOn.setHours(0, 0, 0, 0);
        return lineDeliveredOn < today;
      } else {
        return false;
      }
    });
  };
}

TransferBtn.propTypes = {
  order: OrderObject,
  currentUser: PropTypes.object
};

export default TransferBtn;
