import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import { OrderObject } from "./../Queries/OrderQuery";
import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const ACCEPT_ORDER = gql`
  mutation acceptOrder($id: ID!) {
    acceptOrder(id: $id) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
`;

class AcceptOrderUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation mutation={ACCEPT_ORDER} onError={this.onError}>
        {(update, { loading, error }) => {
          const acceptOrder = () => {
            update({
              variables: {
                id: order.id
              }
            });
          };
          return render({ order, acceptOrder });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

AcceptOrderUpdater.propTypes = {
  order: OrderObject,
  render: PropTypes.func.isRequired
};

export default AcceptOrderUpdater;
