import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { OrderObject } from "../Queries/OrderQuery";

const CostCentresQuery = ({ order, render }) => {
  const contractId = order.contract.id;
  const query =
    order.hospitalType === "Ward"
      ? wardCostCentresQuery(contractId)
      : dischargeCostCentresQuery(contractId);
  return (
    <Query query={query}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;
        const costCentres =
          order.hospitalType === "Ward"
            ? data.contract.wardCostCentres
            : data.dischargeCostCentres.map(cc => ({
                value: cc.code,
                text: `${cc.code}${
                  cc.description ? " (" + cc.description + ")" : ""
                }`
              }));
        return render({
          costCentres: costCentres
        });
      }}
    </Query>
  );
};

const wardCostCentresQuery = contractId => {
  return gql`
    query CostCentres {
      contract(id: ${contractId}) {
        wardCostCentres
      }
    }
  `;
};

const dischargeCostCentresQuery = contractId => {
  return gql`
    query DischargeCostCentres {
      dischargeCostCentres(contractId: ${contractId}, activeOnly: true) {
        code
        description
      }
    }
  `;
};

CostCentresQuery.propTypes = {
  order: OrderObject,
  render: PropTypes.func.isRequired
};

export default CostCentresQuery;
