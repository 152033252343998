import React from "react";
import PropTypes from "prop-types";

import { OrderObject, CurrentUserObject } from "../Queries/OrderQuery";
import OrderContext from "../OrderContext";
import ShipmentsButton from "./ShipmentsButton";

const Shipments = props => (
  <OrderContext.Consumer>
    {({ isLineEditing }) =>
      isLineEditing ||
      !props.order.uiState.allowProcessShipments ||
      !props.uiActive ? (
        <a className={"button button--disabled"}>Shipments</a>
      ) : (
        <ShipmentsButton
          order={props.order}
          currentUser={props.currentUser}
          awaitingCallPending={props.awaitingCallPending}
        />
      )
    }
  </OrderContext.Consumer>
);

Shipments.defaultProps = {
  awaitingCallPending: false
};

Shipments.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject.isRequired,
  awaitingCallPending: PropTypes.bool,
  uiActive: PropTypes.bool.isRequired
};

export default Shipments;
