import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";
import SalesRepSelector from "./SalesRepSelector";

class EditSalesRep extends React.Component {
  state = { editing: false };

  render() {
    const { value, labelText, options, required } = this.props;
    return (
      <React.Fragment>
        <div className={"field"}>
          <label
            htmlFor={labelText}
            className={required ? "field--required" : ""}
          >
            {labelText}
          </label>
          <span className="sub-modal-select">
            {value}&nbsp;
            <a
              className="button button--small salesrep-edit-button"
              onClick={this.toggleEdit}
            >
              {value ? "Edit" : "Add"}
            </a>
          </span>
        </div>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            header="Select the staff rep for order"
            onClose={this.toggleEdit}
          >
            <SalesRepSelector options={options} onChange={this.onChange} />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  onChange = selection => {
    this.props.onChange("salesRep", selection);
    this.toggleEdit();
  };

  toggleEdit = e => {
    e && e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };
}

EditSalesRep.propTypes = {
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool
};

export default EditSalesRep;
