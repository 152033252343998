import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";

const PartnerCSMsg = ({ order }) => {
  if (!order || !order.partner || !order.partner.csMessage) {
    return null;
  }
  return (
    <div className="message message--error" role="alert">
      <p>{order.partner.csMessage}</p>
    </div>
  );
};

PartnerCSMsg.propTypes = {
  order: OrderObject
};

export default PartnerCSMsg;
