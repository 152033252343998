import React from "react";
import PropTypes from "prop-types";

import { ClientObject } from "./../Queries/OrderQuery";
import ClientUpdater from "./ClientUpdater";
import ClientView from "./ClientView";
import ClientModal from "./Modal";
import { isErapOrder } from "../BusRules/access";

class Client extends React.Component {
  state = { editing: false };

  render() {
    const { client, order, editable, currentUser } = this.props;
    const canEdit = editable && order.uiState.currentUserIsHeadOffice
    if (client == null) {
      if (!["draft", "cancelled"].includes(order.status)) {
        return <div>loading...</div>;
      } else {
        return (
          <article className="order-client-area">
            <header className="display_group__header">
              <h2 className="display_group__header__heading">
                Client &amp; Delivery Details
              </h2>
              <ClientModal order={order} currentUser={currentUser} />
            </header>
          </article>
        );
      }
    }
    return (
      <article className="order-client-area">
        <header className="display_group__header">
          <h2 className="display_group__header__heading">
            Client &amp; Delivery Details
            <a
              href={"/clients/" + client.originalRecordId + "?tab=details"}
              target="_blank"
              rel="noopener noreferrer"
              className="display_group__header__heading__small"
            >
              View Client
            </a>
          </h2>
          {!this.state.editing &&
            canEdit &&
            !isErapOrder(order) &&
            ["draft", "quote", "submitted", "pending_validation"].includes(
              order.status
            ) && (
              <ClientModal
                order={order}
                buttonText={"Change Client"}
                currentUser={currentUser}
              />
            )}
          {canEdit && (
            <a
              className="display_group__header__button"
              onClick={this.toggleEdit}
            >
              {this.state.editing ? "Cancel" : "Edit"}
            </a>
          )}
        </header>
        {this.state.editing ? (
          <ClientUpdater {...this.props} toggleEdit={this.toggleEdit} />
        ) : (
          <ClientView {...this.props} />
        )}
      </article>
    );
  }

  toggleEdit = () => {
    this.setState(prevState => {
      this.props.disableUIFunc(!prevState.editing);
      return { editing: !prevState.editing };
    });
  };
}

Client.propTypes = {
  client: ClientObject,
  order: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default Client;
