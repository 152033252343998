import React from "react";
import PropTypes from "prop-types";

import OrderUpdater from "./../../OrderUpdater";
import EditDebtor from "../../OrderDetails/EditDebtor";
import SelectDebtorQuery from "./SelectDebtorQuery";

class SelectDebtor extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <SelectDebtorQuery
        contractId={order.contract.id}
        render={({ debtors }) => (
          <OrderUpdater
            currentUser={this.props.currentUser}
            order={order}
            render={({ updateOrder }) => (
              <div
                className={`select-debtor-in-allocation ${!order.debtor &&
                  "select-debtor-in-allocation__required"}`}
              >
                <EditDebtor
                  onChange={(field, value) =>
                    this.handleChange(value, updateOrder)
                  }
                  value={(order.debtor && order.debtor.code) || null}
                  labelText="Debtor"
                  options={debtors}
                  bulkPrice={{
                    showWarning: order.orderLines.length > 0,
                    initialValue: order.debtor && order.debtor.bulkPricing
                  }}
                />
              </div>
            )}
          />
        )}
      />
    );
  }

  handleChange = (debtor, updateOrder) => {
    const order = { id: this.props.order.id, debtor, debtorId: debtor.id };
    updateOrder(order);
  };
}

SelectDebtor.propTypes = {
  order: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default SelectDebtor;
