import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class CancelBtn extends React.Component {
  render() {
    return (
      <button className="button primary" onClick={this.open}>
        Reinstate Order
      </button>
    );
  }

  open = () => {
    if (
      window.confirm(
        "This will reinstate the order as a brand new order \nReinstate this order?"
      )
    ) {
      this.cancelOrder();
    }
  };

  cancelOrder = () => {
    const { update, order } = this.props;
    update({
      variables: {
        id: order.id
      }
    });
  };
}

CancelBtn.propTypes = {
  order: OrderObject,
  update: PropTypes.func.isRequired
};

export default CancelBtn;
