import React from "react";
import PropTypes from "prop-types";

const CommentsCounter = ({ order }) => (
  <React.Fragment>
    <div className="icon icon--comments" />
    <div
      className={`comments-counter ${
        order.unreadCommentsCount > 0
          ? "comments-counter__unread"
          : order.commentsCount > 0
            ? "comments-counter__read"
            : ""
      }`}
    >
      {order.commentsCount > 0 ? order.commentsCount : "-"}
    </div>
  </React.Fragment>
);

CommentsCounter.propTypes = {
  order: PropTypes.object.isRequired
};

export default CommentsCounter;
