import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";

import AcceptBtn from "./AcceptBtn";
import RejectBtn from "./RejectBtn";
import RequestApprovalBtn from "./RequestApprovalBtn";

const FulfillmentOptions = ({ order }) => {
  if (!order.uiState.showFulfillment) {
    return null;
  }
  return (
    <div className="title_bar_section__buttons">
      {order.uiState.showAcceptReject && <AcceptBtn order={order} />}
      {order.uiState.showAcceptReject && <RejectBtn order={order} />}
      {order.uiState.showRequestApproval && (
        <RequestApprovalBtn order={order} />
      )}
    </div>
  );
};

FulfillmentOptions.propTypes = {
  order: OrderObject
};

export default FulfillmentOptions;
