const getColumnWidth = (rows, accessor, type, spacing) => {
  const maxWidth = 400
  const minWidth = 30
  const s = spacing || 10

  const getLength = (row) => {
    const value = row[accessor]
    
    if (!value) return minWidth;
    return (`${row[accessor]}` || '').length * s;
  }
  
  let cellLength = Math.max( ...rows.map(row => getLength(row)) );

  if (type == "price") {
    let total = 0;
    rows.map(row => total += row[accessor]);
    cellLength = `$${total.toFixed(2)}`.length * s;
  }

  if (cellLength < minWidth) return minWidth;
  return Math.min(maxWidth, cellLength)
}

export { getColumnWidth }; 
