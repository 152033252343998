import React from "react";
import PropTypes from "prop-types";

import EditTextField from "./EditTextField";
import EditDropdown from "./EditDropdown";

const EditDeliveryAddressFields = ({ model, onChange, errors }) => (
  <React.Fragment>
    <h3 className={"fieldset__heading"}>Delivery Address</h3>
    <EditTextField
      name="deliveryAddress"
      onChange={onChange}
      value={model.deliveryAddress}
      labelText="Address"
      errors={errors}
    />
    <EditTextField
      name="deliveryCity"
      onChange={onChange}
      value={model.deliveryCity}
      labelText="City"
      errors={errors}
    />
    <EditTextField
      name="deliveryPostcode"
      onChange={onChange}
      value={model.deliveryPostcode}
      labelText="Postcode"
      errors={errors}
    />

    <EditDropdown
      name="deliveryState"
      onChange={onChange}
      value={model.deliveryState}
      labelText="State"
      options={model.stateOptions}
      errors={errors}
    />
  </React.Fragment>
);

EditDeliveryAddressFields.propTypes = {
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default EditDeliveryAddressFields;
