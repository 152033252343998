import React from "react";

import { isAllSent } from "./../BusRules/orders";
import { OrderObject } from "./../Queries/OrderQuery";

import { OrderLink } from "./../OrderNav";

const AllSentMsg = ({ parentOrder, currOrder }) => {
  if (!isAllSent(parentOrder)) {
    return null;
  }
  return (
    <div className="message message--success" role="alert">
      <p>
        All items have been assigned and sent to the nominated network partners.
        For any updates, please check the individual{" "}
        {parentOrder.id === currOrder.id ? (
          <span>orders in their individual tabs</span>
        ) : (
          <span>
            order -{" "}
            <OrderLink order={currOrder}>#{currOrder.cmpOrderNumber}</OrderLink>
          </span>
        )}
      </p>
    </div>
  );
};

AllSentMsg.propTypes = {
  parentOrder: OrderObject,
  currOrder: OrderObject
};

export default AllSentMsg;
