import React from "react";
import PropTypes from "prop-types";

import PartnerReferenceFieldEdit from "./PartnerReferenceFieldEdit";
import InvoiceLineUpdater from "./InvoiceLineUpdater";

class PartnerReferenceField extends React.Component {
  state = { editing: false, partnerReference: "" };
  containerRef = React.createRef();
  render() {
    const { invoiceLine } = this.props;
    const { editing } = this.state;
    return (
      <div ref={this.containerRef}>
        {editing ? (
          <InvoiceLineUpdater>
            {({ updateInvoiceLine }) => (
              <PartnerReferenceFieldEdit
                invoiceLine={invoiceLine}
                updateInvoiceLine={updateInvoiceLine}
                close={this.close}
                containerRef={this.containerRef.current}
              />
            )}
          </InvoiceLineUpdater>
        ) : (
          <div
            className="order-lines-invoice-partner-reference"
            onClick={this.edit}
          >
            {invoiceLine.partnerReference}
            <div className="icon icon--edit icon--right icon--tiny" />
          </div>
        )}
      </div>
    );
  }

  edit = () => {
    this.setState({ editing: true });
    this.props.rowNode.setRowHeight(70);
    this.props.api.onRowHeightChanged();
  };

  close = () => {
    this.setState({ editing: false });
    this.props.api.resetRowHeights();
  };
}

PartnerReferenceField.propTypes = {
  invoiceLine: PropTypes.object.isRequired,
  api: PropTypes.object,
  rowNode: PropTypes.object
};

export default PartnerReferenceField;
