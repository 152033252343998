import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";
import PropTypes from "prop-types";
import SendSMSMutation from "./SendSMSMutation";
import EditTextField from "../../lib/Form/EditTextField";
import EditTextArea from "../../lib/Form/EditTextArea";
import { isValidMobile } from "../../lib/Form/formatPhone";

class SendSMSBtn extends React.Component {
  defaultTo = () => {
    const clientMobile = this.props.order.client.mobileNumber;
    if (clientMobile && isValidMobile(clientMobile)) {
      return clientMobile;
    }
    const clientPhone = this.props.order.client.phoneNumber;
    if (clientPhone && isValidMobile(clientPhone)) {
      return clientPhone;
    }
    return '';
  };

  defaultBody = () => this.props.currentUser.defaultClientSms;

  state = {
    isOpen: false,
    submitDisabled: false,
    error: null,
    toIsValid: isValidMobile(this.defaultTo()),
    sms: {
      to: this.defaultTo(),
      body: this.defaultBody(),
    }
  };

  render() {
    const { order, currentUser } = this.props;
    const { error, sms } = this.state;
    return (
      <React.Fragment>
        <button className="button network_partner" onClick={this.open}>
          Send SMS to Client
        </button>
        {this.state.isOpen && (
          <SendSMSMutation
            order={order}
            onError={this.smsError}
            onComplete={this.close}
            render={({ sendSMS }) => (
              <Modal
                show={this.state.isOpen}
                onClose={this.close}
                header="Send SMS"
                renderFooter={() => (
                  <div>
                    <button
                      className="button button--back"
                      onClick={() => this.close(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="button primary"
                      onClick={() => {
                        this.setState({submitDisabled: true});
                        sendSMS(sms.to, order.client.defaultSmsPrefix + sms.body);
                      }}
                      disabled={
                        this.state.submitDisabled ||
                        !this.state.toIsValid ||
                        !sms.to ||
                        !sms.body
                      }
                    >
                      Send SMS
                    </button>
                  </div>
                )}
              >
                {error && (
                  <div className="alert" role="alert">
                    <p>{error}</p>
                  </div>
                )}
                {process.env.RAILS_ENV && process.env.RAILS_ENV !== "production" && (
                  <div className="message message--error message--block" role="alert">
                    <strong>NON-PRODUCTION environment</strong>
                    <p>The message will actually be sent to <strong>YOUR</strong> mobile number.</p>
                    <p>If you don't have one set the message will fail.</p>
                  </div>
                )}
                {!isValidMobile(this.defaultTo()) && (
                  <div className="message message--error message--block" role="alert">
                    <p>The mobile number is taken from the client's mobile or phone fields.</p>
                    <p>If it is incorrect please consider updating the client first</p>
                  </div>
                )}
                <fieldset className="fieldset">
                  <EditTextField
                    onChange={this.handleChange}
                    name="to"
                    value={sms.to}
                    labelText="To"
                    className="fullwidth"
                    pattern="04[0-9]{8}"
                  />
                  <EditTextArea
                    onChange={this.handleChange}
                    name="body"
                    value={sms.body}
                    labelText="Message"
                    preText={order.client.defaultSmsPrefix}
                  />
                </fieldset>
              </Modal>
            )}
          />
        )}
      </React.Fragment>
    )
  }
  
  handleChange = (field, value) => {
    if (field === "to") {
      if (!isValidMobile(value)) {
        this.setState(prevSate => ({
          error: "Invalid mobile number",
          toIsValid: false
        }))
      } else {
        this.setState({ error: null, toIsValid: true })
      }
    }
    this.setState(prevState => ({
      sms: { ...prevState.sms, [field]: value }
    }));
  };
  
  open = () => this.setState(
    {
      isOpen: true,
      submitDisabled: false,
      sms: {
        to: this.defaultTo(),
        body: this.defaultBody(),
      }
    }
  );
  
  close = () => this.setState(
    {
      isOpen: false,
      submitDisabled: false,
      sms: {
        to: this.defaultTo(),
        body: this.defaultBody(),
      }
    }
  );
  
  smsError = () => {
    this.setState({
      error: "Error sending SMS, please refresh and try again",
      submitDisabled: false
    });
  };
}

SendSMSBtn.propTypes = {
  order: OrderObject.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default SendSMSBtn;
