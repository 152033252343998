import React from "react";

import WardModal from "./WardModal";
import DischargeModal from "./DischargeModal";
import { OrderObject } from "./../Queries/OrderQuery";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";

class TransferLinesTable extends React.Component {
  state = { selections: [] };
  render() {
    const { order, orderLines, closeParentModal, currentUser } = this.props;

    return (
      <React.Fragment>
        <div
          id="transfer-lines-table"
          className="ag-theme-balham ag-grid-wrapper auto-height"
        >
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={orderLines}
            columnDefs={this.columnDefs()}
            defaultColDef={this.defaultColDef}
            domLayout="autoHeight"
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={this.setSelections}
          />
        </div>
        <div className="button-group">
          <WardModal
            closeParentModal={closeParentModal}
            order={order}
            selectedLineIds={this.state.selections}
          />
          <DischargeModal
            closeParentModal={closeParentModal}
            order={order}
            selectedLineIds={this.state.selections}
            currentUser={currentUser}
          />
        </div>
      </React.Fragment>
    );
  }

  columnDefs = () => [
    {
      headerName: "Cat Num",
      field: "sku",
      width: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1
    },
    {
      headerName: "Type",
      field: "saleType",
      width: 60
    },
    {
      headerName: "Qty",
      field: "quantity",
      width: 60
    },
    {
      headerName: "Asset ID/Serial #",
      field: "assetDetails",
      width: 150
    }
  ];

  defaultColDef = {
    resizable: false,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  };

  onGridReady = params => {
    this.agGridApi = params.api;
  };

  setSelections = () => {
    const selectedIds = this.agGridApi.getSelectedRows().map(row => row.id);
    this.setState({ selections: selectedIds });
  };
}

TransferLinesTable.propTypes = {
  order: OrderObject.isRequired,
  orderLines: PropTypes.array.isRequired,
  closeParentModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default TransferLinesTable;
