import React from "react";
import PropTypes from "prop-types";

import { OrderObject, CurrentUserObject } from "./../Queries/OrderQuery";

import OrderTabs from "./../OrderTabs";

import OrderContext from "./RelatedOrderContext";

import { unassignedCount, isSent, lineCount } from "./../BusRules/orders";

import OrderTabButton from "./OrderTabButton";
import TabIndicator from "./TabIndicator";
import AllSentMsg from "./AllSentMsg";
import InvoiceIssueWarningMsg from "./../Invoicing/InvoiceIssueWarningMsg";
import TransferredMsg from "./TransferredMsg";
import OverPaymentWarningMsg from "../Payments/OverPaymentWarningMsg";

class RelatedOrderTabs extends React.Component {
  state = { currOrderId: null };

  render() {
    const { order, currentUser, uiActive, disableUIFunc } = this.props;
    if (order == null) {
      return <div>loading...</div>;
    }
    const showAssign = order.uiState && order.uiState.showAssignment;
    const assigned = order.uiState && order.uiState.stageAssigned;
    const showSingleOrder = !(showAssign || assigned);

    if (showSingleOrder) {
      return this.singleOrderView(order, currentUser, uiActive, disableUIFunc);
    }
    const currOrder = this.currOrder();
    return (
      <section>
        <OrderContext.Provider
          value={{
            mainOrder: order,
            gotoNextAssignedOrder: this.gotoNextAssignedOrder
          }}
        >
          <div>
            <OrderTabButton
              order={order}
              currOrder={currOrder}
              setCurrOrder={this.setCurrOrder}
            >
              {this.mainOrderTabName(showAssign)}
            </OrderTabButton>

            {this.haveAllocations() &&
              order.orderAllocations.map(o => (
                <OrderTabButton
                  key={o.id}
                  order={o}
                  currOrder={currOrder}
                  setCurrOrder={this.setCurrOrder}
                  className="np-related-tab"
                >
                  {this.assignedOrderTabName(o)}
                </OrderTabButton>
              ))}
          </div>

          <div className="panel panel--tabs is-active">
            <div className="panel__body">
              <AllSentMsg parentOrder={order} currOrder={currOrder} />

              <OrderTabs
                order={currOrder}
                isSuborderDisplay={order.id !== currOrder.id}
                currentUser={currentUser}
                key={currOrder.id}
                uiActive={uiActive}
                disableUIFunc={disableUIFunc}
              />
            </div>
          </div>
        </OrderContext.Provider>
      </section>
    );
  }

  gotoNextAssignedOrder = () =>
    this.nextOrder() && this.setCurrOrder(this.nextOrder());

  mainOrderTabName = showAssign => {
    const { order } = this.props;
    return showAssign ? (
      <div>
        <div className="tab__label">
          {order.inReassign ? (
            <h2 className="panel__header__heading">
              Assigned to {order.partner.name}
              <span className="in-reassignment-message">
                Reassign to new partner(s)
              </span>
            </h2>
          ) : (
            <span>All Unassigned Items</span>
          )}
        </div>
        <TabIndicator
          num={unassignedCount(order)}
          isDone={unassignedCount(order) === 0}
        />
      </div>
    ) : (
      <div className="tab__label">
        Original order
        <div className="tab__icon tab-indicator is-done">
          <svg className="tab-indicator__icon" viewBox="0 0 100 100">
            <g>
              <path d="M87.8978532,14.004077 C85.3478411,14.0783947 82.9268403,15.1421678 81.1473944,16.9701877 C65.6095892,32.5415734 51.4234958,47.8100639 36.5023144,63.0471177 L18.9613494,48.9837437 C16.2208086,46.7908815 12.5185208,46.2267681 9.2492574,47.5039233 C5.97999401,48.7810784 3.64049991,51.7054461 3.11213806,55.1753225 C2.58377622,58.6451989 3.94682804,62.1333572 6.68778789,64.3256956 L31.2349109,83.963394 C35.1551708,87.0861193 40.7980128,86.7554488 44.3267098,83.1962145 C61.9843228,65.5006845 77.8036016,48.1203395 95.0574307,30.8290024 C97.941163,27.9994029 98.7975518,23.6923661 97.2156166,19.9748365 C95.6336814,16.2573069 91.9363038,13.8880525 87.8978532,14.004077 Z" />
            </g>
          </svg>
        </div>
      </div>
    );
  };

  singleOrderView = (order, currentUser, uiActive, disableUIFunc) => (
    <section className="panel is-active margin-bottom-base">
      {!order.partner && (
        <header className="panel__header">
          <h2 className="panel__header__heading">Order Items</h2>
        </header>
      )}

      {order.partner && (
        <header className="panel__header">
          <h2 className="panel__header__heading">
            Assigned to {order.partner.name}
          </h2>
          <a
            href={`/network_partners/${order.partner.id}?tab=details`}
            data-turbolinks="true"
            target={"_blank"}
            className="panel__header__action"
          >
            (show details)
          </a>
        </header>
      )}

      <div className="panel__body">
        <InvoiceIssueWarningMsg order={order} />
        <TransferredMsg order={order} />
        <OverPaymentWarningMsg order={order} />
        <OrderTabs
          order={order}
          isSuborderDisplay={false}
          currentUser={currentUser}
          uiActive={uiActive}
          disableUIFunc={disableUIFunc}
        />
      </div>
    </section>
  );

  assignedOrderTabName = o => (
    <div>
      <div className="tab__label">
        {isSent(o) ? "Sent" : "Assigned"} to {o.partner.name}{" "}
        {o.includesHomeMods && "(Home Mods)"}
        {o.includesRepair && "(Repair)"}
      </div>
      <TabIndicator num={lineCount(o)} isDone={isSent(o)} />
    </div>
  );

  setCurrOrder = o => this.setState({ currOrderId: o.id });

  haveAllocations = () =>
    this.props.order.orderAllocations ||
    this.props.order.orderAllocations.length > 0;

  currOrder = () => {
    const { order } = this.props;
    const { currOrderId } = this.state;
    return this.haveAllocations()
      ? order.orderAllocations.find(o => o.id === currOrderId) || order
      : order;
  };

  nextOrder = () => {
    const order = this.currOrder();
    const orders = this.props.order.orderAllocations;
    if (order.id === this.props.order.id) {
      // main order, return first related
      return orders[0];
    }
    for (let i = 0; i < orders.length - 1; i++) {
      if (order.id === orders[i].id) {
        return orders[i + 1];
      }
    }
  };
}

RelatedOrderTabs.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default RelatedOrderTabs;
