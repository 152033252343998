import React, { memo } from "react";
import PropTypes from "prop-types";
import { DateFieldInput } from "../../../lib/Form/EditDateField";
import { OrderObject } from "../../Queries/OrderQuery";

const StatusDependentFieldEditor = memo(
  ({
    value,
    onValueChange,
    formatValue,
    colDef,
    data,
    type,
    options,
    editableStatuses,
    order,
    editedLineStatus,
    limit,
    limits
  }) => {
    const lineStatus = editedLineStatus || data.status;
    const editable =
      order.uiState.editable && editableStatuses.includes(lineStatus);

    if (editable) {
      if (type === "date") {
        return (
          <DateFieldInput
            onChange={(name, value) => onValueChange(value)}
            className={"ag-edit-custom"}
            name={colDef.field}
            value={value}
            limit={limit}
            limits={limits}
          />
        );
      } else if (type === "select") {
        return (
          <select
            value={value || ""}
            className={"ag-edit-custom"}
            onChange={e => onValueChange(e.target.value)}
          >
            {options.map(o => (
              <option key={o}>{o}</option>
            ))}
          </select>
        );
      }
    }

    return formatValue(value);
  }
);

StatusDependentFieldEditor.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  formatValue: PropTypes.func,
  colDef: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  editableStatuses: PropTypes.array,
  order: OrderObject,
  editedLineStatus: PropTypes.string,
  limit: PropTypes.string,
  limits: PropTypes.object
};

export default StatusDependentFieldEditor;
