import React from "react";
import PropTypes from "prop-types";

import EditTextField from "Form/EditTextField";

class SeekApprovalEnterNumber extends React.Component {
  state = {
    order: { priorApprovalNumber: this.props.order.priorApprovalNumber }
  };
  render() {
    return (
      <form onSubmit={this.saveOrder}>
        <div className="seek-prior-approval-form">
          <EditTextField
            name="priorApprovalNumber"
            onChange={this.handleChange}
            value={this.state.order.priorApprovalNumber}
            labelText="Please enter Prior Approval Number"
          />
          <input
            type="submit"
            name="commit"
            disabled={this.state.order.priorApprovalNumber === ""}
            value="Update Prior Approval"
            className="button primary"
          />
          <a
            className="button button--cancel"
            onClick={this.props.toggleUpdateApproval}
          >
            cancel editing
          </a>
        </div>
      </form>
    );
  }

  saveOrder = e => {
    e.preventDefault();
    if (this.state.order.priorApprovalNumber > "") {
      this.props.updateOrder({
        id: this.props.order.id,
        priorApprovalNumber: this.state.order.priorApprovalNumber
      });
    }
    this.props.toggleUpdateApproval();
  };

  handleChange = (field, value) => {
    const order = { ...this.state.order, [field]: value };
    this.setState({ order });
  };
}

SeekApprovalEnterNumber.propTypes = {
  order: PropTypes.object,
  updateOrder: PropTypes.func.isRequired,
  toggleUpdateApproval: PropTypes.func.isRequired
};

export default SeekApprovalEnterNumber;
