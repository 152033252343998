import React from "react";

import { FacilityObject } from "./../Queries/OrderQuery";
import RoField from "Form/RoField";
import RoPhoneField from "../../lib/Form/RoPhoneField";

const FacilityViewFields = ({ facility }) => {
  return (
    <div>
      <RoField labelText="Name" value={facility.name} />
      <RoPhoneField labelText="Phone" value={facility.phone} />
      <RoField labelText="Address" value={facility.address} />
      <RoField labelText="City" value={facility.city} />
      <RoField labelText="Postcode" value={facility.postcode} />
      <RoField labelText="State" value={facility.state} />
    </div>
  );
};

FacilityViewFields.propTypes = {
  facility: FacilityObject.isRequired
};

export default FacilityViewFields;
