import PropTypes from "prop-types";
import gql from "graphql-tag";

import checklistSectionFragments from "./checklistSectionFragments";

const fragments = {
  query: gql`
    fragment ChecklistQuery on Checklist {
      id
      sections {
        ...ChecklistSectionQuery
      }
    }
    ${checklistSectionFragments.query}
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sections: PropTypes.arrayOf(checklistSectionFragments.object)
  })
};

export default fragments;
