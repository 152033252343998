import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import EditTextField from "../../lib/Form/EditTextField";
import { HealthcareProviderObject } from "../Queries/OrderQuery";

const EmailField = ({ hcp, contractId, handleChange, errors }) => {
  return (
    <GraphqlWrapper>
      <Query
        query={gqlQuery()}
        variables={{
          id: hcp.sourceId,
          contractId: contractId,
          email: hcp.email
        }}
      >
        {({ loading, error, data }) => {
          return (
            <React.Fragment>
              {data && data.healthcareProviderEmailExists && (
                <div
                  className="message message--error message--compact"
                  role="alert"
                >
                  <p>This email already exists against another HCP</p>
                </div>
              )}
              <EditTextField
                name="email"
                type="email"
                onChange={handleChange}
                value={hcp.email}
                labelText="Email"
                errors={errors}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlQuery = () => {
  return gql`
    query HealthcareProviderEmailExists(
      $id: Int!
      $contractId: Int!
      $email: String
    ) {
      healthcareProviderEmailExists(
        id: $id
        contractId: $contractId
        email: $email
      )
    }
  `;
};

EmailField.propTypes = {
  hcp: HealthcareProviderObject.isRequired,
  contractId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default EmailField;
