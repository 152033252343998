import React from "react";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import PropTypes from "prop-types";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.contractsQuery}
        responseDataKey="contracts"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Contract",
      field: "contractCode",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/contracts/${params.data.id}?tab=details`
        };
      }
    },
    {
      headerName: "Name",
      field: "longName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contract Type",
      field: "contractType",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Aged Care", "Community", "Hospital"]
      }
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Active", "Inactive"]
      }
    },
    {
      headerName: "Branches",
      field: "internalPartnerCount",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "Network Partners",
      field: "externalPartnerCount",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "Pricing from Contract",
      field: "pricingFromContract",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "OMNI ID",
      field: "omniId",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "EPI ID",
      field: "epiId",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "New Pricing Available",
      field: "newPricingAvailable",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    },
    {
      headerName: "Trials enabled",
      field: "trialsEnabled",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["True", "False"]
      }
    }
  ];

  contractsQuery = () => {
    return gql`
      query Contracts($agGridParams: RequestParamInput) {
        contracts(agGridParams: $agGridParams) {
          id
          contractCode
          internalPartnerCount
          externalPartnerCount
          longName
          contractType
          status
          pricingFromContract
          omniId
          epiId
          newPricingAvailable
          trialsEnabled
        }
      }
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired
};

export default TableView;
