import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import { contractIdFromPath } from "../lib/Util/url";
import { isOnDvaContract } from "../Orders/BusRules/access";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.scheduleItemQuery}
        responseDataKey="scheduleItems"
      />
    );
  }

  defaultColumnDefs = () => {
    let cols = [
      {
        headerName: "Cat Num",
        field: "sku",
        pinned: "left",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Size",
        field: "size",
        width: 100,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "UOM",
        field: "unit",
        width: 150,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Description",
        field: "description",
        width: 400,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "RAP#",
        field: "rapScheduleCode",
        width: 100,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Sale Type",
        field: "saleType",
        width: 150,
        filter: "agTextColumnFilter"
      }
    ];

    if (this.props.contract.showContractPricing) {
      cols.push({
        headerName: "Contract Price(ex GST)",
        field: "sellUnitPriceExc",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "greaterThan", "lessThan"]
        }
      });
    }
    if (this.props.contract.showNpPricing) {
      cols.push({
        headerName: "NP Price (ex GST)",
        field: "buyUnitPriceExc",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "greaterThan", "lessThan"]
        }
      });
    }
    if (this.props.contract.omniId) {
      cols.push({
        headerName: "OMNI Price (ex GST)",
        field: "omniUnitPriceExc",
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "greaterThan", "lessThan"]
        }
      });
    }
    if (
      this.props.contract.showContractPricing ||
      this.props.contract.showNpPricing
    ) {
      cols.push({
        headerName: "Weeks to own",
        field: "weeksToOwn",
        width: 150,
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ["equals", "greaterThan", "lessThan"]
        }
      });
    }
    if (
      this.props.contract.showContractPricing &&
      !isOnDvaContract(this.props.contract)
    ) {
      cols.push(
        ...[
          {
            headerName: "Bulk Price(ex GST)",
            field: "bulkUnitPriceExc",
            filter: "agNumberColumnFilter",
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ["equals", "greaterThan", "lessThan"]
            }
          },
          {
            headerName: "Bulk Quantity",
            field: "bulkQuantity",
            filter: "agNumberColumnFilter",
            filterParams: {
              suppressAndOrCondition: true,
              filterOptions: ["equals", "greaterThan", "lessThan"]
            }
          }
        ]
      );
    }
    cols.push(
      ...[
        {
          headerName: "GST",
          field: "gst",
          colId: "gstApplicable",
          width: 100,
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["Yes", "No"]
          }
        },
        {
          headerName: "Product Status",
          field: "status",
          width: 150,
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["Active", "Inactive", "Limited"]
          }
        },
        {
          headerName: "Prior Approval",
          field: "priorApproval",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Prescriber",
          field: "prescriber",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Brand Name",
          field: "manufacturerBrandName",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Brand Item Code",
          field: "manufacturerBrandCode",
          filter: "agTextColumnFilter"
        }
      ]
    );

    return cols;
  };

  scheduleItemQuery = () => {
    const contractId = contractIdFromPath();
    return gql`
      query ScheduleItems($agGridParams: RequestParamInput!) {
        scheduleItems(agGridParams: $agGridParams, contractId: ${contractId}) {
          id
          sku
          size
          unit
          description
          rapScheduleCode
          saleType
          gst
          sellUnitPriceExc
          buyUnitPriceExc
          bulkUnitPriceExc
          omniUnitPriceExc
          bulkQuantity
          weeksToOwn
          gstApplicable
          priorApproval
          prescriber
          manufacturerBrandName
          manufacturerBrandCode
          status
          lastErapPushSyncAt
        }
      }
    `;
  };
}

TableView.propTypes = {
  contract: PropTypes.object.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
