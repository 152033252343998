import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FieldWrapper from "./FieldWrapper";

const CommentEditDropdown = ({
  name,
  onChange,
  value,
  labelText,
  stackedLabel,
  options,
  required,
  errors
}) => (
  <FieldWrapper name={name} errors={errors} stackedLabel={stackedLabel}>
    <React.Fragment>
      <label htmlFor={name} className={required ? "field--required" : ""}>
        {labelText}
      </label>
      <select
        id={name}
        value={value || ""}
        onChange={e => onChange(name, e.target.value)}
      >
        {!Object.keys(options).includes(value || "") && <option key="" />}
        {Object.keys(options).map(option => (
          <option key={option} disabled={options[option]}>
            {option}
          </option>
        ))}
      </select>
    </React.Fragment>
  </FieldWrapper>
);

CommentEditDropdown.defaultProps = {
  required: false,
  stackedLabel: false
};

CommentEditDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string.isRequired,
  stackedLabel: PropTypes.bool,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool,
  errors: PropTypes.array
};

export default CommentEditDropdown;
