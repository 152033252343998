import React from "react";

import { OrderObject } from "../Queries/OrderQuery";

const OverPaymentWarningMsg = ({ order }) => {
  if (!order.uiState.orderOverpaid) {
    return null;
  }
  return (
    <div className="message message--error" role="alert">
      <p>
        The Total Order Value is below the already paid amount, please check the
        order details and payments in GP and adjust accordingly.
      </p>
    </div>
  );
};

OverPaymentWarningMsg.propTypes = {
  order: OrderObject
};

export default OverPaymentWarningMsg;
