import React from "react";
import Modal from "Util/Modal";
import HcpsTable from "../HealthcareProvider/Table";
import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";

class HcpsModal extends React.Component {
  state = { isOpen: false };

  render() {
    const { order, hcpName, currentUser } = this.props;

    return (
      <React.Fragment>
        <div className={"field hcp-select"}>
          <label htmlFor="hcp-id" className="field--required">
            Healthcare Provider
          </label>
          <span className="sub-modal-select">
            {hcpName}&nbsp;
            <a
              href="#"
              onClick={e => this.toggleModal(e)}
              className="button button--small"
            >
              {hcpName ? "Edit" : "Add"}
            </a>
          </span>
        </div>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={() => this.closeModal()}
            header="Select Healthcare Provider"
            className="hcps-modal"
            renderFooter={() => (
              <a
                onClick={() => this.closeModal()}
                className="button button--back"
              >
                Back
              </a>
            )}
          >
            <div className="fieldset">
              If you cannot find your healthcare provider, please&nbsp;
              <a
                href={"/healthcare_providers/new"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.closeModal}
              >
                add a healthcare provider
              </a>
              &nbsp;first
            </div>
            <HcpsTable
              order={order}
              update={this.handleSelect}
              currentUser={currentUser}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  handleSelect = hcp => {
    this.props.setHcp(hcp);
    this.closeModal();
  };

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

HcpsModal.propTypes = {
  order: OrderObject.isRequired,
  setHcp: PropTypes.func.isRequired,
  hcpName: PropTypes.string,
  currentUser: PropTypes.object
};

export default HcpsModal;
