import React from "react";

import { OrderObject, FacilityObject } from "./../Queries/OrderQuery";
import FacilityView from "./FacilityView";
import PropTypes from "prop-types";
import FacilityUpdater from "./FacilityUpdater";

class Facility extends React.Component {
  state = { editing: false, facility: null };

  static getDerivedStateFromProps(props, state) {
    // set local copies for editing
    if (state.facility == null) {
      return {
        editing: false,
        facility: props.facility
      };
    }
    return null;
  }

  render() {
    const { order, editable, currentUser } = this.props;
    const { facility } = this.state;
    return (
      <article className="order-facility-area">
        <header className="display_group__header">
          <h2 className="display_group__header__heading">Facility Details</h2>
          {(editable || order.uiState.allowEditWardClient) &&
            order.uiState.currentUserIsHeadOffice && (
              <a
                className="display_group__header__button"
                onClick={this.toggleEdit}
              >
                {this.state.editing ? "Cancel" : "Edit"}
              </a>
            )}
        </header>
        {this.state.editing ? (
          <FacilityUpdater
            facility={facility}
            order={order}
            toggleEdit={this.updateFacility}
          />
        ) : (
          <FacilityView facility={facility} order={order} />
        )}
      </article>
    );
  }

  updateFacility = newFacility => {
    this.setState({ facility: newFacility });
    this.toggleEdit();
  };

  toggleEdit = () => {
    this.setState(prevState => {
      this.props.disableUIFunc(!prevState.editing);
      return { editing: !prevState.editing };
    });
  };
}

Facility.propTypes = {
  facility: FacilityObject.isRequired,
  order: OrderObject.isRequired,
  editable: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default Facility;
