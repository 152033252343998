import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";

const SalesRepSelector = ({ onChange, options }) => (
  <Downshift
    onChange={selection => onChange(selection)}
    itemToString={item => (item ? item.name : "")}
    defaultIsOpen={true}
  >
    {({
      clearSelection,
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      getToggleButtonProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem
    }) => (
      <div className="select-sales-rep">
        <div className="search">
          <input
            {...getInputProps()}
            className="search__input"
            placeholder="Sales rep name"
            type="text"
          />
        </div>

        <div className="editcombo--controller-button--input-wrapper">
          <ul className="list--search list--scroll margin-bottom-base">
            {isOpen
              ? options
                  .filter(
                    p =>
                      !inputValue ||
                      p.searchText
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  .map((p, index) => (
                    <li
                      key={p.id}
                      className="list__item"
                      {...getItemProps({
                        index,
                        item: p
                      })}
                    >
                      <div className="list__item__heading">{p.name}</div>
                      <div className="list__item__subheading">
                        {p.gpCode} - {p.territory}
                      </div>
                    </li>
                  ))
              : null}
          </ul>
        </div>
      </div>
    )}
  </Downshift>
);

SalesRepSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default SalesRepSelector;
