import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const MUTATION = gql`
  mutation updateOrderAutoInvoicing(
    $id: ID!
    $input: UpdateOrderAutoInvoicingInput!
  ) {
    updateOrderAutoInvoicing(id: $id, input: $input) {
      order {
        ...OrderFields
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderEventFragments.query}
`;

class AutoInvoicingUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render } = this.props;
    return (
      <Mutation
        mutation={MUTATION}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const updateAutoInvoicing = (order, useAutoInvoicing) => {
            update({
              variables: {
                id: order.id,
                input: { useAutoInvoicing }
              }
            });
          };
          return render({ order, updateAutoInvoicing });
        }}
      </Mutation>
    );
  }

  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };
}

AutoInvoicingUpdater.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default AutoInvoicingUpdater;
