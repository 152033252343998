import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";

class PrintOrderBtn extends React.Component {
  state = { isOpen: false };
  render() {
    return (
      <a
        className={
          "button button--dropdown" + (this.state.isOpen ? " is-open" : "")
        }
        onClick={this.toggle}
      >
        Print
        {this.state.isOpen && (
          <ul className="button__menu">
            {this.props.order.uiState.showPrintPurchaseOrder && (
              <li>
                <a
                  href={this.props.order.purchaseOrderLink}
                  className="button__menu__link"
                >
                  Print PO
                </a>
              </li>
            )}
            {this.props.order.uiState.showPrintDeliveryDocket && (
              <li>
                <a
                  href={this.props.order.deliveryDocketLink}
                  className="button__menu__link"
                >
                  Print Delivery Docket
                </a>
              </li>
            )}
            <li>
              <a onClick={this.printOrder} className="button__menu__link">
                Print Screen
              </a>
            </li>
            {this.props.order.uiState.showPrintOrderForm && (
              <li>
                <a
                  href={this.props.order.orderFormLink}
                  className="button__menu__link"
                >
                  Print Order Form
                </a>
              </li>
            )}
          </ul>
        )}
      </a>
    );
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  printOrder = () => window.print();
}

PrintOrderBtn.propTypes = {
  order: OrderObject
};

export default PrintOrderBtn;
