import React from "react";

import { Mutation } from "react-apollo";

import MUTATION from "./updateCommentMutation";

import CommentMemoView from "./CommentMemoView";
import PropTypes from "prop-types";

class CommentMemoIsRead extends React.Component {
  render() {
    const { data, node } = this.props;

    return (
      <React.Fragment>
        <CommentMemoView {...this.props} />
        <Mutation mutation={MUTATION} onError={this.onError}>
          {(update, { loading, error }) => {
            const updateCommentRead = val => {
              update({
                variables: {
                  id: data.id,
                  input: {
                    isRead: val
                  }
                }
              })
                .then(result => {
                  node.setData(result.data.updateComment.comment);
                })
                .catch(error => {
                  console.log("Error updating comment", error);
                });
            };
            return data.isRead ? (
              <button
                className="button button--small right button--white"
                onClick={() => updateCommentRead(false)}
                style={{ marginBottom: 5 }}
              >
                Mark unread
              </button>
            ) : (
              <button
                className="button button--small right primary"
                onClick={() => updateCommentRead(true)}
                style={{ marginBottom: 5 }}
              >
                Mark read
              </button>
            );
          }}
        </Mutation>
      </React.Fragment>
    );
  }
}

CommentMemoIsRead.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired
};

export default CommentMemoIsRead;
