import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import documentFragments from "./../Queries/graphql/documentFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      order {
        id
        updatedAt
        documents {
          ...DocumentQuery
        }
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${documentFragments.query}
  ${orderEventFragments.query}
`;

class DocumentDeleter extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={DELETE_DOCUMENT}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const deleteDocument = document => {
            update({
              variables: { id: document.id }
            });
          };
          return render({ deleteDocument });
        }}
      </Mutation>
    );
  }
  onError = error => {
    console.log("onError occurred");
    console.log(error);
  };

  onCompleted = data => {
    // handle server side errors
    if (data.updateOrderLine && data.updateOrderLine.errors.length > 0) {
      this.setState({ errors: data.updateOrderLine.errors });
    } else {
      this.setState({ errors: null });
    }
  };
}

DocumentDeleter.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func
};

export default DocumentDeleter;
