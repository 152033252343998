import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../../lib/AgGrid/CmpAgGrid";
import AddRowToOrderBtn from "../OrderDetails/AddRowToOrderBtn";
import { OrderObject } from "../Queries/OrderQuery";
import { isOnDvaContract } from "../BusRules/access";

class ClientsTable extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.clientsQuery}
        responseDataKey="clients"
        localStorageKey="orderClients"
        frameworkComponents={this.frameworkComponents}
      />
    );
  }

  frameworkComponents = {
    actionCellRenderer: row => {
      return <AddRowToOrderBtn row={row.data} update={this.props.update} />;
    }
  };

  defaultColumnDefs = () => {
    return [
      {
        headerName: "Action",
        field: "action",
        pinned: "left",
        width: 80,
        sortable: false,
        filter: false,
        cellRenderer: "actionCellRenderer",
        valueGetter: params => null // value not part of dataset
      },
      {
        headerName: "Client Reference Number",
        field: "reference",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Given Name",
        field: "firstName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Last Name",
        field: "lastName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Phone Number",
        field: "phoneNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Mobile Number",
        field: "mobileNumber",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "address",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "City",
        field: "city",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "State",
        field: "state",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
        }
      },
      {
        headerName: "Postcode",
        field: "postcode",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Gender",
        field: "gender",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Male", "Female"]
        }
      }
    ];
  };

  clientsQuery = () => {
    const contractId = this.props.contractId;
    const epiOnly =
      isOnDvaContract(this.props.order) &&
      this.props.order.sourceSystem === "EPI";
    return gql`
      query Clients($agGridParams: RequestParamInput!) {
        clients(contractId: ${contractId}, activeOnly: true, excludeDrafts: true, agGridParams: $agGridParams, epiOnly: ${epiOnly}) {
            id
            reference
            lastName
            firstName
            gender
            phoneNumber
            mobileNumber
            email
            address
            postcode
            city
            state
        }
      }
    `;
  };
}

ClientsTable.propTypes = {
  contractId: PropTypes.number.isRequired,
  order: OrderObject.isRequired,
  update: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default ClientsTable;
