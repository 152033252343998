import React from "react";

import { OrderObject } from "./../../Queries/OrderQuery";
import AssignCanceler from "./AssignCanceler";

const AssignCancel = ({ order }) => (
  <AssignCanceler
    order={order}
    render={({ cancelAssignment }) => (
      <a
        className="button button--cancel"
        onClick={() => {
          if (window.confirm("cancel this assignment?")) {
            cancelAssignment();
          }
        }}
      >
        Cancel assignment
      </a>
    )}
  />
);

AssignCancel.propTypes = {
  order: OrderObject.isRequired
};

export default AssignCancel;
