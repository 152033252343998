import React from "react";

const StateContext = React.createContext({ isLineEditing: false });

const Consumer = StateContext.Consumer;

class Provider extends React.Component {
  state = { isLineEditing: false };

  startLineEditing = () => this.setState({ isLineEditing: true });
  finishLineEditing = () => this.setState({ isLineEditing: false });

  render() {
    return (
      <StateContext.Provider
        value={{
          ...this.state,
          startLineEditing: this.startLineEditing,
          finishLineEditing: this.finishLineEditing
        }}
      >
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

export default { Provider, Consumer };
