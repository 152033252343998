import React from "react";

import CommentEditDropdown from "Form/CommentEditDropdown";
import EditTextArea from "Form/EditTextArea";

import { OrderObject } from "./../Queries/OrderQuery";

import CommentAdder from "./CommentAdder";

class AddCommentForm extends React.Component {
  state = {
    comment: { visibleTo: null, body: "", notifyAssignedUser: false }
  };

  render() {
    const { order } = this.props;
    const { comment } = this.state;
    return (
      <CommentAdder
        order={order}
        afterSave={() =>
          this.setState({
            comment: { visibleTo: null, body: "", notifyAssignedUser: false }
          })
        }
        render={({ addCommentToOrder, loading }) => {
          const onSubmit = e => {
            const { comment } = this.state;
            e.preventDefault();
            addCommentToOrder({
              visibleTo: comment.visibleTo,
              body: comment.body,
              notifyAssignedUser: comment.notifyAssignedUser
            });
          };

          return (
            <div className="add-comment">
              <form onSubmit={onSubmit}>
                <h4 className="margin-bottom-base">Add a comment</h4>
                <fieldset className="fieldset">
                  <CommentEditDropdown
                    name="visibleTo"
                    onChange={this.handleChange}
                    value={comment.visibleTo}
                    labelText="To"
                    stackedLabel={true}
                    options={this.buildOptions(order)}
                  />
                  {comment.visibleTo === "Everyone" && (
                    <div className="message message--error" role="alert">
                      <p>
                        You are about to create a comment visible to everyone.
                        Are you sure?
                      </p>
                    </div>
                  )}
                  <EditTextArea
                    name="body"
                    onChange={this.handleChange}
                    value={comment.body}
                    stackedLabel={true}
                    labelText="Comments"
                  />
                  {order.uiState.currentUserIsHeadOffice &&
                    comment.visibleTo === "Internal Only" && (
                      <div
                        className="field field--stacked"
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginBottom: 0 }}>
                          Notify assigned user:
                          <input
                            name="notifyAssignedUser"
                            type="checkbox"
                            checked={comment.notifyAssignedUser}
                            onChange={e =>
                              this.handleChange(
                                "notifyAssignedUser",
                                e.target.checked
                              )
                            }
                            style={{ marginLeft: 5 }}
                          />
                        </label>
                      </div>
                    )}
                </fieldset>
                <div className="fieldset__actions">
                  <input
                    type="submit"
                    name="commit"
                    value="Add comment"
                    className="button primary"
                    disabled={
                      !comment.body || !comment.visibleTo || this.state.saving
                    }
                  />
                </div>
                {loading && <div className="order-comment-saving">Saving</div>}
              </form>
            </div>
          );
        }}
      />
    );
  }

  buildOptions = order => {
    var _this = this;
    var options = order.commentsVisibleToOptions;

    return options.reduce(function(ret, option) {
      ret[_this.optionValue(order, option)] = _this.optionDisabled(
        order,
        option
      );
      return ret;
    }, {});
  };

  optionDisabled = (order, option) => {
    if (this.isHealthcareProvider(option) && !this.hasHCProviderEmail(order)) {
      return true;
    } else {
      return false;
    }
  };

  optionValue = (order, option) => {
    if (this.isHealthcareProvider(option) && !this.hasHCProviderEmail(order)) {
      return option + " - (excluding Healthcare Provider, no email provided)";
    } else {
      return option;
    }
  };

  hasHCProviderEmail = order => {
    return !!(order.healthcareProvider && order.healthcareProvider.email);
  };

  isHealthcareProvider = option => {
    return option === "Healthcare Provider" || option === "Everyone";
  };

  handleChange = (field, value) => {
    const comment = { ...this.state.comment, [field]: value };
    this.setState({ comment });
  };
}

AddCommentForm.propTypes = {
  order: OrderObject.isRequired
};

export default AddCommentForm;
