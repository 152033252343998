import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";

import { OrderObject } from "./../../Queries/OrderQuery";
import AssignUpdater from "./AssignUpdater";
import SelectPartner from "./SelectPartner";

class ToAlternativePartner extends React.Component {
  state = { isOpen: false, newPartner: null };

  render() {
    const {
      selections,
      selectedRows,
      order,
      repairLinesSelected,
      homeModLinesSelected,
      clearSelections,
      disableUIFunc
    } = this.props;
    const partner = order.partner;
    const { newPartner } = this.state;

    if (order.uiState && !order.uiState.showAssignment) {
      return null;
    }

    return (
      <React.Fragment>
        <AssignUpdater
          order={order}
          disableUIFunc={disableUIFunc}
          render={({ assignSelections }) => {
            const assign = () => {
              const { newPartner } = this.state;
              assignSelections(
                newPartner.id,
                repairLinesSelected,
                homeModLinesSelected,
                selections
              );
              clearSelections();
            };
            return (
              <React.Fragment>
                <button
                  className="button primary"
                  onClick={this.toggleModal}
                  disabled={!this.enableAssign()}
                >
                  Assign to Alternative partner
                </button>

                {this.state.isOpen && (
                  <Modal
                    show={this.state.isOpen}
                    onClose={this.toggleModal}
                    header={
                      partner
                        ? "Reassign Items to a Network Partner or Aidacare Office"
                        : "Assign Items to a Network Partner or Aidacare Office"
                    }
                    renderFooter={() => this.renderFooter(assign)}
                  >
                    <div className="margin-bottom-base assign-alternative-partner">
                      <h4 className="modal__body__heading">
                        {partner ? (
                          <span>
                            Items currently assigned to {partner.name}{" "}
                            {partner.formattedAddress}
                          </span>
                        ) : (
                          <span>Items (not assigned)</span>
                        )}
                      </h4>

                      <ul className="list list--compact list--bullet">
                        {selectedRows.map(s => (
                          <li className="list__item" key={s.id}>
                            {s.sku} - {s.description}
                          </li>
                        ))}
                      </ul>

                      <h4 className="modal__body__heading">
                        {partner ? "Reassign" : "Assign"} items
                      </h4>

                      <SelectPartner
                        order={order}
                        complete={this.selectPartner}
                        repairLinesSelected={this.props.repairLinesSelected}
                        homeModLinesSelected={this.props.homeModLinesSelected}
                      />

                      {newPartner && (
                        <div className="list list--search">
                          <div className="list__item is-selected">
                            <div className="list__item__heading">
                              {newPartner.name}
                            </div>
                            <div className="list__item__subheading">
                              {newPartner.formattedAddress}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <p className="margin-none">
                      This action will remove the items from the current order
                      and will generate a new order for the new network partner.
                      You will be able to preview and edit the orders before the
                      action is completed.
                    </p>
                  </Modal>
                )}
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }

  enableAssign = () => {
    const {
      selections,
      order,
      repairLinesSelected,
      homeModLinesSelected,
      multipleQtyHireSelected,
      trialLinesSelected,
      uiActive
    } = this.props;
    return (
      uiActive &&
      selections.length > 0 &&
      (!multipleQtyHireSelected || order.sourceSystem !== "EPI") &&
      !(repairLinesSelected && homeModLinesSelected) &&
      !((repairLinesSelected || homeModLinesSelected) && trialLinesSelected)
    );
  };

  specialOrder = () =>
    this.props.repairLinesSelected || this.props.homeModLinesSelected;

  renderFooter = assign =>
    this.specialOrder() ? (
      <div className="assign-alternative-partner-footer">
        {(this.props.repairLinesSelected ||
          this.props.homeModLinesSelected) && (
          <div className="message message--error" role="alert">
            <p>
              You are about to create a
              {this.props.repairLinesSelected ? " Repair " : " Home Mods "}
              Order. Are you sure?
            </p>
          </div>
        )}
        {this.assignButton(assign)}
      </div>
    ) : (
      this.assignButton(assign)
    );

  assignButton = assign => {
    const { newPartner } = this.state;
    return (
      <button
        className="button primary"
        disabled={newPartner === null}
        onClick={() => {
          assign();
          this.toggleModal();
        }}
      >
        Assign to New Partner {newPartner && `(${newPartner.name})`}
      </button>
    );
  };

  selectPartner = newPartner => {
    this.setState({
      newPartner
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
}

ToAlternativePartner.propTypes = {
  order: OrderObject.isRequired,
  selections: PropTypes.array.isRequired,
  repairLinesSelected: PropTypes.bool.isRequired,
  homeModLinesSelected: PropTypes.bool.isRequired,
  multipleQtyHireSelected: PropTypes.bool.isRequired,
  trialLinesSelected: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  clearSelections: PropTypes.func.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default ToAlternativePartner;
