import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import { OrderObject } from "../Queries/OrderQuery";
import orderFragments from "../Queries/graphql/orderFragments";
import hcpFragments from "../Queries/graphql/hcpFragments";
import orderEventFragments from "../Queries/graphql/orderEventFragments";

const ADD_HCP_TO_ORDER = gql`
  mutation addHcpToOrder($id: ID!, $healthcareProviderId: ID) {
    addHcpToOrder(id: $id, healthcareProviderId: $healthcareProviderId) {
      order {
        ...OrderFields
        healthcareProvider {
          ...HcpQuery
          providerTypeOptions
          stateOptions
        }
        events {
          ...EventQuery
        }
      }
    }
  }
  ${orderFragments.query}
  ${hcpFragments.query}
  ${orderEventFragments.query}
`;

class HcpAdder extends React.Component {
  render() {
    const { order, onComplete, render } = this.props;
    return (
      <Mutation
        mutation={ADD_HCP_TO_ORDER}
        onError={this.onError}
        onCompleted={onComplete}
      >
        {(update, { loading, error }) => {
          const updateFunc = hcp => {
            let hcpId = null;
            if (hcp && hcp.hasOwnProperty("id")) {
              hcpId = hcp.id;
            }
            update({
              variables: {
                id: order.id,
                healthcareProviderId: hcpId
              }
            });
          };
          return render({ update: updateFunc });
        }}
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
  };
}

HcpAdder.propTypes = {
  order: OrderObject.isRequired,
  onComplete: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired
};

export default HcpAdder;
