import React from "react";
import PropTypes from "prop-types";

import { OrderObject, CurrentUserObject } from "./Queries/OrderQuery";

import TabGroup from "Tabs/TabGroup";
import Tab from "Tabs/Tab";

import { showComplexHMTabs } from "./BusRules/access";
import Lines from "./Lines/index";
import Comments from "./Comments/index";
import CommentsCounter from "./Comments/CommentsCounter";
import Documents from "./Documents/index";
import Events from "./Events/index";
import Payments from "./Payments/index";
import PartnerCSMsg from "./RelatedOrderTabs/PartnerCSMsg";
import PricingDiffMsg from "./RelatedOrderTabs/PricingDiffMsg";
import ChecklistIndex from "./Checklist";

const OrderTabs = ({ order, isSuborderDisplay, currentUser, uiActive, disableUIFunc }) => {
  if (order == null) {
    return <div>loading...</div>;
  }
  return (
    <div>
      {order.uiState.currentUserIsHeadOffice && <PartnerCSMsg order={order} />}
      {order.uiState.showPricingDiffMsg && <PricingDiffMsg order={order} />}
      <TabGroup name="orderItemTab" initialTab="lines">
        <Tab
          tabId="lines"
          label="Items in order"
          styleClass="small"
          selected={true}
        >
          <h3 className="print-heading">Items in order</h3>
          <Lines
            order={order}
            isSuborderDisplay={isSuborderDisplay}
            currentUser={currentUser}
            uiActive={uiActive}
            disableUIFunc={disableUIFunc}
          />
        </Tab>
        <Tab
          tabId="comments"
          label={
            <React.Fragment>
              Comments <CommentsCounter order={order} />
            </React.Fragment>
          }
          styleClass="small"
        >
          <h3 className="print-heading">Comments</h3>
          <Comments order={order} currentUser={currentUser} />
        </Tab>
        <Tab tabId="documents" label="Documents" styleClass="small">
          <h3 className="print-heading">Documents</h3>
          <Documents order={order} currentUser={currentUser} />
        </Tab>
        {order.uiState.currentUserIsHeadOffice && (
          <Tab tabId="log" label="Order Log" styleClass="small">
            <h3 className="print-heading">Events</h3>
            <Events order={order} />
          </Tab>
        )}
        {showComplexHMTabs(currentUser, order) && (
          <Tab tabId="payments" label="Payments" styleClass="small">
            <h3 className="print-heading">Payments</h3>
            <Payments order={order} />
          </Tab>
        )}
        {showComplexHMTabs(currentUser, order) && (
          <Tab tabId="checklist" label="Checklist" styleClass="small">
            <h3 className="print-heading">Checklist</h3>
            <ChecklistIndex order={order} />
          </Tab>
        )}
      </TabGroup>
    </div>
  );
};

OrderTabs.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject.isRequired,
  isSuborderDisplay: PropTypes.bool.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default OrderTabs;
