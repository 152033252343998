import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../../lib/AgGrid/GridPropsQuery";

const ContractUsersApp = props => {
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        render={({ contractCodes }) => (
          <section className="contract_users_section">
            <Table
              contractCodes={contractCodes}
              forItAdmin={props.forItAdmin}
            />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default ContractUsersApp;
