import React from "react";
import { OrderObject } from "./Queries/OrderQuery";

const OrderStepBar = ({ heading }) => {
  return <h2 className="order_step_bar">{heading}</h2>;
};

OrderStepBar.propTypes = {
  order: OrderObject
};

export default OrderStepBar;
