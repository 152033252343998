import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import OrderContext from "./../OrderContext";

class ReassignLinesBtn extends React.Component {
  render() {
    const { order, uiActive } = this.props;
    return (
      <OrderContext.Consumer>
        {({ isLineEditing }) => (
          <button
            className="button primary"
            onClick={this.toggleReassign}
            disabled={
              !uiActive ||
              !order.uiState.allowCompleteReassignLines ||
              isLineEditing
            }
          >
            {order.inReassign ? "Complete Reassignment" : "Reassign Partner"}
          </button>
        )}
      </OrderContext.Consumer>
    );
  }

  toggleReassign = () => {
    const { order } = this.props;
    this.props.updateOrder({
      id: order.id,
      inReassign: !order.inReassign
    });
  };
}

ReassignLinesBtn.propTypes = {
  order: OrderObject,
  updateOrder: PropTypes.func.isRequired,
  uiActive: PropTypes.bool.isRequired
};

export default ReassignLinesBtn;
