import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";

import Table from "./Table";
import invoiceLinesUpdater from "./ProcessInvoiceLinesUpdater";
import ProcessInvoices from "./ProcessInvoices";
import ProcessingInvoicesWarningMsg from "./ProcessingInvoicesWarningMsg";

class ProcessInvoicePopup extends React.Component {
  render() {
    const { order, invoiceLines } = this.props;
    return (
      <Modal
        show={true}
        header="Invoicing"
        onClose={this.props.close}
        className="invoiceable-lines"
      >
        <div>
          <h2>CMP Order #: {order.cmpOrderNumber}</h2>
          <p className="invoiceable-lines-message">
            The following lines are ready for invoicing
          </p>
          <ProcessingInvoicesWarningMsg />
          <Table
            order={order}
            invoiceLines={invoiceLines}
            close={this.props.close}
            showPartnerRef={true}
            invoiceLinesUpdater={invoiceLinesUpdater}
            processComponent={ProcessInvoices}
          />
        </div>
      </Modal>
    );
  }
}

ProcessInvoicePopup.propTypes = {
  order: OrderObject,
  invoiceLines: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};

export default ProcessInvoicePopup;
