import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import checklistItemFragments from "../Queries/graphql/checklistItemFragments";

const UPDATE_ITEM_STATUS = gql`
  mutation updateChecklistItem($id: ID!, $status: String) {
    updateChecklistItem(id: $id, status: $status) {
      item {
        id
        status
      }
    }
  }
`;

class ChecklistItem extends React.Component {
  state = { status: this.props.item.status };

  render() {
    const { item } = this.props;
    const { status } = this.state;

    return (
      <tr className="table__row checklist__item" key={item.id}>
        <td className="table__cell">{item.number}</td>
        <td className="table__cell">{item.description}</td>
        <td className="table__cell">
          {
            <Mutation
              mutation={UPDATE_ITEM_STATUS}
              onCompleted={this.onCompleted}
            >
              {update => (
                <React.Fragment>
                  <label>
                    <input
                      type="radio"
                      id={item.id + "-complete"}
                      name={item.id + "-status"}
                      value="complete"
                      checked={status === "complete"}
                      onChange={e => this.setStatus(update, e.target.value)}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      id={item.id + "-no"}
                      name={item.id + "-status"}
                      value="no"
                      checked={status === "no"}
                      onChange={e => this.setStatus(update, e.target.value)}
                    />
                    No
                  </label>
                  <label>
                    <input
                      type="radio"
                      id={item.id + "-na"}
                      name={item.id + "-status"}
                      value="na"
                      checked={status === "na"}
                      onChange={e => this.setStatus(update, e.target.value)}
                    />
                    N/A
                  </label>
                </React.Fragment>
              )}
            </Mutation>
          }
        </td>
      </tr>
    );
  }

  setStatus = (update, status) => {
    update({
      variables: {
        id: this.props.item.id,
        status: status
      }
    });
  };

  onCompleted = data => {
    this.setState({ status: data.updateChecklistItem.item.status });
  };
}

ChecklistItem.propTypes = {
  item: checklistItemFragments.object.isRequired
};

export default ChecklistItem;
