import React from "react";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import userFragments from "./Queries/userFragments";
import PropTypes from "prop-types";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import { boolFieldYesNo } from "../Orders/BusRules/orders";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.userQuery}
        filterButtons={this.filterButtons()}
        responseDataKey="users"
      />
    );
  }

  filterButtons = () => {
    if (this.props.currentUser.itAdmin) {
      return [
        {
          buttonText: "Head Office Users",
          filterModel: {
            aidacareHo: {
              filterType: "set",
              values: ["Yes"]
            }
          }
        },
        {
          buttonText: "Network Partner Users",
          filterModel: {
            aidacareHo: {
              filterType: "set",
              values: ["No"]
            }
          }
        }
      ];
    }
  };

  defaultColumnDefs = () => {
    let cols = [
      {
        headerName: "First Name",
        field: "firstName",
        type: "cmpLinkColumn",
        cellRenderer: CmpAgGridLink,
        cellRendererParams: params => {
          return {
            link: `/user_accounts/${params.data.id}?tab=details`
          };
        }
      },
      {
        headerName: "Last Name",
        field: "lastName",
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Contracts",
        colId: "contract",
        field: "contracts",
        // Doesn't make sense to sort when there are multiple
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: this.props.contractCodes,
          comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          excelMode: "windows"
        }
      },
      {
        headerName: "Organisation(s)",
        field: "organisationName",
        filter: false,
        sortable: false
      },
      {
        headerName: "Employee Id",
        field: "employeeId",
        filter: "agTextColumnFilter"
      }
    ];
    if (this.props.currentUser.itAdmin) {
      cols.push({
        headerName: "IT Admin",
        field: "itAdmin",
        filter: "agSetColumnFilter",
        filterParams: {
          suppressSelectAll: true,
          suppressMiniFilter: true,
          values: ["Yes", "No"]
        },
        valueGetter: params => boolFieldYesNo((params.data || {}).itAdmin)
      });
    }
    cols.push(
      ...[
        {
          headerName: "Phone #",
          field: "phoneNumber",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Mobile #",
          field: "mobileNumber",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Email",
          field: "email",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["Active", "Inactive"]
          }
        },
        {
          headerName: "Last Sign In",
          field: "formattedLastSignIn",
          // current_sign_in_at shows the most recent sign in
          colId: "currentSignInAt",
          filter: "agDateColumnFilter",
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ["equals", "lessThan", "greaterThan"]
          }
        },
        {
          // Hidden column used by the filters
          headerName: "Head Office User",
          field: "aidacareHo",
          valueGetter: params => null,
          sortable: false,
          hide: true,
          suppressColumnsToolPanel: true,
          filter: "agSetColumnFilter",
          filterParams: {
            suppressMiniFilter: true,
            values: ["Yes", "No"]
          }
        }
      ]
    );
    if (this.props.currentUser.aidacareHo) {
      cols.push({
        headerName: "User To Enter",
        field: "userToEnter",
        filter: "agTextColumnFilter"
      });
    }
    return cols;
  };

  userQuery = () => {
    return gql`
      query Users($agGridParams: RequestParamInput!) {
        users(agGridParams: $agGridParams) {
          ...UserQuery
        }
      }
      ${userFragments.query}
    `;
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default TableView;
