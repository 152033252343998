import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import EditTextField from "../../lib/Form/EditTextField";
import { ClientObject } from "../Queries/OrderQuery";

const ReferenceField = ({ client, contractId, handleChange, errors }) => {
  return (
    <GraphqlWrapper>
      <Query
        query={gqlQuery()}
        variables={{
          id: client.sourceId,
          contractId: contractId,
          reference: client.reference
        }}
      >
        {({ loading, error, data }) => {
          return (
            <React.Fragment>
              {data && data.clientReferenceExists && (
                <div
                  className="message message--error message--compact"
                  role="alert"
                >
                  <p>
                    This client ref no. already exists against another client
                  </p>
                </div>
              )}
              <EditTextField
                name="reference"
                onChange={handleChange}
                value={client.reference}
                labelText="Client Ref. No"
                errors={errors}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </GraphqlWrapper>
  );
};

const gqlQuery = () => {
  return gql`
    query ClientReferenceExists(
      $id: Int!
      $contractId: Int!
      $reference: String
    ) {
      clientReferenceExists(
        id: $id
        contractId: $contractId
        reference: $reference
      )
    }
  `;
};

ReferenceField.propTypes = {
  client: ClientObject.isRequired,
  contractId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default ReferenceField;
