import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../../Queries/OrderQuery";
import Modal from "Util/Modal";
import TabGroup from "Tabs/TabGroup";
import Tab from "Tabs/Tab";

import OrderContext from "./../../OrderContext";

import AddItemsForm from "./AddItemsForm";
import AddNonContractedItem from "./AddNonContractedItem";
import { isOnDvaContract, isNEDOrder } from "../../BusRules/access";

class AddItems extends React.Component {
  state = { isOpen: false };

  render() {
    const {
      order,
      isAddFreight,
      uiActive,
      disableUIFunc,
      currentUser
    } = this.props;
    const debtorSelected = !order.contract.bulkPricing || order.debtor;
    const enabled =
      uiActive &&
      (isAddFreight || order.uiState.editable || order.uiLocked) &&
      debtorSelected;
    const showAddContracted =
      !(isAddFreight && !isOnDvaContract(order)) && !isNEDOrder(order);
    const showAddNonContracted = !isAddFreight || !isOnDvaContract(order);
    const initialTab = showAddContracted ? "contracted" : "non-contracted";

    return (
      <React.Fragment>
        <OrderContext.Consumer>
          {({ isLineEditing }) => (
            <React.Fragment>
              {!enabled && !debtorSelected && (
                <em>
                  <small>Please select a debtor</small>
                </em>
              )}
              <button
                className={`button ${
                  isAddFreight ? "button--white" : "primary"
                }`}
                onClick={this.toggleModal}
                disabled={!enabled || isLineEditing}
              >
                {isAddFreight ? "Add freight" : "Add item"}
              </button>
            </React.Fragment>
          )}
        </OrderContext.Consumer>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.toggleModal}
            header="Add item to order"
          >
            <div className="add-items-model-wrapper">
              <TabGroup name="addItem" initialTab={initialTab}>
                {showAddContracted && (
                  <Tab
                    tabId="contracted"
                    label="Add Contracted Item"
                    styleClass="small"
                    tabBodyClass="contracted"
                    selected={true}
                  >
                    <AddItemsForm
                      currentUser={currentUser}
                      order={order}
                      complete={this.toggleModal}
                      isAddFreight={isAddFreight}
                      disableUIFunc={disableUIFunc}
                    />
                  </Tab>
                )}
                {showAddNonContracted && (
                  <Tab
                    tabId="non-contracted"
                    label="Add Non-Contracted Item"
                    styleClass="small"
                  >
                    <AddNonContractedItem
                      order={order}
                      complete={this.toggleModal}
                      disableUIFunc={disableUIFunc}
                      isAddFreight={isAddFreight}
                      currentUser={currentUser}
                    />
                  </Tab>
                )}
              </TabGroup>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
}

AddItems.propTypes = {
  order: OrderObject.isRequired,
  isAddFreight: PropTypes.bool,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

AddItems.defaultProps = {
  isAddFreight: false
};

export default AddItems;
