import React from "react";
import PropTypes from "prop-types";

import { undeletedLines } from "./../../BusRules/orders";

class AddItemsBtn extends React.Component {
  state = {
    quantity: 1,
    disabled: false,
    isOnOrder: false
  };
  render() {
    const { row } = this.props;

    const ignoreDecimal = e => {
      if (e.key === ".") {
        e.preventDefault();
      }
    };

    const setQty = e => {
      if (e.target.value < 1) {
        e.target.value = Math.abs(e.target.value) || 1;
      }
      this.setState({
        quantity: parseInt(e.target.value)
      });
    };
    return (
      <div className="add-item-action">
        {this.state.isOnOrder ? (
          <span>On Order</span>
        ) : (
          <span className="add-to-order">
            <input
              type="number"
              defaultValue={1}
              onChange={e => setQty(e)}
              onKeyDown={e => ignoreDecimal(e)}
              min={1}
            />
            <button
              className="button button--tiny button--white"
              onClick={this.addItem}
              disabled={this.state.disabled || row.actionDisabled}
              id={`add-sku-${row.sku}`}
            >
              Add to order
            </button>
          </span>
        )}
      </div>
    );
  }

  componentDidMount() {
    const { row, order } = this.props;
    const lineOnOrder = undeletedLines(order).find(
      o =>
        !["HIRE", "RECYCLED"].includes(o.saleType) &&
        o.sku === row.sku &&
        o.saleType === row.saleType
    );
    if (lineOnOrder) {
      this.setState({ isOnOrder: true });
    }
  }

  addItem = e => {
    const { row, update, order } = this.props;
    const { quantity } = this.state;
    const sku = row.sku;
    const saleType = row.saleType;
    const sellUnitPriceExc = row.sellUnitPriceExc;
    const buyUnitPriceExc = row.buyUnitPriceExc;
    const manualPrice = row.manualPrice || false;
    this.setState({ disabled: true, isOnOrder: true });
    update({
      variables: {
        id: order.id,
        input: {
          sku: sku,
          saleType: saleType,
          quantity: quantity,
          sellUnitPriceExc: sellUnitPriceExc,
          buyUnitPriceExc: buyUnitPriceExc,
          manualPrice: manualPrice
        }
      }
    });
  };
}

AddItemsBtn.propTypes = {
  order: PropTypes.object,
  row: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

export default AddItemsBtn;
