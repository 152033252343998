import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";
import OrderContext from "./../OrderContext";

import ProcessOrderLines from "./ProcessOrderLines";

const options = [
  {
    key: "deliverable",
    buttonLabel: "Mark Delivered",
    headerText: "Mark Delivered",
    subheaderText: "Please select lines delivered",
    actionButtonText: "Mark Delivered",
    condition: "showProcessDelivered",
    queryField: "deliverable",
    status: "delivered",
    fields: ["deliveredOn"],
    requiredFields: ["deliveredOn"],
    disableActionButton: (selections, orderLines) => {
      const remainingLines = orderLines.filter(
        line => !selections.includes(line.id)
      );
      if (remainingLines.length === 0) {
        return false;
      }
      return remainingLines.every(line => line.saleType === "FREIGHT");
    }
  },
  {
    key: "pickupable",
    buttonLabel: "Pickup Request",
    headerText: "Pickup Request",
    subheaderText:
      "Please select lines to mark for pick up and enter pick up instructions",
    actionButtonText: "Process Pickup Request",
    condition: "showProcessPickupRequested",
    queryField: "pickupable",
    status: "pickup_request",
    fields: ["pickupInstructions"],
    requiredFields: ["pickupInstructions"]
  },
  {
    key: "rollbackable",
    buttonLabel: "Rollback to On Hire",
    headerText: "Rollback to On Hire",
    subheaderText: "Please select lines to rollback to on hire",
    actionButtonText: "Rollback",
    condition: "showRollbackPickupRequest",
    queryField: "rollbackable",
    status: "on_hire",
    fields: [],
    requiredFields: []
  },
  {
    key: "returnable",
    buttonLabel: "Mark Returned",
    headerText: "Mark Returned",
    subheaderText: "Please select lines to mark as returned",
    actionButtonText: "Mark Returned",
    condition: "showProcessReturned",
    queryField: "returnable",
    status: "returned",
    fields: ["pickedUpOn"],
    requiredFields: ["pickedUpOn"]
  }
];

class OrderLevelProcessBtns extends React.Component {
  render() {
    const { order, disableUIFunc, uiActive } = this.props;

    return (
      <OrderContext.Consumer>
        {({ isLineEditing }) =>
          options.map(option => (
            <ConditionalButton
              key={option.key}
              disabled={isLineEditing || !uiActive}
              order={order}
              option={option}
              disableUIFunc={disableUIFunc}
            />
          ))
        }
      </OrderContext.Consumer>
    );
  }
}

class ConditionalButton extends React.Component {
  state = { isOpen: false };
  render() {
    const { order, option, disabled } = this.props;
    const { isOpen } = this.state;

    if (order.uiState[option.condition]) {
      return (
        <React.Fragment>
          <button
            className="button button--white"
            disabled={disabled}
            onClick={this.toggleOpen}
          >
            {option.buttonLabel}
          </button>
          {isOpen && (
            <ProcessOrderLines {...this.props} toggleOpen={this.toggleOpen} />
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}

ConditionalButton.propTypes = {
  order: OrderObject,
  disabled: PropTypes.bool,
  option: PropTypes.object.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

OrderLevelProcessBtns.propTypes = {
  order: OrderObject,
  disableUIFunc: PropTypes.func.isRequired,
  uiActive: PropTypes.bool.isRequired
};

export default OrderLevelProcessBtns;
