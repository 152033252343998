import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "./../Queries/OrderQuery";
import PropTypes from "prop-types";
import TrialLinesTable from "./TrialLinesTable";

class UpdateTrialsBtn extends React.Component {
  state = { isOpen: false };

  render() {
    const { order, disableUIFunc } = this.props;
    return (
      <React.Fragment>
        <button className="button button--white" onClick={this.open}>
          Update Trials
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.close}
            header="Update Trials"
            className="trials-modal"
          >
            <TrialLinesTable
              order={order}
              closeModal={this.close}
              disableUIFunc={disableUIFunc}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });
}

UpdateTrialsBtn.propTypes = {
  order: OrderObject,
  disableUIFunc: PropTypes.func.isRequired
};

export default UpdateTrialsBtn;
