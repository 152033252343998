import React from "react";

import { HealthcareProviderObject, OrderObject } from "../Queries/OrderQuery";
import { allowPrivilegedFields, isNonErapDvaOrder } from "../BusRules/access";
import RoField from "Form/RoField";
import RoMemo from "Form/RoMemo";
import PropTypes from "prop-types";
import RoPhoneField from "../../lib/Form/RoPhoneField";

const HcpView = ({ hcp, order, currentUser }) => {
  return (
    <div className={"display-flex"}>
      <fieldset className={"fieldset fieldset--compact"}>
        <RoField value={hcp.firstName} labelText="First Name" />
        <RoField value={hcp.lastName} labelText="Last Name" />
        <RoField value={hcp.ahpraNumber} labelText="AHPRA Number" />
        <RoField value={hcp.groupName} labelText="Group Name" />
        <RoField
          value={hcp.medicareProviderNumber}
          labelText="Medicare Provider Number"
        />
        <RoField value={hcp.providerType} labelText="Provider Type" />
      </fieldset>
      <fieldset className={"fieldset fieldset--compact"}>
        <RoField value={hcp.formattedAddress} labelText="Address" />
        <RoPhoneField value={hcp.phoneNumber} labelText="Phone" />
        <RoPhoneField value={hcp.mobileNumber} labelText="Mobile" />
        <RoPhoneField value={hcp.faxNumber} labelText="Fax" />
        <RoField value={hcp.email} labelText="Email" />
        <RoField
          value={hcp.preferredContactMethod}
          labelText="Preferred Contact Method"
        />
        <RoMemo
          value={hcp.prescriberNotes}
          labelText="Healthcare Provider Note"
        />
        {allowPrivilegedFields(order) && isNonErapDvaOrder(order) && (
          <RoMemo
            value={hcp.cjInstructions}
            labelText="Clinical Justification Instructions"
          />
        )}
      </fieldset>
    </div>
  );
};

HcpView.propTypes = {
  hcp: HealthcareProviderObject.isRequired,
  order: OrderObject.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default HcpView;
