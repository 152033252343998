import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";

const PricingDiffMsg = ({ order }) => {
  if (!order) {
    return null;
  }
  return (
    <div className="message message--success" role="alert">
      <p>
        Price Change Applied - Different Pricing Per Invoice - check toggle on
        order line (small arrow)
      </p>
    </div>
  );
};

PricingDiffMsg.propTypes = {
  order: OrderObject
};

export default PricingDiffMsg;
