import PropTypes from "prop-types";
import gql from "graphql-tag";

import paymentFragments from "./paymentFragments";

const fragments = {
  query: gql`
    fragment InvoiceQuery on Order {
      paymentInvoices {
        id
        invoiceNumber
        payment {
          ...PaymentQuery
        }
      }
    }
    ${paymentFragments.query}
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    invoiceNumber: PropTypes.string,
    payment: paymentFragments.object
  })
};

export default fragments;
