import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";
import { AgGridReact } from "ag-grid-react";

class TableView extends React.Component {
  state = { selections: [] };
  render() {
    const { order, processSelections, orderLines, option } = this.props;

    let unselectable = !order.uiState.editable;
    if (
      option.key === "pickupable" &&
      order.uiState.showProcessPickupRequested
    ) {
      unselectable = false;
    } else if (option.key === "rollbackable") {
      unselectable = !order.uiState.showRollbackPickupRequest;
    } else if (option.key === "shippable") {
      unselectable = !order.uiState.allowProcessShipments;
    }

    return (
      <React.Fragment>
        <div className="ag-theme-balham ag-grid-wrapper auto-height">
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={orderLines}
            columnDefs={this.columnDefs(unselectable)}
            defaultColDef={this.defaultColDef}
            domLayout="autoHeight"
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={this.setSelections}
          />
        </div>
        {processSelections && processSelections(this.state.selections)}
      </React.Fragment>
    );
  }

  columnDefs = unselectable => [
    {
      headerName: "Cat Num",
      field: "sku",
      width: 150,
      checkboxSelection: !unselectable,
      headerCheckboxSelection: !unselectable
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1
    },
    {
      headerName: "Schedule Id",
      field: "rapScheduleCode",
      width: 100
    },
    {
      headerName: "Type",
      field: "saleType",
      width: 100
    },
    {
      headerName: "Qty",
      field: "quantity",
      width: 100
    },
    {
      headerName: "Status",
      field: "status",
      width: 150
    },
    {
      headerName: "Asset ID/Serial #",
      field: "assetDetails",
      width: 150
    }
  ];

  defaultColDef = {
    resizable: false,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  };

  onGridReady = params => {
    this.agGridApi = params.api;
  };

  setSelections = () => {
    const selectedIds = this.agGridApi.getSelectedRows().map(row => row.id);
    this.setState({ selections: selectedIds });
  };
}

TableView.propTypes = {
  order: OrderObject.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  processSelections: PropTypes.func.isRequired,
  orderLines: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired
};

export default TableView;
