import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class ConvertToOrderBtn extends React.Component {
  render() {
    return (
      <React.Fragment>
        <a className="button button--white" onClick={this.confirm}>
          Convert to Order
        </a>
      </React.Fragment>
    );
  }

  confirm = () => {
    if (window.confirm("Convert this quote to an Order?")) {
      this.convertToOrder();
    }
  };

  convertToOrder = () => {
    const { update, order } = this.props;
    update({
      variables: {
        id: order.id
      }
    });
  };
}

ConvertToOrderBtn.propTypes = {
  order: OrderObject.isRequired,
  update: PropTypes.func.isRequired
};

export default ConvertToOrderBtn;
