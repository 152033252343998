import React from "react";
import PropTypes from "prop-types";

import FieldWrapper from "./FieldWrapper";

class EditYesNo extends React.Component {
  render() {
    const {
      name,
      value,
      className,
      labelText,
      stackedLabel,
      labelClass,
      includeBlank,
      errors
    } = this.props;

    let selected = value ? "Yes" : "No";
    if (value === null && includeBlank) {
      selected = "";
    }
    return (
      <FieldWrapper name={name} errors={errors} stackedLabel={stackedLabel}>
        <React.Fragment>
          <label htmlFor={name} className={labelClass}>
            {labelText}
          </label>
          <select
            id={name}
            className={className}
            value={selected}
            onChange={this.callOnChange}
          >
            {includeBlank && <option />}
            <option>Yes</option>
            <option>No</option>
          </select>
        </React.Fragment>
      </FieldWrapper>
    );
  }

  callOnChange = e => {
    const { name, onChange } = this.props;
    let returnVal = null;
    if (e.target.value === "Yes") {
      returnVal = true;
    } else if (e.target.value === "No") {
      returnVal = false;
    }
    onChange(name, returnVal);
  };
}

EditYesNo.defaultProps = {
  stackedLabel: false
};

EditYesNo.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  stackedLabel: PropTypes.bool,
  labelClass: PropTypes.string,
  includeBlank: PropTypes.bool,
  errors: PropTypes.array
};

export default EditYesNo;
