import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";

const gridPropsFields = [
  {
    dataKey: "staffMembers",
    gqlQueryString: `
      staffMembers {
        id
        name
        contractIds
      }`
  }
];

const ClientOrdersApp = () => {
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        additionalFields={gridPropsFields}
        render={({ contractCodes, currentUser, staffMembers }) => (
          <section className="client_section">
            <Table
              contractCodes={contractCodes}
              staffMembers={staffMembers}
              currentUser={currentUser}
            />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default ClientOrdersApp;
