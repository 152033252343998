import PropTypes from "prop-types";
import gql from "graphql-tag";
import checklistItemFragments from "./checklistItemFragments";

const fragments = {
  query: gql`
    fragment ChecklistSectionQuery on ChecklistSection {
      id
      number
      header
      footer
      items {
        ...ChecklistItemQuery
      }
    }
    ${checklistItemFragments.query}
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    header: PropTypes.string,
    footer: PropTypes.string,
    items: PropTypes.arrayOf(checklistItemFragments.object)
  })
};

export default fragments;
