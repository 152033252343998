import React from "react";
import PropTypes from "prop-types";

import { OrderObject, FacilityObject } from "../Queries/OrderQuery";

import { isAgedCareOrder, isHospitalWardOrder } from "../BusRules/orders";
import ErrorDisplay from "Form/ErrorDisplay";
import EditTextArea from "Form/EditTextArea";
import FacilityViewFields from "./FacilityViewFields";
import FacilitiesQuery from "./FacilitiesQuery";
import EditDropdown from "../../lib/Form/EditDropdown";
import EditTextField from "../../lib/Form/EditTextField";
import RoField from "../../lib/Form/RoField";

class FacilityForm extends React.Component {
  state = { facility: null, order: null };

  static getDerivedStateFromProps(props, state) {
    // set local copies for editing
    if (state.facility == null && state.order == null) {
      return {
        facility: props.facility,
        order: props.order
      };
    }
    return null;
  }

  render() {
    const { facility, order } = this.state;
    const { errors } = this.props;

    return (
      <FacilitiesQuery
        contractId={order.contract.id}
        render={({ facilities }) => (
          <form onSubmit={this.onSubmit} className={"form"}>
            <ErrorDisplay errors={errors} modelDesc="facility" />
            <div className={"display-flex"}>
              <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
                {order.uiState.allowEditFacility && (
                  <EditDropdown
                    labelText="Facility"
                    onChange={this.handleChange}
                    name="sourceId"
                    value={facility.sourceId}
                    options={facilities.map(f => ({
                      value: f.id,
                      text: f.name + " - " + (f.debtor ? f.debtor : "N/A")
                    }))}
                    required={true}
                    selectCssClass="fullwidth"
                    placeholder="Please select"
                  />
                )}
                {!order.uiState.allowEditFacility && (
                  <FacilityViewFields facility={facility} />
                )}
                {!order.includesRepair && (
                  <EditTextArea
                    name="deliveryInstructions"
                    onChange={this.handleChange}
                    value={order.deliveryInstructions}
                    labelText="Delivery Instructions"
                    errors={errors}
                  />
                )}
                {isAgedCareOrder(order) &&
                  order.manualOrder &&
                  order.uiState.allowEditFacility && (
                    <React.Fragment>
                      <EditTextField
                        onChange={this.handleChange}
                        name="requesterFirstName"
                        value={order.requesterFirstName}
                        labelText="Requester First Name"
                        required={true}
                        errors={errors}
                      />
                      <EditTextField
                        onChange={this.handleChange}
                        name="requesterLastName"
                        value={order.requesterLastName}
                        labelText="Requester Last Name"
                        required={true}
                        errors={errors}
                      />
                    </React.Fragment>
                  )}
                {isAgedCareOrder(order) &&
                  order.manualOrder &&
                  !order.uiState.allowEditFacility && (
                    <React.Fragment>
                      <RoField
                        labelText="Requester First Name"
                        value={order.requesterFirstName}
                      />
                      <RoField
                        labelText="Requester Last Name"
                        value={order.requesterLastName}
                      />
                    </React.Fragment>
                  )}
                {isHospitalWardOrder(order) &&
                  order.uiState.allowEditWardClient && (
                    <React.Fragment>
                      <EditTextField
                        onChange={this.handleChange}
                        name="wardClientName"
                        value={order.wardClientName}
                        labelText="Client Name"
                        errors={errors}
                      />
                      <EditTextField
                        onChange={this.handleChange}
                        name="wardClientReference"
                        value={order.wardClientReference}
                        labelText="Client Reference"
                        errors={errors}
                      />
                      <EditTextField
                        onChange={this.handleChange}
                        name="wardClientRoom"
                        value={order.wardClientRoom}
                        labelText="Client Room"
                        errors={errors}
                      />
                    </React.Fragment>
                  )}
                {isHospitalWardOrder(order) &&
                  !order.uiState.allowEditWardClient && (
                    <React.Fragment>
                      <RoField
                        labelText="Client Name"
                        value={order.wardClientName}
                      />
                      <RoField
                        labelText="Client Reference"
                        value={order.wardClientReference}
                      />
                      <RoField
                        labelText="Client Room"
                        value={order.wardClientRoom}
                      />
                    </React.Fragment>
                  )}
              </fieldset>
            </div>
            <div className="form__actions">
              <input
                onClick={this.save}
                type="submit"
                name="commit"
                value="Update"
                className="button primary"
                disabled={this.submitDisabled()}
              />
            </div>
          </form>
        )}
      />
    );
  }

  submitDisabled = () => {
    const { order } = this.state;
    if (
      order.uiState.allowEditFacility &&
      isAgedCareOrder(order) &&
      order.manualOrder
    ) {
      return !(order.requesterFirstName && order.requesterLastName);
    }
    return false;
  };

  onSubmit = e => {
    const { facility, order } = this.state;
    e.preventDefault();

    // Only the order is updated so this could be sent to updateOrder but we may
    // need to update the facility in the future so leaving it like this for now
    this.props.update({
      variables: {
        id: facility.id,
        input: {
          orderId: order.id,
          order: {
            deliveryInstructions: order.deliveryInstructions,
            facilityId: parseInt(facility.sourceId),
            requesterFirstName: order.requesterFirstName,
            requesterLastName: order.requesterLastName,
            wardClientName: order.wardClientName,
            wardClientReference: order.wardClientReference,
            wardClientRoom: order.wardClientRoom
          }
        }
      }
    });
  };

  handleChange = (field, value) => {
    let order = { ...this.state.order };
    if (
      [
        "deliveryInstructions",
        "requesterFirstName",
        "requesterLastName",
        "wardClientName",
        "wardClientReference",
        "wardClientRoom"
      ].includes(field)
    ) {
      order = { ...this.state.order, [field]: value };
    }
    const facility = { ...this.state.facility, [field]: value };
    this.setState({ facility, order });
  };
}

FacilityForm.propTypes = {
  facility: FacilityObject.isRequired,
  order: OrderObject.isRequired,
  update: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default FacilityForm;
