import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "../Queries/OrderQuery";
import CreatePaymentPopup from "./CreatePaymentPopup";

class CreatePaymentBtns extends React.Component {
  state = { isOpen: false, payment1PopupOpen: false, payment2PopupOpen: false };

  render() {
    const { order } = this.props;
    const payment1Description =
      (order.contract.paymentSkuDescription1 || "Payment 1") + " - 25%";
    const payment2Description =
      (order.contract.paymentSkuDescription2 || "Payment 2") + " - 65%";
    const greenCheck = (
      <div className="tab__icon tab-indicator is-done small">
        <svg className="tab-indicator__icon" viewBox="0 0 100 100">
          <g>
            <path d="M87.8978532,14.004077 C85.3478411,14.0783947 82.9268403,15.1421678 81.1473944,16.9701877 C65.6095892,32.5415734 51.4234958,47.8100639 36.5023144,63.0471177 L18.9613494,48.9837437 C16.2208086,46.7908815 12.5185208,46.2267681 9.2492574,47.5039233 C5.97999401,48.7810784 3.64049991,51.7054461 3.11213806,55.1753225 C2.58377622,58.6451989 3.94682804,62.1333572 6.68778789,64.3256956 L31.2349109,83.963394 C35.1551708,87.0861193 40.7980128,86.7554488 44.3267098,83.1962145 C61.9843228,65.5006845 77.8036016,48.1203395 95.0574307,30.8290024 C97.941163,27.9994029 98.7975518,23.6923661 97.2156166,19.9748365 C95.6336814,16.2573069 91.9363038,13.8880525 87.8978532,14.004077 Z" />
          </g>
        </svg>
      </div>
    );
    return (
      <React.Fragment>
        <button
          onClick={this.togglePayment1Popup}
          className="button button--white"
          disabled={!order.uiState.allowProcessPayment1}
        >
          Process Builder Deposit (25%)
          {order.uiState.payment1Processed && greenCheck}
        </button>
        {this.state.payment1PopupOpen && (
          <CreatePaymentPopup
            order={order}
            number="one"
            description={payment1Description}
            close={this.togglePayment1Popup}
            update={this.props.update}
          />
        )}

        <button
          onClick={this.togglePayment2Popup}
          className="button button--white"
          disabled={!order.uiState.allowProcessPracticalCompletion}
        >
          Practical completion (65%) & DVA Invoice
          {order.uiState.payment2Processed && greenCheck}
        </button>
        {this.state.payment2PopupOpen && (
          <CreatePaymentPopup
            order={order}
            number="two"
            description={payment2Description}
            close={this.togglePayment2Popup}
            update={this.props.update}
            includeDate={true}
          />
        )}
      </React.Fragment>
    );
  }

  togglePayment1Popup = () => {
    this.setState(prevState => ({
      payment1PopupOpen: !prevState.payment1PopupOpen
    }));
  };

  togglePayment2Popup = () => {
    this.setState(prevState => ({
      payment2PopupOpen: !prevState.payment2PopupOpen
    }));
  };
}

CreatePaymentBtns.propTypes = {
  order: OrderObject.isRequired,
  update: PropTypes.func.isRequired
};

export default CreatePaymentBtns;
