import React from "react";

import { OrderObject, CurrentUserObject } from "../Queries/OrderQuery";
import ProcessLinesQuery from "../OrderLevelProcessBtns/ProcessLinesQuery";
import ShipmentsModal from "./ShipmentsModal";
import PropTypes from "prop-types";

class ShipmentsButton extends React.Component {
  state = { isOpen: false };

  render() {
    const { order } = this.props;

    return (
      <React.Fragment>
        <button onClick={this.toggleOpen} className="button button--white">
          Shipments
        </button>
        {this.state.isOpen && (
          <ProcessLinesQuery
            orderId={order.id}
            queryField="shippable"
            render={({ orderLines }) =>
              orderLines && (
                <ShipmentsModal
                  {...this.props}
                  show={this.state.isOpen}
                  orderLines={orderLines}
                  toggleOpen={this.toggleOpen}
                  awaitingCallPending={this.props.awaitingCallPending}
                />
              )
            }
          />
        )}
      </React.Fragment>
    );
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}

ShipmentsButton.propTypes = {
  order: OrderObject.isRequired,
  awaitingCallPending: PropTypes.bool.isRequired,
  currentUser: CurrentUserObject.isRequired
};

export default ShipmentsButton;
