import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import OrderQuery from "./Queries/OrderQuery";
import OrderUpdater from "./OrderUpdater";

import OrderList from "./List/index";
import OrderDisplay from "./OrderDisplay";

const OrdersApp = props => {
  return (
    <GraphqlWrapper>
      <Router>
        <Switch>
          <React.Fragment>
            <Route exact path="/" component={OrderList} />
            <Route exact path="/orders" render={() => <Redirect to="/" />} />
            <Route path="/orders/:id" component={Show} />
          </React.Fragment>
        </Switch>
      </Router>
    </GraphqlWrapper>
  );
};

export default OrdersApp;

const Show = ({ match }) => (
  <OrderQuery
    orderId={match.params.id}
    render={({ order, currentUser, salesReps }) => (
      <OrderUpdater
        currentUser={currentUser}
        order={order}
        render={props => <OrderDisplay {...props} salesReps={salesReps} />}
      />
    )}
  />
);
