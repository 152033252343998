import React from "react";
import { OrderObject } from "./../Queries/OrderQuery";

import TableView from "./TableView";

class EventIndex extends React.Component {
  render() {
    const { order } = this.props;
    return <TableView events={order.events} />;
  }
}
EventIndex.propTypes = {
  order: OrderObject
};

export default EventIndex;
