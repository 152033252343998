import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment FacilityQuery on Facility {
      id
      name
      phone
      address
      city
      postcode
      state
      organisationId
      provisional
      sourceId
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    organisationId: PropTypes.string,
    provisional: PropTypes.bool,
    sourceId: PropTypes.number
  })
};

export default fragments;
