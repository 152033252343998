import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";

import Table from "./Table";
import { contractIdFromPath } from "../lib/Util/url";

const ScheduleItemsApp = props => {
  const contractId = contractIdFromPath();
  const gridPropsFields = [
    {
      dataKey: "contract",
      gqlQueryString: `
        contract(id: ${contractId}) {
          id
          contractCode
          showContractPricing
          showNpPricing
          omniId
        }`
    }
  ];

  return (
    <GraphqlWrapper>
      <GridPropsQuery
        additionalFields={gridPropsFields}
        render={({ contract, currentUser }) => (
          <section className="schedule_item_section">
            <Table contract={contract} currentUser={currentUser} />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default ScheduleItemsApp;
