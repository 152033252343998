import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";

import { OrderObject } from "./../../Queries/OrderQuery";
import AssignUpdater from "./AssignUpdater";
import { isNonErapHomeMods } from "../../BusRules/access";

class ToPreferedPartner extends React.Component {
  state = { isOpen: false };

  render() {
    const {
      selections,
      order,
      partner,
      repairLinesSelected,
      homeModLinesSelected,
      clearSelections,
      disableUIFunc,
      buttonText
    } = this.props;

    if (order.uiState && !order.uiState.showAssignment) {
      return null;
    }

    return (
      <React.Fragment>
        <AssignUpdater
          disableUIFunc={disableUIFunc}
          order={order}
          render={({ assignSelections }) => {
            const assign = () => {
              assignSelections(
                partner.id,
                repairLinesSelected,
                homeModLinesSelected,
                selections
              );
              clearSelections();
            };
            return (
              <React.Fragment>
                <button
                  className="button primary"
                  onClick={() =>
                    this.showModal() ? this.toggleModal() : assign()
                  }
                  disabled={!this.enableAssignToPartnerButton()}
                >
                  {buttonText}
                </button>

                {this.state.isOpen && (
                  <Modal
                    show={this.state.isOpen}
                    onClose={this.toggleModal}
                    header={`Assign Items to ${partner.name}`}
                    renderFooter={() => this.assignButton(assign)}
                  >
                    <div className="margin-bottom-base assign-alternative-partner">
                      <p>
                        This action will remove the items from the current order
                        and will generate a new order for {partner.name}. You
                        will be able to preview and edit the orders before the
                        action is completed.
                      </p>

                      <hr />
                      {(repairLinesSelected || homeModLinesSelected) && (
                        <div className="message message--error" role="alert">
                          <p>
                            You are about to create a
                            {repairLinesSelected ? " Repair " : " Home Mods "}
                            Order. Are you sure?
                          </p>
                        </div>
                      )}
                    </div>
                  </Modal>
                )}
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }
  showModal = () =>
    this.props.repairLinesSelected || this.props.homeModLinesSelected;

  enableAssignToPartnerButton = () => {
    const {
      selections,
      order,
      partner,
      partnerType,
      repairLinesSelected,
      homeModLinesSelected,
      multipleQtyHireSelected,
      trialLinesSelected,
      uiActive
    } = this.props;
    if (uiActive && partner && partner.isActive && selections.length > 0) {
      if (multipleQtyHireSelected && order.sourceSystem === "EPI") {
        return false;
      }
      if (isNonErapHomeMods(order) && partnerType === "supply") {
        return !homeModLinesSelected;
      } else if (partner.isInternal) {
        // Can't assign repair or complex home mods to internal
        return !(
          repairLinesSelected ||
          (homeModLinesSelected && order.complexHomeMods)
        );
      } else if (
        (homeModLinesSelected || repairLinesSelected) &&
        trialLinesSelected
      ) {
        return false;
      } else {
        // Can't assign both repair and homeMod at the same time
        return !(repairLinesSelected && homeModLinesSelected);
      }
    }
    return false;
  };

  assignButton = assign => {
    const { buttonText } = this.props;
    return (
      <button
        className="button primary"
        onClick={() => {
          assign();
          this.toggleModal();
        }}
      >
        {buttonText}
      </button>
    );
  };

  setIsHomeMod = val => this.setState({ isHomeMod: val });

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
}

ToPreferedPartner.propTypes = {
  order: OrderObject.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isActive: PropTypes.bool,
    isInternal: PropTypes.bool
  }),
  partnerType: PropTypes.string,
  selections: PropTypes.array.isRequired,
  repairLinesSelected: PropTypes.bool.isRequired,
  homeModLinesSelected: PropTypes.bool.isRequired,
  multipleQtyHireSelected: PropTypes.bool.isRequired,
  trialLinesSelected: PropTypes.bool.isRequired,
  clearSelections: PropTypes.func.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default ToPreferedPartner;
