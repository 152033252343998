import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class CancelBtn extends React.Component {
  render() {
    if (this.props.order.inReassign) {
      return null;
    }
    return (
      <React.Fragment>
        <a className="button red" onClick={this.open}>
          Cancel Order
        </a>
      </React.Fragment>
    );
  }

  open = () => {
    if (window.confirm("cancel this order?")) {
      this.cancelOrder();
    }
  };

  cancelOrder = () => {
    const { update, order } = this.props;
    update({
      variables: {
        id: order.id
      }
    });
  };
}

CancelBtn.propTypes = {
  order: OrderObject,
  update: PropTypes.func.isRequired
};

export default CancelBtn;
