import React from "react";
import PropTypes from "prop-types";

import Modal from "../../lib/Util/Modal";
import { OrderObject } from "../Queries/OrderQuery";
import EditTextField from "../../lib/Form/EditTextField";
import EditDateField from "../../lib/Form/EditDateField";
import { minDeliveredOnDateAllowed } from "../BusRules/orderLines";

class CreatePaymentPopup extends React.Component {
  state = { partnerReference: "", date: null };

  render() {
    const { order, description, includeDate } = this.props;
    const { partnerReference, date } = this.state;

    return (
      <Modal
        onClose={this.props.close}
        header={description}
        show={true}
        renderFooter={() => (
          <React.Fragment>
            <button onClick={this.props.close} className="button button--back">
              Cancel
            </button>
            <button onClick={this.createPayment} className="button primary">
              {"Process " + description}
            </button>
          </React.Fragment>
        )}
      >
        <div>
          <p>
            Please make sure that you have completed the checklist item in the
            tab below before processing the payment. Please enter Partner Ref if
            available.
          </p>
        </div>
        <div>
          <fieldset className={"fieldset fieldset--fullwidth"}>
            <EditTextField
              name="partnerReference"
              onChange={this.handleChange}
              value={partnerReference}
              labelText="Partner reference:"
            />
            {includeDate && (
              <EditDateField
                name="date"
                onChange={this.handleChange}
                value={date}
                labelText="Delivery date:"
                limit="TodayBack"
                limits={{ startDate: minDeliveredOnDateAllowed(order) }}
              />
            )}
          </fieldset>
        </div>
      </Modal>
    );
  }

  handleChange = (field, value) => this.setState({ [field]: value });

  createPayment = () => {
    const { order, number, close, includeDate } = this.props;
    let variables = {
      id: order.id,
      number: number,
      partnerReference: this.state.partnerReference
    };
    if (includeDate) {
      variables["date"] = this.state.date;
    }
    this.props.update({ variables: variables });
    close();
  };
}

CreatePaymentPopup.defaultProps = {
  includeDate: false
};

CreatePaymentPopup.propTypes = {
  order: OrderObject.isRequired,
  number: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  includeDate: PropTypes.bool
};

export default CreatePaymentPopup;
