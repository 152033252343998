import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import SendToGp from "./SendToGp";
import SyncItemsTable from "./SyncItemsTable";

const DashboardApp = props => (
  <GraphqlWrapper>
    <div>
      <h2>Integration Dashboard</h2>
      <div>
        <SendToGp
          render={({ sendToGp }) => (
            <SyncItemsTable sendToGp={sendToGp} />
          )}
        />
      </div>
    </div>
  </GraphqlWrapper>
);

export default DashboardApp;
