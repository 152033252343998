import React from "react";
import PropTypes from "prop-types";

import MiniSaveWrapper from "Form/MiniSaveWrapper";

class PartnerReferenceFieldEdit extends React.Component {
  state = {
    partnerReference: this.props.invoiceLine.partnerReference || ""
  };
  componentDidMount() {
    this.props.containerRef.addEventListener(
      "keydown",
      this.keyPressHander,
      false
    );
  }
  componentWillUnmount() {
    this.props.containerRef.removeEventListener(
      "keydown",
      this.keyPressHander,
      false
    );
  }
  render() {
    const { partnerReference } = this.state;
    return (
      <div className="order-lines-cell-flush">
        <MiniSaveWrapper save={this.save} close={this.close}>
          <input
            type="text"
            onChange={this.onChange}
            value={partnerReference}
            autoFocus={true}
          />
        </MiniSaveWrapper>
      </div>
    );
  }

  onChange = e => this.setState({ partnerReference: e.target.value });

  close = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.close();
  };

  save = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.updateInvoiceLine(this.props.invoiceLine.id, this.state);
    this.close();
  };

  keyPressHander = event => {
    if (event.key === "Escape") {
      this.close();
    }
    if (event.key === "Enter") {
      this.save();
    }
  };
}

PartnerReferenceFieldEdit.propTypes = {
  invoiceLine: PropTypes.object.isRequired,
  containerRef: PropTypes.object.isRequired,
  updateInvoiceLine: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default PartnerReferenceFieldEdit;
