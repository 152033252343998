import React from "react";
import PropTypes from "prop-types";

import FieldWrapper from "./FieldWrapper";

class EditTextField extends React.Component {
  render() {
    const {
      name,
      onKeyDown,
      value,
      labelText,
      stackedLabel,
      errors,
      required,
      autoFocus,
      className,
      pattern,
      title,
      placeholder,
      min,
      step,
      type
    } = this.props;

    return (
      <FieldWrapper name={name} errors={errors} stackedLabel={stackedLabel}>
        <React.Fragment>
          {labelText && (
            <label htmlFor={name} className={required ? "field--required" : ""}>
              {labelText}
            </label>
          )}
          <input
            id={name}
            type={type || "text"}
            value={value || ""}
            pattern={pattern}
            title={title}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onBlur={this.onBlur}
            onChange={e => this.handleTextChange(e.target.value)}
            onKeyDown={onKeyDown}
            className={className}
            min={min}
            step={step}
          />
        </React.Fragment>
      </FieldWrapper>
    );
  }

  onBlur = e => {
    this.handleTextChange(e.target.value.trim());
  };

  handleTextChange = val => {
    this.props.onChange(this.props.name, val);
  };
}

EditTextField.defaultProps = {
  required: false,
  stackedLabel: false,
  autofocus: false
};

EditTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
  stackedLabel: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  pattern: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  step: PropTypes.string,
  errors: PropTypes.array
};

export default EditTextField;
