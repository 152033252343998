import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";
import commentFragments from "./../Queries/graphql/commentFragments";

const MUTATION = gql`
  mutation updateOrderLines($id: ID!, $input: UpdateOrderLinesInput!) {
    updateOrderLines(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        comments {
          ...CommentQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${commentFragments.query}
`;

class ProcessOrderLinesUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { render } = this.props;
    return (
      <Mutation
        mutation={MUTATION}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const processLines = (orderId, lines, extra) => {
            const orderLines = lines.map(line => this.whitelistedInput(line));
            const input = { orderLines };
            if (extra.comment) {
              input.comment = {
                body: "PICKUP INSTRUCTIONS: " + extra.comment
              };
            }
            update({
              variables: {
                id: orderId,
                input
              }
            });
          };
          return render({ processLines });
        }}
      </Mutation>
    );
  }

  whitelistedInput = line => {
    const input = {};
    const whitelist = [
      "id",
      "status",
      "deliveredOn",
      "pickedUpOn",
      "hireEndOn",
      "trialStatus"
    ];

    whitelist.map(key => {
      if (line[key] !== undefined) {
        input[key] = line[key];
      }
    });
    return input;
  };

  onCompleted = () => {
    this.props.disableUIFunc(false);
  };

  onError = error => {
    this.props.disableUIFunc(false);
    console.log("onError occurred");
    console.log(error);
  };
}

ProcessOrderLinesUpdater.propTypes = {
  render: PropTypes.func,
  disableUIFunc: PropTypes.func.isRequired
};

export default ProcessOrderLinesUpdater;
