import React from "react";

import { OrderObject } from "../../Queries/OrderQuery";
import InventoryModal from "./InventoryModal";

class CheckStock extends React.Component {
  state = { isOpen: false };

  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <button onClick={this.toggleOpen} className="button grey">
          Check Stock Level
        </button>
        {this.state.isOpen && (
          <InventoryModal orderId={order.id} close={this.toggleOpen} />
        )}
      </React.Fragment>
    );
  }

  toggleOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };
}

CheckStock.propTypes = {
  order: OrderObject.isRequired
};

export default CheckStock;
