import React from "react";
import PropTypes from "prop-types";

import {
  OrderObject,
  DocumentObject,
  CurrentUserObject
} from "../Queries/OrderQuery";
import RemoveItems from "./RemoveItems";
import AddItems from "./AddItems/index";
import DownloadAll from "./DownloadAll";
import { isErapOrder } from "../BusRules/access";
import { AgGridReact } from "ag-grid-react";

class TableView extends React.Component {
  state = { selections: [] };
  render() {
    const { currentUser, order, documents } = this.props;

    return (
      <React.Fragment>
        <div className="ag-theme-balham ag-grid-wrapper auto-height">
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={documents}
            columnDefs={this.columnDefs()}
            defaultColDef={this.defaultColDef}
            domLayout="autoHeight"
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={this.setSelections}
          />
        </div>
        <div className="button-group document-buttons">
          <AddItems order={order} currentUser={currentUser} />
          <RemoveItems
            selections={this.state.selections}
            deleteDocument={this.props.deleteDocument}
            clearSelections={this.clearSelectedRows}
          />
          <DownloadAll documents={this.props.documents} />
        </div>
      </React.Fragment>
    );
  }

  onGridReady = params => {
    this.agGridApi = params.api;
  };

  setSelections = () => {
    const selectedIds = this.agGridApi.getSelectedRows().map(row => row.id);
    this.setState({ selections: selectedIds });
  };

  clearSelectedRows = () => {
    this.agGridApi.deselectAll();
  };

  columnDefs = () => {
    let cols = [
      {
        headerName: "Category",
        field: "categoryName",
        width: 250,
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: "Document",
        field: "file",
        flex: 1,
        valueGetter: params => params.data.fileName,
        cellRenderer: params => (
          <a href={params.data.fileUrl}>
            {params.data.fileName} ({params.data.fileSize})
          </a>
        )
      }
    ];
    if (!isErapOrder(this.props.order)) {
      cols.push(
        ...[
          {
            headerName: "Show to therapist",
            field: "syncWithEpi",
            width: 150,
            valueGetter: params => (params.data.syncWithEpi ? "Yes" : "No")
          }
        ]
      );
    }
    cols.push(
      ...[
        {
          headerName: "Added on",
          field: "addedAt",
          width: 150
        },
        {
          headerName: "Added by",
          field: "ownerName",
          width: 250
        }
      ]
    );
    return cols;
  };

  defaultColDef = {
    resizable: false,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  };
}

TableView.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  order: OrderObject.isRequired,
  documents: PropTypes.arrayOf(DocumentObject),
  deleteDocument: PropTypes.func
};

export default TableView;
