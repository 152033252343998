import React from "react";
import PropTypes from "prop-types";

class PresetFilterButton extends React.Component {
  filterModelIsActive(filterModel, currentFilterModel) {
    let active = true;
    const buttonKeys = Object.keys(filterModel);
    const currentFilterKeys = Object.keys(currentFilterModel);

    if (currentFilterKeys.length > 0) {
      for (let key of buttonKeys) {
        if (
          !currentFilterKeys.includes(key) ||
          filterModel[key]["filterType"] !==
            currentFilterModel[key]["filterType"] ||
          filterModel[key]["values"].sort().toString() !==
            currentFilterModel[key]["values"].sort().toString()
        ) {
          active = false;
          break;
        }
      }
    } else {
      active = false;
    }
    return active;
  }

  render() {
    const {
      buttonText,
      onClick,
      buttonFilterModel,
      currentFilterModel,
      count,
      hideOnZeroCount
    } = this.props;

    const active = this.filterModelIsActive(
      buttonFilterModel,
      currentFilterModel
    );

    if (hideOnZeroCount && !count) return null;
    return (
      <button
        className={
          "button button--with-counter " +
          (active ? "primary" : "button--white")
        }
        onClick={onClick}
      >
        {buttonText}
        {typeof count !== "undefined" && (
          <span className="tabs-counter tabs-counter--corner">{count}</span>
        )}
      </button>
    );
  }
}

PresetFilterButton.defaultProps = {
  hideOnZeroCount: false
};

PresetFilterButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonFilterModel: PropTypes.object.isRequired,
  currentFilterModel: PropTypes.object.isRequired,
  hiddenColumn: PropTypes.bool,
  count: PropTypes.number,
  hideOnZeroCount: PropTypes.bool
};

export default PresetFilterButton;
