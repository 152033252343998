import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import HcpForm from "./HcpForm";
import HcpLimitedForm from "./HcpLimitedForm";
import hcpFragments from "./../Queries/graphql/hcpFragments";
import orderEventFragments from "./../Queries/graphql/orderEventFragments";
import { OrderObject } from "../Queries/OrderQuery";

const UPDATE_HCP = gql`
  mutation updateHealthcareProvider(
    $id: ID!
    $input: HealthcareProviderInput!
  ) {
    updateHealthcareProvider(id: $id, input: $input) {
      healthcareProvider {
        ...HcpQuery
      }
      order {
        id
        updatedAt
        uiState {
          enableAssign
          processStep2Message
        }
        events {
          ...EventQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${hcpFragments.query}
  ${orderEventFragments.query}
`;

class HCPUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { hcp, currentUser, order, limitedEditableFields } = this.props;
    return (
      <Mutation
        mutation={UPDATE_HCP}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(updateHcp, { loading, error }) =>
          limitedEditableFields ? (
            <HcpLimitedForm
              hcp={hcp}
              order={order}
              contractId={order.contract.id}
              currentUser={currentUser}
              update={updateHcp}
              errors={this.state.errors}
            />
          ) : (
            <HcpForm
              hcp={hcp}
              order={order}
              currentUser={currentUser}
              contractId={order.contract.id}
              update={updateHcp}
              errors={this.state.errors}
            />
          )
        }
      </Mutation>
    );
  }

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
    if (graphQLErrors) {
      this.setState({ errors: (graphQLErrors[0] || {}).errors });
    }
  };

  onCompleted = data => {
    this.props.toggleEdit();
  };
}

HCPUpdater.propTypes = {
  hcp: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  order: OrderObject.isRequired,
  limitedEditableFields: PropTypes.bool.isRequired
};

export default HCPUpdater;
