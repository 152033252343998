import React from "react";
import PropTypes from "prop-types";

const MiniSaveWrapper = ({ children, save, close }) => (
  <div className="mini-save-wrapper order-lines-cell-flush">
    {children}
    <div className="mini-save-wrapper--icons">
      <a href="#" onClick={close} className="icon icon--cancel icon--tiny" />{" "}
      <a
        href="#"
        onClick={save}
        className="icon icon--save icon--small icon--right"
      />
    </div>
  </div>
);

MiniSaveWrapper.propTypes = {
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default MiniSaveWrapper;
