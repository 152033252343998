import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment CurrentUserQuery on Query {
      currentUser {
        id
        name
        itAdmin
        organisationName
        aidacareHo
        partner
        defaultClientSms
      }
    }
  `
};

export default fragments;
