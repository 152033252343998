import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { AgGridReact } from "ag-grid-react";
import { CurrentUserObject, OrderObject } from "../Queries/OrderQuery";
import {
  includesHireOrRTO,
  isRTOOrder,
  unassignedLines
} from "../BusRules/orders";
import { showRemoveProducts } from "../BusRules/access";
import { anyLinesExpandable, lineStatuses } from "../BusRules/orderLines";

import { getPricingColumns } from "./RowFields/getPricingColumns";
import { getPrevalidationColumns } from "./RowFields/getPrevalidationColumns";
import { getBaseColumns } from "./RowFields/getBaseColumns";
import { getPartnerColumns } from "./RowFields/getPartnerColumns";
import { getFulfillmentColumns } from "./RowFields/getFulfillmentColumns";
import { getInvoicingColumns } from "./RowFields/getInvoicingColumns";
import { getHireEndOnColumn } from "./RowFields/getHireEndOnColumn";

import StartSendingOrdersButton from "./StartSendingOrdersButton";
import FooterButtons from "./FooterButtons";
import ActionCell from "./RowFields/ActionCell";

const Table = ({
  order,
  currentUser,
  saveLines,
  isSuborderDisplay,
  uiActive,
  disableUIFunc
}) => {
  const linesGridRef = useRef();
  const totalsGridRef = useRef();
  const [editingRow, setEditingRow] = useState(null);
  const [editedLineStatus, setEditedLineStatus] = useState();
  const [lines, setLines] = useState();
  const [totals, setTotals] = useState();
  const [autoGroupColumnDef, setAutoGroupColumnDef] = useState({
    headerName: "",
    pinned: "left",
    width: 20,
    resizable: false,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: props => null
    }
  });
  const [defaultColDef, setDefaultColDef] = useState({
    resizable: true,
    sortable: false,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  });

  const [gridApi, setGridApi] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setLines(linesTreeData());
    setTotals(totalsData());
  }, [order.orderLines]);

  useEffect(() => {
    setLinesColumnDefs(columnDefs(showSelectable, false));
    setTotalsColumnDefs(columnDefs(showSelectable, true));
  }, [order, editingRow, editedLineStatus]);

  const editable = order.uiState && order.uiState.editable;
  const showSelectable =
    (editable &&
      showRemoveProducts(currentUser, order) &&
      order.uiState.canEditLines) ||
    order.inReassign;
  const showAssign = order.uiState && order.uiState.stageAssignment;
  const enableSend = order.uiState && order.uiState.stageCanSendToPartner;
  const showSendingButtons =
    showAssign && enableSend && order.orderAllocations.length > 0;

  const linesTreeData = () => {
    let data = [];
    unassignedLines(order).forEach(line => {
      const orderLineLeaf = `orderLine-${line.id}`;
      let lastInvoiceLine = line.invoiceLines
        .filter(l => l.status !== "locked")
        .slice(-1)[0];
      if (lastInvoiceLine === undefined) {
        lastInvoiceLine = {
          invoiceNumber: "N/A",
          invoiceDate: "N/A",
          statusDesc: "N/A",
          partnerReference: "N/A"
        };
      }
      data.push({
        ...line,
        path: [orderLineLeaf],
        invoiceNumber: lastInvoiceLine.invoiceNumber || "-",
        invoiceDate: lastInvoiceLine.invoiceDate || "-",
        statusDesc: lastInvoiceLine.statusDesc || "-",
        partnerReference: lastInvoiceLine.partnerReference || "-"
      });
      if (
        (["HIRE", "RTO"].includes(line.saleType) &&
          line.invoiceLines.length > 0) ||
        line.invoiceLines.length > 1
      ) {
        line.invoiceLines.forEach(invoiceLine => {
          data.push({
            ...invoiceLine,
            path: [orderLineLeaf, `invoiceLine-${invoiceLine.id}`],
            status: null
          });
        });
      }
    });
    return data;
  };

  const totalsData = () => {
    return [
      {
        isTotals: true,
        quantity: order.orderTotals.quantity,
        lineSellUnitPriceExc: order.orderTotals.lineSellUnitPriceExc,
        lineSellUnitPriceInc: order.orderTotals.lineSellUnitPriceInc,
        lineBuyUnitPriceExc: order.orderTotals.lineBuyUnitPriceExc,
        lineBuyUnitPriceInc: order.orderTotals.lineBuyUnitPriceInc
      }
    ];
  };

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setLines(linesTreeData());
    setTotals(totalsData());
  }, []);

  const setSelections = params => {
    setSelectedRows(params.api.getSelectedRows());
  };

  const columnDefs = (showSelectable, isTotals) => {
    const showFulfillment =
      order.uiState && order.uiState.showFulfillment && !isSuborderDisplay;

    let cols = [];
    if (isTotals && anyLinesExpandable(order)) {
      cols.push(autoGroupColumnDef);
    }
    if (showSelectable) {
      cols.push({
        headerName: "",
        field: "checkbox",
        pinned: "left",
        width: 30,
        resizable: false,
        checkboxSelection: !isTotals,
        headerCheckboxSelection: !isTotals
      });
    }
    if (
      showFulfillment &&
      (order.uiState.editable || order.uiState.canEditHireEnd) &&
      order.uiState.canEditLines
    ) {
      cols.push({
        headerName: "",
        field: "action",
        pinned: "left",
        width: editingRow !== null ? 95 : 45,
        cellRenderer: ActionCell,
        cellRendererParams: params => {
          return {
            node: params.node,
            params: params,
            editingRow: editingRow
          };
        }
      });
    }

    cols.push(
      ...getBaseColumns({ order, currentUser, disableUIFunc, saveLine })
    );
    cols.push(...getPricingColumns({ order }));

    if (!showFulfillment) {
      if (isRTOOrder(order)) {
        cols.push({
          headerName: "Weeks to own",
          headerTooltip: "Weeks to own",
          field: "weeksToOwn",
          width: 55
        });
      }
      cols.push({
        headerName: "Status",
        field: "status",
        width: 80
      });
    }

    cols.push(...getPrevalidationColumns({ order, saveLine }));
    cols.push(...getPartnerColumns({ order }));

    if (showFulfillment) {
      cols.push(
        ...getFulfillmentColumns({
          order,
          isEditing: editingRow !== null,
          editedLineStatus,
          setEditedLineStatus
        })
      );
      cols.push(...getInvoicingColumns({ order }));
    } else if (includesHireOrRTO(order)) {
      cols.push(...getHireEndOnColumn({ order, includeHeader: true }));
    }

    return cols;
  };

  const [linesColumnDefs, setLinesColumnDefs] = useState(
    columnDefs(showSelectable, false)
  );
  const [totalsColumnDefs, setTotalsColumnDefs] = useState(
    columnDefs(showSelectable, true)
  );

  const isRowSelectable = useCallback(
    rowNode =>
      rowNode.data.path.length === 1 &&
      lineStatuses.SELECTABLE_LIST.includes(rowNode.data.status),
    []
  );

  const onRowEditingStarted = useCallback(params => {
    disableUIFunc(true);
    setEditingRow(params.rowIndex);
  }, []);

  const onRowValueChanged = useCallback(
    params => {
      // AG Grid doesn't currently have a way to get only the changes made to
      // the row, so we have to generate the changes ourselves
      const newLineValues = params.data;
      const oldLineValues = linesTreeData().find(
        line => line.path.toString() === newLineValues.path.toString()
      );
      let lineChanges = Object.fromEntries(
        Object.entries(newLineValues).filter(
          ([key, val]) => key in oldLineValues && oldLineValues[key] !== val
        )
      );
      lineChanges.id = params.data.id;

      const saved = saveLines(order, [lineChanges]);
      if (saved === false) {
        setLines(linesTreeData());
        disableUIFunc(false);
      }
    },
    [order]
  );

  const onRowEditingStopped = useCallback(params => {
    setEditingRow(null);
    setEditedLineStatus(null);
    disableUIFunc(false);
  }, []);

  const saveLine = useCallback(
    orderLine => {
      saveLines(order, [orderLine]);
    },
    [order]
  );

  const clearSelectedRows = useCallback(() => {
    gridApi.deselectAll();
  }, [gridApi]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "initial"
        }}
        className="ag-theme-balham ag-grid-wrapper"
      >
        <div id={"order-lines-table"} style={{ flex: "1 1 auto" }}>
          <AgGridReact
            ref={linesGridRef}
            alignedGrids={[totalsGridRef]}
            onGridReady={onGridReady}
            rowData={lines}
            treeData={anyLinesExpandable(order)}
            getDataPath={data => data.path}
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={linesColumnDefs}
            defaultColDef={defaultColDef}
            suppressMovableColumns={true}
            reactiveCustomComponents={true}
            rowSelection="multiple"
            domLayout="autoHeight"
            onRowSelected={setSelections}
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            suppressHorizontalScroll={true}
            tooltipInteraction={true}
            tooltipShowDelay={200}
            editType="fullRow"
            onRowEditingStarted={onRowEditingStarted}
            onRowValueChanged={onRowValueChanged}
            onRowEditingStopped={onRowEditingStopped}
          />
        </div>
        <div id={"order-lines-totals"} style={{flex: "none", height: "48px"}}>
          <AgGridReact
            ref={totalsGridRef}
            alignedGrids={[linesGridRef]}
            rowData={totals}
            columnDefs={totalsColumnDefs}
            defaultColDef={defaultColDef}
            headerHeight="0"
            rowStyle={{fontWeight: "bold"}}
          />
        </div>
      </div>
      <div>
        {showSendingButtons && <StartSendingOrdersButton />}
        <div className="order-lines-footer">
          <FooterButtons
            order={order}
            saveLine={saveLine}
            selections={selectedRows.map(row => row.id)}
            clearSelections={clearSelectedRows}
            selectedRows={selectedRows}
            currentUser={currentUser}
            isSuborderDisplay={isSuborderDisplay}
            uiActive={uiActive}
            disableUIFunc={disableUIFunc}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

Table.propTypes = {
  order: OrderObject.isRequired,
  currentUser: CurrentUserObject.isRequired,
  saveLines: PropTypes.func.isRequired,
  isSuborderDisplay: PropTypes.bool.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default Table;
