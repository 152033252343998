import React from "react";
import PropTypes from "prop-types";

class RoField extends React.Component {
  state = { copied: false };

  render() {
    const { value, labelText, clickToCopy } = this.props;
    return (
      <div className={"field"}>
        <label htmlFor={labelText}>{labelText}</label>
        {/* just use text uses less screen height so easier for now */}
        {/* <input type="text" disabled readOnly value={value} id={labelText} /> */}
        <div>
          <span
            className={this.spanClass()}
            onClick={this.onClick}
            title={clickToCopy ? "Click to copy" : ""}
          >
            {value || "-"}
          </span>
        </div>
      </div>
    );
  }

  spanClass = () => {
    const { dataLabelClass, clickToCopy } = this.props;
    let klass = "";
    if (clickToCopy) {
      klass += "click-to-copy";
      if (this.state.copied) {
        klass += " copied";
      }
    }
    if (dataLabelClass) {
      klass += ` label label--${dataLabelClass}`;
    }
    return klass;
  };

  onClick = () => {
    const { value, clickToCopy } = this.props;
    if (clickToCopy) {
      navigator.clipboard.writeText(value).then(() => {
        this.setState({ copied: true });
        setTimeout(() => this.setState({ copied: false }), 1500);
      });
    }
  };
}

RoField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  dataLabelClass: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  clickToCopy: PropTypes.bool
};

export default RoField;
