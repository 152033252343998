import React from "react";
import { OrderObject, CurrentUserObject } from "./../Queries/OrderQuery";

import Table from "./Table";

import AddCommentForm from "./AddCommentForm";

class CommentsIndex extends React.Component {
  render() {
    const { order, currentUser } = this.props;
    return (
      <div>
        <Table comments={order.comments} currentUser={currentUser} />
        <AddCommentForm order={order} />
      </div>
    );
  }
}
CommentsIndex.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject.isRequired
};

export default CommentsIndex;
