import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";
import { showEditOrderDetails } from "./../BusRules/access";
import OrderDetailsView from "./OrderDetailsView";
import OrderDetailsEdit from "./OrderDetailsEdit";
class Details extends React.Component {
  state = { editing: false };

  render() {
    const { order, editable, currentUser, editableAfterGP } = this.props;
    const canEdit = editable || editableAfterGP;
    const limitedEditableFields = editableAfterGP && !editable;

    return (
      <div
        className={
          "order-details-area display_group__item width-one-fifth" +
          (order.urgent ? " is-urgent" : "")
        }
      >
        <header className="display_group__header">
          <h2 className={"display_group__header__heading"}>Order Details</h2>
          {canEdit && showEditOrderDetails(currentUser) && (
            <a
              className={"display_group__header__button"}
              onClick={this.toggleEdit}
            >
              {this.state.editing ? "Cancel" : "Edit"}
            </a>
          )}
        </header>
        {this.state.editing ? (
          <OrderDetailsEdit
            {...this.props}
            toggleEdit={this.toggleEdit}
            limitedEditableFields={limitedEditableFields}
          />
        ) : (
          <OrderDetailsView {...this.props} />
        )}
      </div>
    );
  }

  toggleEdit = e => {
    e && e.preventDefault();
    this.setState(prevState => {
      this.props.disableUIFunc(!prevState.editing);
      return { editing: !prevState.editing };
    });
  };
}

Details.propTypes = {
  order: OrderObject,
  salesReps: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  editableAfterGP: PropTypes.bool.isRequired
};

export default Details;
