import React, { useState, useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { apolloClient } from "../lib/GraphQL/GraphqlWrapper";
import dateFuncs from "Form/dateFuncs";
import ResendButton, { resendableRow } from "./ResendButton";
import CloseButton, { closeableRow } from "./CloseButton";
import DiscardButton, { discardableRow } from "./DiscardButton";
import { AgGridReact } from "ag-grid-react";
import gql from "graphql-tag";

const RELATED_SYNCS_GQL = gql`
  query RelatedSyncItems($id: String!, $flow: String!) {
    relatedSyncItems(id: $id, flow: $flow) {
      id
      lastSyncAt
      lastSyncLink
      lastSyncError
    }
  }
`;

const SYNC_ITEMS_GQL = gql`
  query SyncItems(
    $days: Int!
    $orderId: String
    $resendableOnly: Boolean
    $flow: String
    $agGridParams: RequestParamInput
  ) {
    syncItems(
      days: $days
      orderId: $orderId
      resendableOnly: $resendableOnly
      flow: $flow
      agGridParams: $agGridParams
    ) {
      id
      type
      flow
      contractCode
      orderId
      gpInvoiceNumber
      lastSyncAt
      enqueued
      resendable
      lastSyncError
      numberOfSyncAttempts
      closeable
      syncId
      lastSyncLink
    }
  }
`;

const createServerSideDatasource = () => {
  return {
    getRows: params => {
      const inputs = params.context.inputs;
      if (!validQueryParams(inputs)) {
        params.success({ rowData: [] });
      } else {
        const requestParams = {
          startRow: params.request.startRow,
          endRow: params.request.endRow,
          sortModel: params.request.sortModel
        };
        apolloClient
          .query({
            query: SYNC_ITEMS_GQL,
            variables: {
              days: inputs.days,
              orderId: inputs.orderId,
              resendableOnly: inputs.resendableOnly,
              flow: inputs.flow,
              agGridParams: requestParams
            },
            fetchPolicy: "network-only"
          })
          .then(result => {
            params.success({ rowData: result.data.syncItems });
          })
          .catch(error => {
            console.log("Error fetching sync items", error);
            params.fail();
            // Infinite row model does not use loading overlay so we can repurpose for errors
            params.api.showLoadingOverlay();
          });
      }
    }
  };
};

const validQueryParams = params => params.flow !== "" || params.orderId !== "";

const errorOverlay =
  "<div class='message message--error' role='alert'>There was an error when loading rows</div>";

const SyncItemsTable = ({ sendToGp }) => {
  const gridRef = useRef();
  const [defaultColDef, setDefaultColDef] = useState({
    resizable: true,
    sortable: false,
    suppressHeaderMenuButton: true,
    filter: false,
  });
  const [inputs, setInputs] = useState({
    days: 2,
    orderId: "",
    resendableOnly: true,
    flow: ""
  });

  const updateRow = useCallback((row, data) => {
    row.node.updateData(data);
  }, []);

  const formatLastSyncAt = params => {
    let value = params.value ? dateFuncs.displayDateTime(params.value) : "";
    if (params.data.enqueued) {
      return value + "Is enqueued";
    }
    return value;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      field: "expand",
      width: 30,
      cellRenderer: "agGroupCellRenderer",
      valueGetter: null
    },
    {
      headerName: "Flow",
      field: "flow",
      width: 90
    },
    {
      headerName: "Contract",
      field: "contractCode",
      width: 120
    },
    {
      headerName: "Type",
      field: "type",
      width: 190
    },
    {
      headerName: "CMP Order Id",
      field: "orderId",
      width: 110,
      cellStyle: { textAlign: "center" },
      cellRenderer: params => (
        <a
          href={"/orders/" + params.data.orderId}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </a>
      )
    },
    {
      headerName: "Invoice No",
      field: "gpInvoiceNumber",
      width: 110,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Synced at",
      field: "lastSyncAt",
      width: 150,
      sort: "desc",
      sortable: true,
      valueFormatter: params => formatLastSyncAt(params)
    },
    {
      headerName: "Details",
      field: "lastSyncLink",
      width: 65,
      cellStyle: { textAlign: "center" },
      cellRenderer: params => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          Details
        </a>
      )
    },
    {
      headerName: "Last error",
      field: "lastSyncError",
      flex: 1
    },
    {
      headerName: "Attempts",
      field: "numberOfSyncAttempts",
      width: 90
    },
    {
      headerName: "Action",
      width: 230,
      field: "resendable",
      valueGetter: params => {
        let values = [];
        if (resendableRow(params.data)) {
          values.push("Resend");
        } else {
          values.push("None");
        }
        if (closeableRow(params.data)) values.push("Close");
        if (discardableRow(params.data)) values.push("Discard");
        return values;
      },
      cellRenderer: params => {
        return (
          <React.Fragment key={params.data.id}>
            <ResendButton
              row={params}
              sendToGp={sendToGp}
              onComplete={updateRow}
            />
            <CloseButton row={params} onComplete={updateRow} />
            <DiscardButton row={params} onComplete={updateRow} />
            {!resendableRow(params.data) && <em>none</em>}
          </React.Fragment>
        );
      }
    }
  ]);

  const [detailCellRendererParams, setDetailCellRendererParams] = useState({
    detailGridOptions: {
      columnDefs: [
        {
          headerName: "Synced at",
          field: "lastSyncAt",
          width: 120,
          sort: "desc",
          valueFormatter: params => formatLastSyncAt(params)
        },
        {
          headerName: "Details",
          field: "lastSyncLink",
          width: 80,
          cellStyle: { textAlign: "center" },
          cellRenderer: params => (
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              Details
            </a>
          )
        },
        {
          headerName: "Error",
          field: "lastSyncError",
          flex: 1,
          filter: "agTextColumnFilter"
        }
      ],
      defaultColDef: {
        suppressHeaderMenuButton: true
      }
    },
    getDetailRowData: params => {
      apolloClient
        .query({
          query: RELATED_SYNCS_GQL,
          variables: {
            id: params.data.syncId,
            flow: params.data.flow
          }
        })
        .then(result => {
          params.successCallback(result.data.relatedSyncItems);
        })
        .catch(error => {
          console.log("Error fetching related syncs", error);
          params.successCallback([]);
        });
    }
  });

  const isRowMaster = useCallback(dataItem => {
    return dataItem.numberOfSyncAttempts > 1;
  }, []);

  const onGridReady = useCallback(params => {
    const datasource = createServerSideDatasource();
    params.api.setGridOption("serverSideDatasource", datasource);
  }, []);

  const context = useMemo(() => {
    return {
      inputs: inputs
    };
  }, [inputs]);

  const handleInputChange = (name, value) => {
    setInputs(prevState => ({ ...prevState, [name]: value }));
  };

  const refreshRows = useCallback(() => {
    if (
      inputs.days < 11 ||
      inputs.orderId.length ||
      window.confirm(
        "WARNING: Searching more than 10 days without a CMP Order Id can take a long time and potentially slow down the system for other users."
      )
    ) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [inputs]);

  return (
    <React.Fragment>
      <div className="search-wrappper">
        <div className="search-wrappper-block">
          <label>Number of days to show:</label>
          <input
            type="number"
            min={1}
            value={inputs.days}
            onChange={e => {
              const parsedDays =
                e.target.value === "" ? 0 : parseInt(e.target.value, 10);
              handleInputChange("days", parsedDays);
            }}
          />
          <label style={{ marginLeft: 20 }}>CMP Order Id:</label>
          <input
            type="text"
            value={inputs.orderId}
            onChange={e => handleInputChange("orderId", e.target.value)}
          />
          <label style={{ marginLeft: 20 }}>Flow:</label>
          <select
            value={inputs.flow}
            onChange={e => handleInputChange("flow", e.target.value)}
          >
            <option value="">All</option>
            <option value="CMP-EPI">CMP-EPI</option>
            <option value="CMP-ERAP">CMP-ERAP</option>
            <option value="CMP-GP">CMP-GP</option>
            <option value="CMP-NED">CMP-NED</option>
          </select>
          <label style={{ marginLeft: 20 }}>
            Resend only:
            <input
              name="resendableOnly"
              type="checkbox"
              checked={inputs.resendableOnly}
              onChange={e =>
                handleInputChange("resendableOnly", e.target.checked)
              }
            />
          </label>
        </div>
        <button
          onClick={() => refreshRows()}
          disabled={!validQueryParams(inputs)}
          className="button primary"
          style={{ marginBottom: 20 }}
        >
          Go
        </button>
        {!validQueryParams(inputs) && (
          <div style={{ marginLeft: 3, paddingTop: 10 }}>
            <small>
              <em>Cannot show All flows without entering a CMP Order Id</em>
            </small>
          </div>
        )}
      </div>
      <div className="ag-theme-balham ag-grid-wrapper">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowModelType={"serverSide"}
          context={context}
          blockLoadDebounceMillis={500}
          overlayLoadingTemplate={errorOverlay}
          cacheBlockSize={50}
          maxBlocksInCache={10}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          floatingFiltersHeight={50}
          sortingOrder={["desc", "asc", null]}
          masterDetail={true}
          isRowMaster={isRowMaster}
          detailCellRendererParams={detailCellRendererParams}
        />
      </div>
    </React.Fragment>
  );
};

SyncItemsTable.propTypes = {
  sendToGp: PropTypes.func.isRequired
};

export default SyncItemsTable;
