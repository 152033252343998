import React from "react";
import PropTypes from "prop-types";

const OrderValidationMessage = props => {
  const { children } = props;
  return (
    <React.Fragment>
      <section>
        <section className="detail_section detail_section__padded ">
          {children}
        </section>
      </section>
    </React.Fragment>
  );
};

OrderValidationMessage.propTypes = {
  children: PropTypes.string.isRequired
};

export default OrderValidationMessage;
