import React from "react";
import PropTypes from "prop-types";

import { setSearchParam, getSearchParam } from "./../Util/url";

class TabGroup extends React.Component {
  state = {
    tabId:
      this.props.initialTab ||
      getSearchParam(this.props.name) ||
      this.props.defaultTab
  };

  render() {
    const { children, name } = this.props;
    const { tabId } = this.state;
    return (
      <div className={"panel panel--tabs"}>
        {React.Children.map(
          children,
          child =>
            child &&
            React.cloneElement(child, {
              tabgroup: {
                name,
                selectedTabId: tabId,
                selectTab: this.selectTab
              }
            })
        )}
      </div>
    );
  }

  selectTab = newTab => {
    if (!this.props.initialTab) {
      setSearchParam(this.tabParamName(), newTab);
    }
    this.setState({ tabId: newTab });
    if (this.props.onChange) {
      this.props.onChange(newTab);
    }
  };

  tabParamName = () => this.props.name;
}

TabGroup.propTypes = {
  name: PropTypes.string.isRequired,
  defaultTab: PropTypes.string,
  initialTab: PropTypes.string,
  onChange: PropTypes.func
};

export default TabGroup;
