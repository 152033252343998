import currency from "currency.js";

// just a proxy for currency.js
// incase we want to remove / change our dollar formatting lib

const formatD = val =>
  typeof val === "number"
    ? currency(val, { formatWithSymbol: true }).format()
    : "";

export { formatD };
