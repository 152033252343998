import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const SelectSalesRepsQuery = ({ render }) => {
  return (
    <Query query={QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;
        return render({
          salesReps: data.salesReps
        });
      }}
    </Query>
  );
};

const QUERY = gql`
  query SalesReps {
    salesReps {
      id
      gpCode
      status
      firstName
      lastName
      name
      territory
      searchText
    }
  }
`;

SelectSalesRepsQuery.propTypes = {
  render: PropTypes.func.isRequired
};

export default SelectSalesRepsQuery;
