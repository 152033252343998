import React from "react";
import PropTypes from "prop-types";
import Modal from "Util/Modal";
import EditTextField from "../Form/EditTextField";
import CreateGridFilterForUser from "./CreateGridFilterForUser";
import {CurrentUserObject} from "../../Orders/Queries/OrderQuery";

class NewUserFilterModal extends React.Component {
  state = { isOpen: false, name: "", submitting: false };

  render() {
    return (
      <React.Fragment>
        <button
          className="button button--small grey"
          onClick={this.toggleModal}
        >
          Save current filter
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.toggleModal}
            header="Filter name"
          >
            <CreateGridFilterForUser
              grid={this.props.grid}
              onComplete={this.onCreate}
              render={({ createGridFilterForUser }) => {
                const onSubmit = e => {
                  this.setState({ submitting: true });
                  e.preventDefault();
                  createGridFilterForUser({
                    grid: this.props.grid,
                    name: this.state.name,
                    filterModel: JSON.stringify(this.props.filterModel)
                  })
                }
                
                return (
                  <React.Fragment>
                    <div className="margin-bottom-base">
                      <p>This will save the current grid state as a new filter.</p>
                      <p>If you want to remove a filter, please visit the "My Order Filters" page.</p>
                    </div>
                    <form className="form" onSubmit={onSubmit}>
                      <fieldset className={"fieldset fieldset--fullwidth"}>
                        <EditTextField
                          name="name"
                          onChange={this.setName}
                          value={this.state.name}
                          labelText="Filter name"
                          required={true}
                        />
                      </fieldset>
                      <input
                        type="submit"
                        name="commit"
                        value="Save"
                        className="button primary"
                        disabled={!this.state.name || this.state.submitting}
                      />
                    </form>
                  </React.Fragment>
                )
              }}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
  
  setName = (field, value) => this.setState({name: value});
  
  toggleModal = () => {
    const duplicateFilter = this.props.existingFilters.find(filter => filter.filterModel === JSON.stringify(this.props.filterModel))
    if (!this.state.isOpen && duplicateFilter) {
      window.alert(`This filter already exists as "${duplicateFilter.name}"`);
    } else {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen,
        name: "",
        submitting: false,
      }));
    }
  };
  
  onCreate = createdValue => {
    this.setState({ name: "", submitting: false })
    this.props.selectFilter(createdValue);
    this.toggleModal();
  }
}

NewUserFilterModal.propTypes = {
  grid: PropTypes.string.isRequired,
  filterModel: PropTypes.object.isRequired,
  selectFilter: PropTypes.func.isRequired,
  existingFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      filterModel: PropTypes.string.isRequired,
    })
  )
};

export default NewUserFilterModal;
