import React from "react";
import Modal from "Util/Modal";
import ClientsTable from "../Client/Table";
import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";

class ClientsModal extends React.Component {
  state = { isOpen: false };

  render() {
    const { order, clientName, currentUser } = this.props;

    return (
      <React.Fragment>
        <div className={"field client-select"}>
          <label htmlFor="client-id" className="field--required">
            Client
          </label>
          <span className="sub-modal-select">
            {clientName}&nbsp;
            <a
              href="#"
              onClick={e => this.toggleModal(e)}
              className="button button--small"
            >
              {clientName ? "Edit" : "Add"}
            </a>
          </span>
        </div>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={() => this.closeModal()}
            header="Select Client"
            className="clients-modal"
            renderFooter={() => (
              <a
                onClick={() => this.closeModal()}
                className="button button--back"
              >
                Back
              </a>
            )}
          >
            <div className="fieldset">
              If you cannot find your client, please&nbsp;
              <a
                href={"/clients/new"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.closeModal}
              >
                add a client
              </a>
              &nbsp;first
            </div>
            <ClientsTable
              contractId={order.contract.id}
              order={order}
              update={this.handleSelect}
              currentUser={currentUser}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  handleSelect = client => {
    this.props.setClient(client);
    this.closeModal();
  };

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

ClientsModal.propTypes = {
  order: OrderObject.isRequired,
  setClient: PropTypes.func.isRequired,
  clientName: PropTypes.string,
  currentUser: PropTypes.object
};

export default ClientsModal;
