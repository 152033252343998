import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../../../lib/AgGrid/CmpAgGrid";
import contractFragments from "../../Queries/graphql/contractFragments";
import NewOrderLink from "./link";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.facilitiesQuery}
        responseDataKey="facilities"
        localStorageKey="newOrderFacilities"
        frameworkComponents={this.frameworkComponents}
        wrapperClass="half-height"
      />
    );
  }

  frameworkComponents = {
    newOrderLinkRenderer: row => {
      let linkText = row.data.name;
      let linkCss = "";
      if (row.column.colId === "select") {
        linkText = "Select";
        linkCss = "button button--tiny button--white";
      }
      return (
        <NewOrderLink
          contract={this.props.contract}
          linkText={linkText}
          queryParams={"facility_id=" + row.data.id}
          closeModal={this.props.closeModal}
          cssClass={linkCss}
        />
      );
    }
  };

  defaultColumnDefs = () => [
    {
      headerName: "Name",
      colId: "name",
      pinned: "left",
      width: 180,
      filter: "agTextColumnFilter",
      cellRenderer: "newOrderLinkRenderer"
    },
    {
      headerName: "Phone",
      field: "phone",
      width: 120,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Address",
      field: "address",
      width: 220,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      width: 150,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      width: 100,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    },
    {
      headerName: "Postcode",
      field: "postcode",
      width: 100,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "",
      colId: "select",
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: "newOrderLinkRenderer",
      valueGetter: () => null // value not part of dataset
    }
  ];

  facilitiesQuery = () => {
    const contractId = this.props.contract.id;
    return gql`
      query Facilities($agGridParams: RequestParamInput!) {
        facilities(agGridParams: $agGridParams, contractId:${contractId}, activeOnly: true, excludeDrafts: true) {
          id
          name
          phone
          address
          postcode
          city
          state
          organisationId
        }
      }
    `;
  };
}

TableView.propTypes = {
  contract: contractFragments.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
