import React from "react";
import { CurrentUserObject, OrderObject } from "../Queries/OrderQuery";

import Table from "./Table";
import DocumentDeleter from "./DocumentDeleter";

class DocumentIndex extends React.Component {
  render() {
    const { currentUser, order } = this.props;
    return (
      <DocumentDeleter
        order={order}
        render={props => (
          <Table
            deleteDocument={props.deleteDocument}
            documents={order.documents}
            order={order}
            currentUser={currentUser}
          />
        )}
      />
    );
  }
}
DocumentIndex.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  order: OrderObject
};

export default DocumentIndex;
