import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";
import DebtorSelector from "./DebtorSelector";

class EditDebtor extends React.Component {
  state = { editing: false };

  render() {
    const { value, labelText, options } = this.props;
    return (
      <React.Fragment>
        <div className={"field"}>
          <label htmlFor={labelText}>{labelText}</label>
          <span className="sub-modal-select">
            {value}&nbsp;
            <a
              className="button button--small debtor-edit-button"
              onClick={this.toggleEdit}
            >
              {value ? "Edit" : "Add"}
            </a>
          </span>
        </div>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            header="Select the debtor for order"
            onClose={this.toggleEdit}
          >
            <DebtorSelector options={options} onChange={this.onChange} />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  onChange = selection => {
    const { bulkPrice } = this.props;
    let confirmed = true;
    if (
      bulkPrice &&
      bulkPrice.showWarning &&
      !!bulkPrice.initialValue !== !!selection.bulkPricing
    ) {
      confirmed = window.confirm(
        "Continuing will change if the order qualifies for bulk pricing or not. Please check pricing for existing lines."
      );
    }
    if (confirmed) {
      this.props.onChange("debtor", selection);
      this.toggleEdit();
    }
  };

  toggleEdit = e => {
    e && e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };
}

EditDebtor.propTypes = {
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  bulkPrice: PropTypes.shape({
    showWarning: PropTypes.bool,
    initialValue: PropTypes.bool
  }),
  options: PropTypes.array
};

export default EditDebtor;
