import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment ChecklistItemQuery on ChecklistItem {
      id
      number
      description
      status
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string
  })
};

export default fragments;
