import React from "react";

import Modal from "Util/Modal";
import EditTextArea from "Form/EditTextArea";

import { OrderObject } from "./../Queries/OrderQuery";
import RejectOrderUpdater from "./RejectOrderUpdater";

class RejectBtn extends React.Component {
  state = { isOpen: false, reason: { comment: "" } };

  render() {
    const { order } = this.props;
    const { reason } = this.state;
    return (
      <React.Fragment>
        <button className="button red" onClick={this.open}>
          Reject
        </button>
        {this.state.isOpen && (
          <RejectOrderUpdater
            order={order}
            render={({ rejectOrder }) => (
              <Modal
                header="Reject Order"
                show={this.state.isOpen}
                onClose={this.toggleModal}
                renderFooter={() => (
                  <div>
                    <button
                      className="button button--back"
                      onClick={this.toggleModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="button red"
                      onClick={() => {
                        rejectOrder(reason.comment);
                      }}
                    >
                      REJECT ORDER
                    </button>
                  </div>
                )}
              >
                <div className="group_section">
                  Are you sure you want to reject the order? Only reject the
                  order if you do not wish to complete it or are unable to
                  fulfil it. If an item is missing, or you require more
                  information, please accept and send us a comment with your
                  issue/query.
                </div>

                <div className="group_section">
                  <form>
                    <fieldset>
                      <EditTextArea
                        name="comment"
                        onChange={this.handleChange}
                        stackedLabel={true}
                        value={this.state.reason.comment}
                        labelText="Reason for rejecting"
                      />
                    </fieldset>
                  </form>
                </div>
              </Modal>
            )}
          />
        )}
      </React.Fragment>
    );
  }

  handleChange = (field, value) => {
    const reason = { ...this.state.reason, [field]: value };
    this.setState({ reason });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  open = () =>
    this.setState({
      isOpen: true
    });
}

RejectBtn.propTypes = {
  order: OrderObject
};

export default RejectBtn;
