import React from "react";

import { OrderObject } from "./../Queries/OrderQuery";
import BackToValidationUpdater from "./BackToValidationUpdater";

class BackToValidationBtn extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <BackToValidationUpdater
          order={order}
          render={({ backToValidation }) => (
            <button
              disabled={order.uiState.goBackToValidationBtnDisabled}
              className="button primary"
              onClick={backToValidation}
            >
              Go back to validation
            </button>
          )}
        />
      </React.Fragment>
    );
  }
}

BackToValidationBtn.propTypes = {
  order: OrderObject
};

export default BackToValidationBtn;
