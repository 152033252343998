import React from "react";
import PropTypes from "prop-types";
import { OrderObject } from "./../Queries/OrderQuery";

import TableUpdater from "./TableUpdater";
import Table from "./Table";

class Lines extends React.Component {
  render() {
    const {
      order,
      isSuborderDisplay,
      currentUser,
      uiActive,
      disableUIFunc
    } = this.props;

    return (
      <TableUpdater
        order={order}
        disableUIFunc={disableUIFunc}
        render={({ saveLines, errors }) => (
          <React.Fragment>
            <Table
              order={order}
              currentUser={currentUser}
              saveLines={saveLines}
              isSuborderDisplay={isSuborderDisplay}
              uiActive={uiActive}
              disableUIFunc={disableUIFunc}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

Lines.propTypes = {
  order: OrderObject.isRequired,
  isSuborderDisplay: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default Lines;
