import React from "react";
import PropTypes from "prop-types";

import FieldWrapper from "./FieldWrapper";

const EditDropdown = ({
  name,
  onChange,
  value,
  selectCssClass,
  labelText,
  stackedLabel,
  options,
  required,
  placeholder,
  errors
}) => (
  <FieldWrapper name={name} errors={errors} stackedLabel={stackedLabel}>
    <React.Fragment>
      <label htmlFor={name} className={required ? "field--required" : ""}>
        {labelText}
      </label>
      <select
        id={name}
        value={value || ""}
        onChange={e => onChange(name, e.target.value)}
        className={selectCssClass}
        required={required}
      >
        {!options.includes(value || "") && placeholder.length > 0 && (
          <option key="" value="" disabled>
            {placeholder}
          </option>
        )}
        {!options.includes(value || "") && placeholder.length === 0 && (
          <option key="" />
        )}
        {options.map(t => {
          const val = t.hasOwnProperty("value") ? t.value : t;
          const text = t.hasOwnProperty("text") ? t.text : t;
          return (
            <option key={text} value={val}>
              {text}
            </option>
          );
        })}
      </select>
    </React.Fragment>
  </FieldWrapper>
);

EditDropdown.defaultProps = {
  required: false,
  selectCssClass: "",
  stackedLabel: false,
  placeholder: ""
};

EditDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectCssClass: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  stackedLabel: PropTypes.bool,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.array
};

export default EditDropdown;
