import React from "react";
import { CurrentUserObject, OrderObject } from "./../../Queries/OrderQuery";
import Modal from "Util/Modal";

import AddItemsForm from "./AddItemsForm";
import CreateDocumentForOrder from "./CreateDocumentForOrder";
import RequestApprovalUpdater from "./../../FulfillmentOptions/RequestApprovalUpdater";

class AddItems extends React.Component {
  state = { isOpen: false, isSubmitOpen: false };

  render() {
    const { currentUser, order } = this.props;
    return (
      <React.Fragment>
        <button className="button primary" onClick={this.toggleModal}>
          Add Document
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.toggleModal}
            header="Add document"
            renderFooter={() => (
              <a onClick={this.toggleModal} className="button button--back">
                Back to order
              </a>
            )}
          >
            <CreateDocumentForOrder
              order={order}
              complete={this.complete}
              render={({ addDocumentToOrder }) => (
                <AddItemsForm
                  currentUser={currentUser}
                  addDocumentToOrder={addDocumentToOrder}
                  order={order}
                  complete={this.complete}
                />
              )}
            />
          </Modal>
        )}
        {this.state.isSubmitOpen && (
          <Modal
            show={this.state.isSubmitOpen}
            onClose={this.toggleSubmitModal}
            header="Submit to Contract Admin"
          >
            <div className="order-document-submit-to-contract">
              Do you want to submit the quote/invoice to Aidacare Contract Team
              for processing?
            </div>
            <RequestApprovalUpdater
              order={order}
              render={({ requestApproval }) => (
                <button
                  className="button primary"
                  onClick={() => {
                    this.submitToAdmin(requestApproval);
                  }}
                >
                  Ok
                </button>
              )}
            />
            <a onClick={this.toggleSubmitModal} className="button button--back">
              Cancel
            </a>
          </Modal>
        )}
      </React.Fragment>
    );
  }
  submitToAdmin = requestApproval => {
    requestApproval();
    this.toggleSubmitModal();
  };

  complete = () => {
    this.toggleModal();
    if (this.props.order.uiState.showRequestApproval) {
      this.toggleSubmitModal();
    }
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  toggleSubmitModal = () => {
    this.setState({
      isSubmitOpen: !this.state.isSubmitOpen
    });
  };
}

AddItems.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  order: OrderObject.isRequired
};

export default AddItems;
