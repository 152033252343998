import React from "react";
import PropTypes from "prop-types";

class RemoveItems extends React.Component {
  state = { isOpen: false };

  render() {
    const { selections } = this.props;
    const enabled = selections.length > 0;
    return (
      <React.Fragment>
        <button
          className="button red"
          onClick={this.deleteSelected}
          disabled={!enabled}
        >
          Remove Document
          {selections.length > 1 && "s"}
        </button>
      </React.Fragment>
    );
  }

  deleteSelected = () => {
    const { deleteDocument, selections, clearSelections } = this.props;
    if (
      window.confirm(
        `Delete the ${selections.length} selected document${
          selections.length > 1 ? "s" : ""
        }?`
      )
    ) {
      selections.forEach(id => deleteDocument({ id }));
      clearSelections();
    }
  };
}

RemoveItems.propTypes = {
  deleteDocument: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  clearSelections: PropTypes.func.isRequired
};

export default RemoveItems;
