import React from "react";
import PropTypes from "prop-types";

import Modal from "Util/Modal";

import { TickCompleted, TickCross } from "./TickHelpers";

class ClinicalJustification extends React.Component {
  state = { popupNoOpen: false };

  constructor(props) {
    super(props);
    this.clinicalJustificationNoteRef = React.createRef();
  }

  render() {
    const { order } = this.props;
    const cj = order.clinicalJustificationProvided;
    return (
      <section className="detail_section detail_section__padded ">
        <div className="add-item-action clinical_justification-provided">
          {cj == null && (
            <React.Fragment>
              <div>
                <div className="cj-provided-header">
                  Clinical justification provided for all items that require it?
                </div>
                <div className="cj-provided-text">
                  Only certain items need this, please ensure that it has been
                  provided if so.
                </div>
              </div>
              <div className="button-group">
                <button
                  onClick={this.handleYes}
                  type="button"
                  className="button primary"
                >
                  Yes
                </button>
                <button
                  onClick={this.handleNo}
                  type="button"
                  className="button red"
                >
                  No
                </button>
              </div>

              {this.state.popupNoOpen && (
                <Modal
                  show={this.state.popupNoOpen}
                  header="Clinical Justification Not Provided"
                  onClose={this.closeModal}
                >
                  <div className="cj-provided-no-comment">
                    <b>Please add a comment to the Healthcare Provider</b>
                    <div>To Healthcare Provider</div>
                    <div>
                      <textarea
                        lines="8"
                        ref={this.clinicalJustificationNoteRef}
                      />
                    </div>
                    <div>Comment by: aidacarestaff</div>
                    <div>
                      <button
                        className="button blue"
                        onClick={this.handleClientIsEligibleNoConfirmed}
                      >
                        Update order
                      </button>
                      <a onClick={this.closeModal}>cancel</a>
                    </div>
                  </div>
                </Modal>
              )}
            </React.Fragment>
          )}
          {cj === true && (
            <div>
              <TickCompleted />
              <p className="display-inline-block margin-right-base">
                Clinical justification provided for all items.
              </p>
              {this.change()}
            </div>
          )}
          {cj === false && (
            <div>
              <TickCross />
              <p className="display-inline-block margin-right-base">
                This order has been set to{" "}
                <strong>Clinical Justification Required</strong>. The healthcare
                provider will need to re-submit the clinical justification for
                further action...
              </p>
              {this.change()}
            </div>
          )}
        </div>
      </section>
    );
  }

  change = () => (
    <button
      className="button button--white margin-left-base"
      onClick={this.resetVal}
    >
      change
    </button>
  );

  saveOrder = val => {
    let order = {
      id: this.props.order.id,
      clinicalJustificationProvided: val
    };
    const noteRef = this.clinicalJustificationNoteRef;
    if (noteRef && noteRef.current && noteRef.current.value) {
      order.clinicalJustificationNote = noteRef.current.value;
    }

    this.props.updateOrder(order);
  };

  handleYes = e => {
    this.saveOrder(true);
  };

  handleNo = e => {
    this.setState({ popupNoOpen: true });
  };

  handleClientIsEligibleNoConfirmed = e => {
    this.saveOrder(false);
    this.closeModal();
  };

  closeModal = e => this.setState({ popupNoOpen: false });

  resetVal = () => this.saveOrder(null);
}

ClinicalJustification.propTypes = {
  order: PropTypes.object,
  updateOrder: PropTypes.func.isRequired
};

export default ClinicalJustification;
