import { isOnDvaContract } from "../../BusRules/access";
import { OrderObject } from "../../Queries/OrderQuery";
import ClientIsEligible from "./ClientIsEligible";
import PropTypes from "prop-types";

const getPrevalidationColumns = ({ order, saveLine }) => {
  let cols = [];
  if (
    order.uiState &&
    order.uiState.stagePrevalidation &&
    isOnDvaContract(order)
  ) {
    cols.push(
      {
        headerName: "Business rules",
        field: "businessRules",
        valueGetter: params =>
          params.data.isTotals
            ? ""
            : `Prior Approval: ${params.data.priorApproval}. Prescriber: ${
                params.data.prescriber
              }`,
        tooltipValueGetter: params => params.value
      },
      {
        headerName: "Is the client eligible for this item?",
        wrapHeaderText: true,
        width: 200,
        cellRendererSelector: params => {
          if (params.data.isTotals) {
            return null;
          } else {
            return {
              component: ClientIsEligible,
              params: {
                line: params.data,
                saveLine: saveLine
              }
            };
          }
        }
      }
    );
  }

  return cols;
};

getPrevalidationColumns.propTypes = {
  order: OrderObject.isRequired,
  saveLine: PropTypes.func.isRequired
};

export { getPrevalidationColumns };
