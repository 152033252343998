import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";

class TableView extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.clientItemsQuery}
        responseDataKey="clientOrderLineSummaries"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "CMP Order #",
      field: "order.cmpOrderNumber",
      colId: "orderRequestId",
      type: "cmpLinkColumn",
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        return {
          link: `/orders/${params.data.order.id}`
        };
      }
    },
    {
      headerName: "Web Order #",
      field: "order.webOrderNo",
      colId: "webOrderNo",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Contract",
      field: "order.contract.contractCode",
      colId: "contract",
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Cat Num",
      field: "sku",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Description",
      field: "description",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Schedule Id",
      field: "rapScheduleCode",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Sale Type",
      field: "saleType",
      filter: "agSetColumnFilter",
      filterParams: {
        suppressMiniFilter: true,
        values: ["Buy", "Hire", "Home Mod", "Repair", "RTO"]
      }
    },
    {
      headerName: "Qty",
      field: "quantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "greaterThan", "lessThan"]
      }
    },
    {
      headerName: "Line Status",
      field: "status",
      filter: false
    },
    {
      headerName: "Order Date",
      field: "order.orderDate",
      colId: "orderDate",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "Delivery Date",
      field: "deliveredOn",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "Returned Date",
      field: "pickedUpOn",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "CMP Inv Num",
      field: "latestInvoiceNumber",
      sortable: false,
      filter: false
    },
    {
      headerName: "Invoice Date",
      field: "latestInvoiceDate",
      sortable: false,
      filter: false
    },
    {
      headerName: "Network Partner",
      field: "order.partner.name",
      colId: "partnerName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Healthcare Provider",
      field: "order.healthcareProvider.name",
      colId: "healthcareProviderName",
      filter: "agTextColumnFilter"
    }
  ];

  clientItemsQuery = () => {
    const id = this.clientIdFromPath();
    return gql`
      query ClientOrderLineSummaries($agGridParams: RequestParamInput!) {
        clientOrderLineSummaries(id: ${id}, agGridParams: $agGridParams) {
          id
          description
          saleType
          sku
          status
          quantity
          deliveredOn
          pickedUpOn
          rapScheduleCode
          latestInvoiceDate
          latestInvoiceNumber
          order {
            id
            cmpOrderNumber
            webOrderNo
            orderDate
            contract {
              id
              contractCode
            }
            healthcareProvider {
              id
              name
            }
            partner {
              id
              name
            }
          }
        }
      }
    `;
  };

  clientIdFromPath = () => {
    const matchPath = window.location.pathname.match(/clients\/\d+/);
    if (matchPath) {
      return matchPath[0].match(/\d+/)[0];
    } else {
      return null;
    }
  };
}

TableView.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default TableView;
