import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class ToStaffMember extends React.Component {
  render() {
    const { staff, order } = this.props;
    if (String(order.assignedStaffId) === String(staff.id)) {
      return null;
    }
    return (
      <a className="assign-staff-member-to-order-option" onClick={this.assign}>
        {staff.name}
      </a>
    );
  }

  assign = e => {
    const { staff, order } = this.props;
    e.preventDefault();
    this.props.update({
      variables: {
        id: order.id,
        input: {
          userId: staff.id
        }
      }
    });
  };
}

ToStaffMember.propTypes = {
  staff: PropTypes.object.isRequired,
  order: OrderObject,
  update: PropTypes.func.isRequired
};

export default ToStaffMember;
