import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment UserQuery on User {
      id
      firstName
      lastName
      employeeId
      itAdmin
      organisationName
      phoneNumber
      mobileNumber
      email
      namedRoles
      status
      name
      formattedLastSignIn
      contractIds
      contracts
      userToEnter
    }
  `
};

export default fragments;
