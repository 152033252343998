import { getColumnWidth } from "../../../lib/Util/getColumnWidth";
import { formatD } from "../../../lib/Util/dollars";
import { lineStatuses } from "../../BusRules/orderLines";
import { OrderObject } from "../../Queries/OrderQuery";

const getPricingColumns = ({ order }) => {
  let cols = [];

  if (order.uiState.showContractPricing) {
    cols.push({
      headerName: "Contract (exc GST)",
      children: [
        pricingColumnDef(order, "Unit", "sellUnitPriceExc"),
        pricingColumnDef(order, "Line", "lineSellUnitPriceExc")
      ]
    });
  }
  if (order.uiState.showNpPricing) {
    if (!order.uiState.currentUserIsHeadOffice) {
      cols.push({
        headerName: "NP Val (inc GST)",
        children: [
          pricingColumnDef(order, "Unit", "buyUnitPriceInc"),
          pricingColumnDef(order, "Line", "lineBuyUnitPriceInc")
        ]
      });
    } else {
      cols.push({
        headerName: "NP Val (exc GST)",
        children: [
          pricingColumnDef(order, "Unit", "buyUnitPriceExc"),
          pricingColumnDef(order, "Line", "lineBuyUnitPriceExc")
        ]
      });
    }
  }
  if (order.uiState.showContractPricing || order.uiState.showNpPricing) {
    cols.push({
      headerName: "GST",
      field: "gst",
      width: 40,
      cellStyle: { textAlign: "center" },
      editable: params => canEditPrices(order, params.data),
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["No", "Yes"]
      }
    });
  }

  return cols;
};

const pricingColumnDef = (order, header, field) => {
  let columnDef = {
    headerName: header,
    field: field,
    valueFormatter: params => formatD(params.value),
    width: getColumnWidth(order.orderLines, field, "price"),
    cellStyle: { textAlign: "right" }
  };
  if (header === "Unit") {
    columnDef = {
      ...columnDef,
      editable: params => canEditPrices(order, params.data),
      cellEditor: "agNumberCellEditor",
      cellEditorParams: {
        min: 0,
        step: 0.01
      },
      valueSetter: params => {
        if (params.newValue) {
          params.data[field] = params.newValue;
          return true;
        }
        params.data[field] = params.oldValue;
        return false;
      }
    };
  }
  return columnDef;
};

const canEditPrices = (order, line) => {
  return (
    order.uiState.canEditLinePrices &&
    lineStatuses.START_EDIT_BUY_LIST.includes(line.status)
  );
};

getPricingColumns.propTypes = {
  order: OrderObject.isRequired
};

export { getPricingColumns };
