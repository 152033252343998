import React from "react";
import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";
import Select from "react-select";
import FieldWrapper from "../../lib/Form/FieldWrapper";
import ErrorDisplay from "../../lib/Form/ErrorDisplay";
import TransferOrderUpdater from "./TransferOrderUpdater";
import Modal from "Util/Modal";
import DischargeTransferQuery from "./DischargeTransferQuery";
import EditDateField from "../../lib/Form/EditDateField";
import dateFuncs from "../../lib/Form/dateFuncs";
import EditTextArea from "../../lib/Form/EditTextArea";
import EditTextField from "../../lib/Form/EditTextField";
import EditSalesRep from "../OrderDetails/EditSalesRep";
import EditDebtor from "../OrderDetails/EditDebtor";
import ClientsModal from "./ClientsModal";
import HcpsModal from "./HcpsModal";
import EditYesNo from "../../lib/Form/EditYesNo";

class DischargeModal extends React.Component {
  state = {
    isOpen: false,
    submitDisabled: false,
    transfer: {
      // Local time in ISO format
      transferDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      comment: "",
      cpo: this.props.order.webOrderNo + "/" + this.props.order.costCentre,
      clientId: null,
      clientName: null,
      hcpId: null,
      hcpName: null,
      salesRep: this.props.order.salesRep,
      debtor: this.props.order.debtor,
      vic30DayDischarge: null,
      costCentre: ""
    }
  };

  render() {
    const { order, selectedLineIds, currentUser } = this.props;
    const { transfer } = this.state;
    return (
      <React.Fragment>
        <button
          onClick={e => this.open(e)}
          className="button button--white"
          disabled={selectedLineIds.length === 0}
        >
          Transfer to Discharge
        </button>
        {this.state.isOpen && (
          <DischargeTransferQuery
            contractId={order.contract.id}
            render={({
              healthcareProviders,
              costCentres,
              salesReps,
              debtors
            }) => (
              <TransferOrderUpdater
                order={order}
                onError={this.enableSubmit}
                render={({ transferOrder, errors }) => (
                  <Modal
                    header="Transfer Order"
                    show={this.state.isOpen}
                    onClose={() => this.close(true)}
                    className="discharge-transfer-modal"
                    renderFooter={() => (
                      <div>
                        <button
                          className="button button--back"
                          onClick={() => this.close(false)}
                        >
                          Back
                        </button>
                        <button
                          className="button primary"
                          onClick={() => {
                            this.setState({ submitDisabled: true });
                            transferOrder({
                              transferType: "discharge",
                              lineIds: selectedLineIds,
                              comment: transfer.comment,
                              cpo: transfer.cpo,
                              costCentre: transfer.costCentre,
                              clientId: parseInt(transfer.clientId),
                              healthcareProviderId: parseInt(transfer.hcpId),
                              salesRepId: transfer.salesRep.id,
                              debtorId: transfer.debtor.id,
                              vic30DayDischarge: transfer.vic30DayDischarge,
                              transferDate: transfer.transferDate
                            });
                          }}
                          disabled={
                            this.state.submitDisabled ||
                            !transfer.hcpId ||
                            !transfer.clientId ||
                            !transfer.transferDate ||
                            !transfer.costCentre ||
                            !(transfer.salesRep && transfer.salesRep.id) ||
                            !(transfer.debtor && transfer.debtor.id) ||
                            transfer.vic30DayDischarge === null
                          }
                        >
                          Transfer Order
                        </button>
                      </div>
                    )}
                  >
                    <ErrorDisplay errors={errors} modelDesc="order" />
                    <fieldset className="fieldset">
                      <EditDateField
                        name="transferDate"
                        onChange={this.handleChange}
                        value={transfer.transferDate}
                        labelText="Transfer Date"
                        className="fullwidth"
                        required={true}
                        limits={{
                          startDate: dateFuncs.toYYYYMMDD(order.dateReceived)
                        }}
                        errors={errors}
                      />
                      <EditTextField
                        onChange={this.handleChange}
                        name="cpo"
                        value={transfer.cpo}
                        labelText="CPO"
                        className="fullwidth"
                      />
                      <FieldWrapper name="costCentre" errors={errors}>
                        <label htmlFor="costCentre" className="field--required">
                          Cost Centre
                        </label>
                        <Select
                          name="costCentre"
                          id="cost-centre"
                          classNamePrefix="react-select-cost-centre"
                          onChange={this.handleCostCentreChange}
                          options={costCentres.map(f => {
                            let label = f.code;
                            if (f.description) label += ` (${f.description})`;
                            return {
                              value: f.code,
                              label: label
                            };
                          })}
                        />
                      </FieldWrapper>
                      <EditYesNo
                        onChange={this.handleChange}
                        name="vic30DayDischarge"
                        className="fullwidth"
                        labelText="Vic 30 Day Discharge"
                        labelClass="field--required"
                        includeBlank={true}
                        value={transfer.vic30DayDischarge}
                      />
                      <EditTextArea
                        name="comment"
                        onChange={this.handleChange}
                        value={transfer.comment}
                        labelText="Comment"
                        errors={errors}
                      />
                      <ClientsModal
                        order={order}
                        setClient={this.handleClientSelect}
                        clientName={transfer.clientName}
                        currentUser={currentUser}
                      />
                      <HcpsModal
                        order={order}
                        setHcp={this.handleHcpSelect}
                        hcpName={transfer.hcpName}
                        currentUser={currentUser}
                      />
                      <EditSalesRep
                        options={salesReps}
                        labelText="Sales Rep"
                        onChange={this.handleChange}
                        value={
                          (transfer.salesRep && transfer.salesRep.name) || null
                        }
                        required={true}
                      />
                      <EditDebtor
                        onChange={this.handleChange}
                        value={
                          (transfer.debtor && transfer.debtor.code) || null
                        }
                        labelText="Debtor"
                        options={debtors}
                      />
                    </fieldset>
                  </Modal>
                )}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }

  handleCostCentreChange = (selected, field) => {
    this.handleSelect(selected, field);
    this.handleChange(
      "cpo",
      this.props.order.webOrderNo + "/" + selected.value
    );
  };

  handleClientSelect = client => {
    this.setState(prevState => ({
      transfer: {
        ...prevState.transfer,
        clientId: client.id,
        clientName: `${client.firstName} ${client.lastName}`
      }
    }));
  };

  handleHcpSelect = hcp => {
    this.setState(prevState => ({
      transfer: {
        ...prevState.transfer,
        hcpId: hcp.id,
        hcpName: `${hcp.firstName} ${hcp.lastName}`
      }
    }));
  };

  handleSelect = (selected, field) => {
    this.setState(prevState => ({
      transfer: { ...prevState.transfer, [field.name]: selected.value }
    }));
  };

  handleChange = (field, value) => {
    this.setState(prevState => ({
      transfer: { ...prevState.transfer, [field]: value }
    }));
  };
  
  enableSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  open = e => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };

  close = closeParent => {
    this.setState({ isOpen: false });
    if (closeParent) {
      this.props.closeParentModal();
    }
  };
}

DischargeModal.defaultProps = {
  selectedLineIds: []
};

DischargeModal.propTypes = {
  closeParentModal: PropTypes.func.isRequired,
  order: OrderObject.isRequired,
  selectedLineIds: PropTypes.array,
  currentUser: PropTypes.object
};

export default DischargeModal;
