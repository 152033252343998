import {
  apolloClient,
  pollingStopper,
  refreshInterval
} from "../GraphQL/GraphqlWrapper";

function newServerSideDatasource(
  agGridApi,
  gqlQuery,
  responseDataKey,
  pollingPathNameMatch = null,
  newDataCallback = null
) {
  const pollInterval = pollingPathNameMatch ? refreshInterval : 0;
  const fetch = pollingPathNameMatch ? "network-only" : "cache-and-network";
  let observableQuery = null;
  let cachedRows;
  return {
    getRows: params => {
      const stopPolling = () => {
        observableQuery.unsubscribe();
      };
      if (pollingPathNameMatch) {
        document.addEventListener("turbolinks:load", () => {
          pollingStopper(pollingPathNameMatch, stopPolling);
        });
      }
      // If getRows has already been called, stop polling the last call
      if (observableQuery) {
        stopPolling();
        cachedRows = null;
      }
      observableQuery = apolloClient
        .watchQuery({
          query: gqlQuery(),
          variables: {
            agGridParams: {
              sortModel: params.request.sortModel,
              startRow: params.request.startRow,
              endRow: params.request.endRow,
              filterModel: mapFilterModel(params.request.filterModel)
            }
          },
          pollInterval: pollInterval,
          fetchPolicy: fetch
        })
        .subscribe({
          next(response) {
            if (params.api.isDestroyed() && observableQuery) {
              observableQuery.unsubscribe();
            } else if (response.data && response.data[responseDataKey]) {
              agGridApi.hideOverlay();
              const rows = response.data[responseDataKey];
              // If a subsequent poll returns less rows we must purge the cache
              // to force a refresh of the dataset and redraw the grid.
              // It results in a second query but it's a necessary evil to allow
              // polling with the server-side data model.
              if (cachedRows && cachedRows.length > rows.length) {
                cachedRows = rows;
                agGridApi.refreshServerSide({});
                return;
              } else {
                cachedRows = rows;
              }
              params.success({ rowData: rows });
              if (newDataCallback) {
                newDataCallback(response.data);
              }
            }
          },
          error(e) {
            console.error(e);
            params.fail();
            agGridApi.showLoadingOverlay();
          }
        });
    }
  };
}

const mapFilterModel = filterModel => {
  let parsedFilterModel = [];
  for (const [key, value] of Object.entries(filterModel)) {
    const filterValue =
      typeof value.filter === "number" ? value.filter.toString() : value.filter;
    parsedFilterModel.push({
      attrName: key,
      filterType: value.filterType,
      filterValue: filterValue,
      setValues: value.values,
      filterDate: value.dateFrom,
      filterOption: value.type
    });
  }
  return parsedFilterModel;
};

export default newServerSideDatasource;
