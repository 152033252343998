import React from "react";
import PropTypes from "prop-types";

const ErrorDisplay = ({ errors, modelDesc }) =>
  errors &&
  errors.length > 0 && (
    <div id="error_explanation" role="alert">
      <h2>Errors prohibited this form from being saved:</h2>
      <ul>
        {errors.map(e => (
          <li key={e.attribute}>{e.message}</li>
        ))}
      </ul>
    </div>
  );

ErrorDisplay.propTypes = {
  errors: PropTypes.array,
  modelDesc: PropTypes.string.isRequired
};

export default ErrorDisplay;
