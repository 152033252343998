import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

class MarkAllClientEligibleYes extends React.Component {
  render() {
    if (
      this.noLinesToMark() ||
      this.props.order.clinicalJustificationProvided != null
    ) {
      return null;
    }
    return (
      <button onClick={this.markAsYes} className="button">
        Mark all as Yes
      </button>
    );
  }

  noLinesToMark = () => this.linesToMark().length === 0;

  linesToMark = () =>
    this.props.order.orderLines.filter(ol => !ol.clientIsEligible);

  markAsYes = () => {
    this.linesToMark().map(ol => {
      this.props.saveLine({
        id: ol.id,
        clientIsEligible: true
      });
    });
  };
}

MarkAllClientEligibleYes.propTypes = {
  order: OrderObject.isRequired,
  saveLine: PropTypes.func.isRequired
};

export default MarkAllClientEligibleYes;
