import React from "react";
import PropTypes from "prop-types";

import CommentMemoIsRead from "./CommentMemoIsRead";
import CommentMemoView from "./CommentMemoView";

class CommentMemo extends React.Component {
  render() {
    if (this.props.data.canEditIsRead) {
      return <CommentMemoIsRead {...this.props} />;
    } else {
      return <CommentMemoView {...this.props} />;
    }
  }
}

CommentMemo.propTypes = {
  data: PropTypes.object.isRequired
};

export default CommentMemo;
