import React from "react";
import PropTypes from "prop-types";

class AddRowToOrderBtn extends React.Component {
  state = { disabled: false };

  render() {
    const { row } = this.props;

    return (
      <button
        className="button button--tiny button--white"
        onClick={this.addToOrder}
        id={`add-to-order-${row.id}`}
        disabled={this.state.disabled}
      >
        Select
      </button>
    );
  }

  addToOrder = e => {
    this.setState({ disabled: true });
    const { row, update } = this.props;
    update(row);
  };
}

AddRowToOrderBtn.propTypes = {
  row: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

export default AddRowToOrderBtn;
