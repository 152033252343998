import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";

const AssignCanceler = ({ render, order }) => {
  return (
    <GraphqlWrapper>
      <Mutation mutation={CANCEL_ASSIGNMENT}>
        {(update, { loading, error }) => {
          const cancelAssignment = () => {
            update({
              variables: {
                id: order.id
              }
            });
          };
          return render({ order: order, cancelAssignment });
        }}
      </Mutation>
    </GraphqlWrapper>
  );
};

AssignCanceler.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default AssignCanceler;

const CANCEL_ASSIGNMENT = gql`
  mutation cancelAssignment($id: ID!) {
    cancelAssignment(id: $id) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
`;
