import PropTypes from "prop-types";
import gql from "graphql-tag";

const fragments = {
  query: gql`
    fragment HcpQuery on HealthcareProvider {
      id
      sourceId
      firstName
      lastName
      groupName
      contract
      medicareProviderNumber
      providerType
      phoneNumber
      mobileNumber
      faxNumber
      preferredContactMethod
      email
      address
      city
      postcode
      state
      formattedAddress
      ahpraNumber
      prescriberNotes
      cjInstructions
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sourceId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    groupName: PropTypes.string,
    contract: PropTypes.string,
    medicareProviderNumber: PropTypes.string,
    providerType: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    faxNumber: PropTypes.string,
    preferredContactMethod: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    formattedAddress: PropTypes.string,
    ahpraNumber: PropTypes.string,
    prescriberNotes: PropTypes.string,
    cjInstructions: PropTypes.string
  })
};

export default fragments;
