import React from "react";
import PropTypes from "prop-types";

import { ClientObject } from "./../Queries/OrderQuery";
import RoField from "Form/RoField";
import RoYesNo from "Form/RoYesNo";
import RoMemo from "Form/RoMemo";
import RoDateField from "Form/RoDateField";
import {
  isErapOrder,
  isNonErapDvaOrder,
  isOnDvaContract
} from "../BusRules/access";
import RoPhoneField from "../../lib/Form/RoPhoneField";
import {isValidMobile} from "../../lib/Form/formatPhone";

const ClientView = ({ client, order }) => {
  return (
    <div className={"display-flex"}>
      <fieldset className={"fieldset fieldset--compact"}>
        <RoField
          value={(client.lastName || "").toUpperCase()}
          labelText="Surname"
        />
        <RoField value={client.firstName} labelText="Given names" />
        {isErapOrder(order) && (
          <RoField value={client.gender} labelText="Gender" />
        )}
        <RoField value={client.reference} labelText="Client Ref. No" />
        <RoDateField value={client.dateOfBirth} labelText="Date of birth" />
        {isOnDvaContract(order) && (
          <RoField value={client.cardType} labelText="Card type" />
        )}
        {isOnDvaContract(order) && isErapOrder(order) && (
          <RoYesNo
            value={client.homecarePackageLevel}
            labelText="Home Care Packages lvl 4"
          />
        )}
        {isOnDvaContract(order) && (
          <RoYesNo
            value={client.residentialCareFacility}
            labelText="Residential care facility"
          />
        )}
        {isOnDvaContract(order) && (
          <RoField
            value={client.acfiClassification}
            labelText="ACFI Classification"
          />
        )}
        <RoField value={client.status} labelText="Client Status" />
        <RoPhoneField value={client.phoneNumber} labelText="Phone No" />
        <RoPhoneField
          value={client.mobileNumber}
          labelText="Mobile No"
          valueNote={client.mobileNumber && isValidMobile(client.mobileNumber) ? "" : "Invalid mobile"}
        />
        <RoField value={client.email} labelText="Email" />
        <RoField
          value={client.altContactName}
          labelText="Alternative Contact Name"
        />
        <RoPhoneField
          value={client.altPhoneNumber}
          labelText="Alternative Contact Phone No"
        />
        {isNonErapDvaOrder(order) && (
          <RoYesNo value={client.fundingOther} labelText="Other Funding" />
        )}
        {isNonErapDvaOrder(order) && client.fundingOther && (
          <React.Fragment>
            <RoField
              value={client.fundingOtherType}
              labelText="Other Funding Type"
            />
            <RoField
              value={client.fundingOtherNotes}
              labelText="Other Funding Notes"
            />
          </React.Fragment>
        )}
      </fieldset>
      <fieldset className={"fieldset fieldset--compact"}>
        <RoField
          value={client.formattedAddress}
          labelText="Residential Address"
        />
        <RoYesNo
          value={client.differentDeliveryAddress}
          labelText="Delivery address different to Residential"
        />
        {client.differentDeliveryAddress && (
          <RoField
            value={client.formattedDeliveryAddress}
            labelText="Delivery Address"
          />
        )}
        {isOnDvaContract(order) && (
          <RoDateField
            value={client.dischargeDate}
            labelText="Date of Discharge"
          />
        )}
        {!order.includesRepair && (
          <RoMemo
            value={client.deliveryInstructions}
            labelText="Delivery Instructions"
          />
        )}
        {isNonErapDvaOrder(order) && order.includesHomeMods && (
          <RoMemo
            value={client.homeModsInstructions}
            labelText="Home Mods Instructions"
          />
        )}
      </fieldset>
    </div>
  );
};

ClientView.propTypes = {
  client: ClientObject.isRequired,
  order: PropTypes.object.isRequired
};

export default ClientView;
