import React from "react";
import PropTypes from "prop-types";

import EditTextField from "./EditTextField";
import EditDropdown from "./EditDropdown";

const EditAddressFields = ({ model, onChange, errors }) => (
  <React.Fragment>
    <EditTextField
      name="address"
      onChange={onChange}
      value={model.address}
      labelText="Address"
      errors={errors}
    />
    <EditTextField
      name="city"
      onChange={onChange}
      value={model.city}
      labelText="City"
      errors={errors}
    />
    <EditTextField
      name="postcode"
      onChange={onChange}
      value={model.postcode}
      labelText="Postcode"
      errors={errors}
    />

    <EditDropdown
      name="state"
      onChange={onChange}
      value={model.state}
      labelText="State"
      options={model.stateOptions}
      errors={errors}
    />
  </React.Fragment>
);

EditAddressFields.propTypes = {
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default EditAddressFields;
