import React from "react";
import PropTypes from "prop-types";

class ProcessInvoices extends React.Component {
  state = { isOpen: false };

  render() {
    const { selections } = this.props;
    const enabled = selections.length > 0;
    return (
      <React.Fragment>
        <button
          className="button primary"
          onClick={this.process}
          disabled={!enabled}
        >
          Process Invoices
        </button>
      </React.Fragment>
    );
  }

  process = () => {
    const { orderId, processLines, selections, close } = this.props;

    const lines = selections.map(row => {
      return {
        id: row.id,
        partnerReference: row.partnerReference
      };
    });

    processLines(orderId, lines);
    close();
  };
}

ProcessInvoices.propTypes = {
  orderId: PropTypes.number.isRequired,
  processLines: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};

export default ProcessInvoices;
