import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import SeekApproval from "./../PreValidationSteps/SeekApproval";
import ConfirmOrderBtn from "./ConfirmOrderBtn";
import OrderValidationMessage from "./../OrderValidationMessage";

const PartnerApproval = props => {
  const { order } = props;

  if (order.uiState.allowOrderConfirmation !== true) {
    return (
      <OrderValidationMessage>
        Please update all lines and remove any home modification / repairs
        request lines
      </OrderValidationMessage>
    );
  }

  return (
    <React.Fragment>
      <SeekApproval {...props} approval="partnerRequestedApproval" />
      {order.uiState.showPartnerApprovalCompleted && (
        <ConfirmOrderBtn {...props} />
      )}
    </React.Fragment>
  );
};

PartnerApproval.propTypes = {
  order: OrderObject,
  updateOrder: PropTypes.func.isRequired
};

export default PartnerApproval;
