import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { canViewVisibleTo } from "./../BusRules/access";

import { CommentObject, CurrentUserObject } from "./../Queries/OrderQuery";

import CommentMemo from "./CommentMemo";
import { AgGridReact } from "ag-grid-react";
import updateCommentMutation from "./updateCommentMutation";
import { apolloClient } from "../../lib/GraphQL/GraphqlWrapper";

const Table = ({ comments, currentUser }) => {
  const [rowData, setRowData] = useState(comments);

  useEffect(() => {
    setRowData(comments);
  }, [comments]);

  const getRowId = useMemo(() => {
    return params => params.data.id;
  }, []);

  const updateComment = useCallback(params => {
    const mutation = updateCommentMutation;
    apolloClient
      .mutate({
        mutation: mutation,
        variables: {
          id: params.data.id,
          input: {
            body: params.newValue
          }
        }
      })
      .then(result => {
        params.node.setData(result.data.updateComment.comment);
      })
      .catch(error => {
        console.log("Error updating comment", error);
        params.node.setData({ ...params.data, body: params.oldValue });
      });
  }, []);

  const getColumnDefs = () => {
    let cols = [];
    if (canViewVisibleTo(currentUser)) {
      cols.push(
        ...[
          {
            headerName: "Visible to",
            field: "visibleTo",
            width: 150
          }
        ]
      );
    }
    cols.push(
      ...[
        {
          headerName: "Comments",
          field: "body",
          cellRenderer: CommentMemo,
          cellRendererParams: params => {
            return { comment: params.data };
          },
          flex: 1,
          autoHeight: true,
          editable: params => params.data.canEditBody,
          cellEditor: "agLargeTextCellEditor",
          cellEditorPopup: true,
          onCellValueChanged: params => updateComment(params)
        },
        {
          headerName: "Added on",
          field: "addedAt",
          width: 150
        },
        {
          headerName: "Added by",
          field: "ownerName",
          width: 150
        }
      ]
    );
    return cols;
  };
  const [columnDefs, setColumnDefs] = useState(getColumnDefs());
  const [defaultColDef, setDefaultColDef] = useState({
    resizable: false,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    filter: false
  });

  return (
    <div
      className="ag-theme-balham ag-grid-wrapper auto-height"
      style={{ marginBottom: 5 }}
    >
      <AgGridReact
        rowData={rowData}
        getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        enableCellTextSelection={true}
        suppressContextMenu={true}
      />
    </div>
  );
};

Table.propTypes = {
  currentUser: CurrentUserObject.isRequired,
  comments: PropTypes.arrayOf(CommentObject)
};

export default Table;
