import React from "react";
import PropTypes from "prop-types";

import FieldWrapper from "./FieldWrapper";

import dateFuncs from "./dateFuncs";
// A React class to wrap the jquery date picker
// do this so we have the same calendar UI across the app for now

class EditDateField extends React.Component {
  render() {
    const { name, labelText, stackedLabel, required, errors } = this.props;
    return (
      <FieldWrapper name={name} errors={errors} stackedLabel={stackedLabel}>
        <React.Fragment>
          <label htmlFor={name} className={required ? "field--required" : ""}>
            {labelText}
          </label>
          <DateFieldInput {...this.props} />
        </React.Fragment>
      </FieldWrapper>
    );
  }
}

const dateLimitOptions = [null, "TodayBack", "TodayForward"];

EditDateField.defaultProps = {
  className: "",
  stackedLabel: false,
  required: false
};

EditDateField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string,
  stackedLabel: PropTypes.bool,
  limit: PropTypes.oneOf(dateLimitOptions),
  errors: PropTypes.array,
  required: PropTypes.bool
};

class DateFieldInput extends React.Component {
  render() {
    const { name, className, value, limit, limits } = this.props;
    let min, max;

    if (limit === "TodayForward") {
      min = dateFuncs.toYYYYMMDD("today");
    } else if (limits.startDate) {
      min = limits.startDate;
    }
    if (limit === "TodayBack") {
      max = dateFuncs.toYYYYMMDD("today");
    } else if (limits.endDate) {
      max = limits.endDate;
    }

    return (
      <input
        ref={el => (this.el = el)}
        id={name}
        className={className}
        type="date"
        placeholder="dd/mm/yyyy"
        min={min}
        max={max}
        autoComplete="off"
        onChange={this.handleTextChange}
        defaultValue={value || ""}
      />
    );
  }

  handleTextChange = e => {
    let val = null;
    if (e.target.value.length > 0) {
      val = e.target.value;
    }
    this.props.onChange(this.props.name, val);
  };
}

DateFieldInput.defaultProps = {
  className: "",
  limits: {}
};

DateFieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limit: PropTypes.oneOf(dateLimitOptions),
  limits: PropTypes.object
};

export default EditDateField;
export { DateFieldInput };
