import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../../Queries/OrderQuery";
import { filterSaleType } from "./../../BusRules/orders";
import ProductAdder from "./ProductAdder";
import ProductList from "./ProductList";
import GridPropsQuery from "../../../lib/AgGrid/GridPropsQuery";

class AddItemsForm extends React.Component {
  state = { searchVal: "" };
  render() {
    const { currentUser, order, disableUIFunc } = this.props;
    const gridPropsFields = [
      {
        dataKey: "scheduleItemSaleTypes",
        gqlQueryString: `
          scheduleItemSaleTypes(contractId: ${order.contract.id})
        `
      }
    ];

    return (
      <React.Fragment>
        <ProductAdder
          disableUIFunc={disableUIFunc}
          render={({ update }) => (
            <GridPropsQuery
              additionalFields={gridPropsFields}
              render={({ scheduleItemSaleTypes }) => (
                <React.Fragment>
                  <ProductList
                    currentUser={currentUser}
                    order={order}
                    update={update}
                    saleTypeFilterOptions={scheduleItemSaleTypes}
                    saleType={filterSaleType(order, this.props.isAddFreight)}
                  />
                  <footer className="modal__footer">
                    <button
                      className="button button--back"
                      onClick={this.props.complete}
                    >
                      Back to order
                    </button>
                  </footer>
                </React.Fragment>
              )}
            />
          )}
        />
      </React.Fragment>
    );
  }
}

AddItemsForm.propTypes = {
  currentUser: PropTypes.object,
  order: OrderObject.isRequired,
  complete: PropTypes.func.isRequired,
  isAddFreight: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default AddItemsForm;
