import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { resendableRow } from "./ResendButton";

const discardableRow = row => {
  return resendableRow(row) && row.type.includes("CMP_BRANCH_");
};

class DiscardButton extends React.Component {
  state = {
    isSending: false
  };

  render() {
    const { row } = this.props;

    return (
      discardableRow(row.data) && (
        <Mutation mutation={DISCARD_SYNC} onError={this.onError}>
          {(update, { loading, error }) => (
            <button
              onClick={e => {
                e.preventDefault();
                this.send(update);
              }}
              className="button button--small"
              disabled={this.state.isSending}
              style={{ marginRight: 5, color: "red" }}
            >
              {this.state.isSending ? " Discarding..." : "Discard"}
            </button>
          )}
        </Mutation>
      )
    );
  }

  send = update => {
    const { row, onComplete } = this.props;

    if (window.confirm("Are you sure you want to discard this order?")) {
      this.setState({ isSending: true });
      update({
        variables: {
          id: row.data.orderId
        }
      }).then(result => {
        if (result && result.data) {
          onComplete(row, result.data.discardSync.syncItem);
        }
      });
    }
  };
}

const DISCARD_SYNC = gql`
  mutation discardSync($id: ID!) {
    discardSync(id: $id) {
      syncItem {
        id
        type
        flow
        orderId
        gpInvoiceNumber
        lastSyncAt
        enqueued
        resendable
        lastSyncError
        numberOfSyncAttempts
        closeable
        syncId
        lastSyncLink
      }
      errors {
        path
        message
      }
    }
  }
`;

DiscardButton.propTypes = {
  row: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default DiscardButton;
export { discardableRow };
