import React from "react";
import PropTypes from "prop-types";

import dateFuncs from "Form/dateFuncs";

const CommentMemoView = ({ data, api, rowIndex }) => {
  const startEditing = function(e) {
    e.preventDefault();
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: "body"
    });
  };

  return (
    <div>
      <div className="comments-memo-field">{data.body}</div>
      {data.bodyEditedAt && (
        <i className="comments-memo-field-edited">
          (Edited {dateFuncs.displayDateTime(data.bodyEditedAt)})
        </i>
      )}
      {data.canEditBody && (
        <a
          onClick={startEditing}
          className="icon icon--edit icon--right"
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
};

CommentMemoView.propTypes = {
  data: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default CommentMemoView;
