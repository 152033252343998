import gql from "graphql-tag";

import orderUiFragments from "./orderUiFragments";
import invoiceFragments from "./invoiceFragments";
import checklistFragments from "./checklistFragments";

const fragments = {
  query: gql`
    fragment OrderFields on Order {
      id
      cmpOrderNumber
      webOrderNo
      hospitalType
      vic30DayDischarge
      agedCare
      orderType
      orderDate
      dateReceived
      updatedAt
      status
      statusDesc
      duringFulfillment
      sourceSystem
      fromErap
      invoiceNo
      costCentre
      cpo
      urgent
      awaitingCall
      assignedStaffId
      assignedStaffName
      clinicalJustificationProvided
      seekPriorApproval
      priorApprovalNumber
      partnerRequestedApproval
      includesRepair
      includesHomeMods
      complexHomeMods
      hireStartOn
      hireEndOn
      followUpOn
      npFollowUpOn
      deliveryInstructions
      fromQuote
      inReassign
      useAutoInvoicing
      invoiceableLinesCount
      reversibleLinesCount
      commentsCount
      unreadCommentsCount
      purchaseOrderLink
      deliveryDocketLink
      orderFormLink
      ...OrderUiFields
      salesRepId
      shipmentCarrierOptions
      consignment
      transferredToIds
      manualOrder
      requesterFirstName
      requesterLastName
      wardClientName
      wardClientReference
      wardClientRoom
      adminNotes
      trial
      contract {
        id
        contractCode
        contractType
        bulkPricing
        trialsEnabled
        paymentSkuDescription1
        paymentSkuDescription2
        processingInstructions
      }
      salesRep {
        id
        gpCode
        status
        firstName
        lastName
        name
        territory
      }
      debtorId
      debtor {
        id
        code
        notes
        bulkPricing
      }
      saleType
      additionalInfo {
        labelText
        value
        subInfo {
          labelText
          value
        }
      }
      ...InvoiceQuery
      checklist {
        ...ChecklistQuery
      }
    }
    ${orderUiFragments.query}
    ${invoiceFragments.query}
    ${checklistFragments.query}
  `
};

export default fragments;
