import React from "react";
import Modal from "Util/Modal";
import { OrderObject } from "../Queries/OrderQuery";
import HcpAdder from "./HcpAdder";
import HcpsTable from "./Table";
import PropTypes from "prop-types";

class HcpModal extends React.Component {
  state = { isOpen: false, addNewDisabled: false };

  render() {
    const { order, currentUser } = this.props;

    const disabled = this.props.disabled || order.status === "cancelled";
    return (
      <React.Fragment>
        <section>
          <button
            onClick={e => this.toggleModal(e)}
            className={
              "display_group__header__button button--" +
              (disabled ? "disabled" : "back")
            }
            disabled={disabled}
          >
            {order.healthcareProvider ? "Change" : "Add"} Healthcare Provider
          </button>
        </section>
        {this.state.isOpen && (
          <HcpAdder
            order={order}
            onComplete={this.closeModal}
            render={({ update }) => (
              <Modal
                show={this.state.isOpen}
                onClose={() => this.closeModal()}
                header="Add Healthcare Provider"
                className="hcps-modal"
                renderFooter={() => (
                  <React.Fragment>
                    {order.sourceSystem !== "EPI" && (
                      <button
                        onClick={() => this.addNew(update)}
                        className="button button--white"
                        disabled={this.state.addNewDisabled}
                      >
                        Add New Provider
                      </button>
                    )}
                    <a
                      onClick={() => this.closeModal()}
                      className="button button--back"
                    >
                      Back
                    </a>
                  </React.Fragment>
                )}
              >
              <HcpsTable
                  order={order}
                  update={update}
                  currentUser={currentUser}
                />
              </Modal>
            )}
          />
        )}
      </React.Fragment>
    );
  }

  addNew = update => {
    this.setState({ addNewDisabled: true });
    update(null);
  };

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

HcpModal.propTypes = {
  order: OrderObject.isRequired,
  currentUser: PropTypes.object,
  disabled: PropTypes.bool
};

export default HcpModal;
