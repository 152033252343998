import React from "react";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import Table from "./Table";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";

const HealthcareProviderClientsApp = props => {
  return (
    <GraphqlWrapper>
      <GridPropsQuery
        render={({ currentUser }) => (
          <section className="healthcare_provider_section">
            <Table currentUser={currentUser} />
          </section>
        )}
      />
    </GraphqlWrapper>
  );
};

export default HealthcareProviderClientsApp;
