import React from "react";
import PropTypes from "prop-types";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const SelectDebtorQuery = ({ contractId, render }) => {
  return (
    <Query query={gqlDebtorsQuery()} variables={{ contractId: contractId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;
        return render({
          debtors: data.debtors.filter(d => d.status !== "inactive")
        });
      }}
    </Query>
  );
};

const gqlDebtorsQuery = () => {
  return gql`
    query Debtors($contractId: Int!) {
      debtors(contractId: $contractId) {
        id
        code
        status
        notes
        searchText
        bulkPricing
      }
    }
  `;
};

SelectDebtorQuery.propTypes = {
  contractId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

export default SelectDebtorQuery;
