import React from "react";
import PropTypes from "prop-types";
import Modal from "Util/Modal";

import { OrderObject, CurrentUserObject } from "../Queries/OrderQuery";
import Table from "../OrderLevelProcessBtns/Table";
import CreateShipmentUpdater from "./CreateShipmentUpdater";
import EditDateField from "../../lib/Form/EditDateField";
import EditTextField from "../../lib/Form/EditTextField";
import FieldWrapper from "../../lib/Form/FieldWrapper";
import EditTextArea from "../../lib/Form/EditTextArea";

class ShipmentsButton extends React.Component {
  state = {
    errors: null,
    submitDisabled: false,
    shipmentDate: null,
    invoiceNumber: null,
    cancel: false,
    carrier: "Vendor Provided",
    trackingNumber: "",
    comments: ""
  };

  render() {
    const { order, orderLines, awaitingCallPending } = this.props;

    const awaitingCall = order.awaitingCall || awaitingCallPending;

    return (
      <Modal
        show={this.props.show}
        onClose={this.props.toggleOpen}
        header="Shipments"
        className="shipments-modal"
        renderFooter={() => (
          <a onClick={this.props.toggleOpen} className="button button--back">
            Cancel
          </a>
        )}
      >
        {this.state.errors && (
          <div className="message message--error" role="alert">
            <p>
              There was an error creating the shipment, please reload the page
              and try again
            </p>
          </div>
        )}
        {awaitingCall && (
          <p>Please remove from Awaiting Call before creating a shipment</p>
        )}
        {!awaitingCall && (
          <Table
            {...this.props}
            option={{ key: "shippable" }}
            orderLines={orderLines}
            processSelections={selections => (
              <CreateShipmentUpdater
                order={order}
                onError={this.onError}
                onCompleted={this.onCompleted}
                render={({ createShipment }) => (
                  <React.Fragment>
                    <form
                      onSubmit={() => this.update(selections, createShipment)}
                      className="form"
                    >
                      <fieldset className="fieldset">
                        <EditDateField
                          name="shipmentDate"
                          onChange={this.handleChange}
                          value={this.state.shipmentDate}
                          labelText="Shipment Date"
                          className="fullwidth"
                          required={true}
                          limit="TodayBack"
                        />
                        <EditTextField
                          onChange={this.handleChange}
                          name="invoiceNumber"
                          value={this.state.invoiceNumber}
                          required={true}
                          pattern="INV[0-9]{8}"
                          title="INV followed by 8 digits"
                          placeholder="INV12345678"
                          labelText="Invoice Number"
                          className="fullwidth"
                        />
                        <FieldWrapper name="carrier">
                          <label htmlFor="carrier" className="field--required">
                            Carrier
                          </label>
                          <select
                            name="carrier"
                            value={this.state.carrier}
                            onChange={e =>
                              this.handleChange("carrier", e.target.value)
                            }
                          >
                            {order.shipmentCarrierOptions.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </FieldWrapper>
                        <EditTextField
                          onChange={this.handleChange}
                          name="trackingNumber"
                          value={this.state.trackingNumber}
                          labelText="Tracking Number"
                          className="fullwidth"
                        />
                        <EditTextArea
                          name="comments"
                          onChange={this.handleChange}
                          value={this.state.comments}
                          labelText="Comments"
                        />
                      </fieldset>
                      <div className="button-group">
                        <input
                          type="submit"
                          name="commit"
                          value="Create Shipment"
                          className="button primary right"
                          disabled={
                            this.state.errors ||
                            this.state.submitDisabled ||
                            selections.length === 0 ||
                            !this.state.shipmentDate ||
                            !this.state.carrier ||
                            !this.state.invoiceNumber
                          }
                        />
                        <button
                          className="button"
                          onClick={() =>
                            this.cancelShipment(selections, createShipment)
                          }
                          disabled={
                            this.state.errors ||
                            this.state.submitDisabled ||
                            selections.length === 0
                          }
                        >
                          Cancel Shipment
                        </button>
                      </div>
                    </form>
                  </React.Fragment>
                )}
              />
            )}
          />
        )}
      </Modal>
    );
  }

  handleChange = (field, value) => {
    this.setState(prevState => ({ ...prevState, [field]: value }));
  };

  cancelShipment = (selections, createShipment) => {
    this.setState({ submitDisabled: true });
    createShipment({
      lineIds: selections,
      cancelShipment: true
    });
  };
  onError = errors => {
    this.setState({ errors: errors });
  };

  onCompleted = () => {
    this.props.toggleOpen();
  };

  update = (selections, createShipment) => {
    this.setState({ submitDisabled: true });

    createShipment({
      lineIds: selections,
      shipmentDate: this.state.shipmentDate,
      invoiceNumber: this.state.invoiceNumber,
      carrier: this.state.carrier,
      trackingNumber: this.state.trackingNumber,
      comments: this.state.comments
    });
  };
}

ShipmentsButton.propTypes = {
  show: PropTypes.bool.isRequired,
  order: OrderObject.isRequired,
  awaitingCallPending: PropTypes.bool.isRequired,
  currentUser: CurrentUserObject.isRequired,
  orderLines: PropTypes.array.isRequired,
  toggleOpen: PropTypes.func.isRequired
};

export default ShipmentsButton;
